import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles({ name: 'ResultsFilters' })(
  ({ breakpoints, spacing }) => ({
    root: {
      display: 'flex',
      flexFlow: 'row wrap',
      minWidth: 180,
      gap: spacing(3),

      [breakpoints.up('md')]: {
        flexFlow: 'column nowrap',
        gap: spacing(4),
      },
    },
    filters: {
      maxHeight: 80,

      [breakpoints.up('md')]: {
        maxHeight: 'unset',
      },
    },
  })
);
