import {
  OfferComparisonTableProps,
  OfferHeadInterface,
} from './OfferComparisonTable.interfaces';
import { useStyles } from './OfferComparisonTable.styles';

import { Typography } from '@mui/material';
import { Icon } from 'app/components/Icon';
import { Table } from 'app/components/Table';
import { LabelEnum } from 'app/shared/enums/Label.enum';
import { OfferDetails } from 'app/shared/enums/OfferDetails.enum';
import { insurersConfig } from 'app/shared/helpers/insurersConfig';
import { offerDetailsToLabelMap } from 'app/shared/helpers/offerDetailsToLabelMap';
import { Button } from '../Button';
import { IconButton } from '../IconButton';

const OfferDetailsTableIcon = ({ value }: { value: boolean }) => {
  return value ? <Icon name="CheckCircle" /> : <Icon name="CloseCircle" />;
};

export default function OfferComparisonTable(props: OfferComparisonTableProps) {
  const { classes } = useStyles();
  const { offers, onRemoveOfferButtonClick, onPrimaryOfferButtonClick } = props;

  const handlePrimaryButtonClick = (id: string) => () =>
    onPrimaryOfferButtonClick(id);

  return (
    <div className={classes.root}>
      <Table.Table>
        <Table.Header>
          <Table.Row>
            <Table.Cell>
              <Typography variant="h4" className={classes.title}>
                {LabelEnum.Compare}
              </Typography>
            </Table.Cell>
            {offers.map((offer) => (
              <Table.Cell key={offer.id}>
                <OfferHead
                  offer={offer}
                  onCloseButtonClick={onRemoveOfferButtonClick}
                />
              </Table.Cell>
            ))}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {Object.values(OfferDetails).map((value) => (
            <>
              <Table.Row key={value}>
                <Table.Cell>
                  <span className={classes.label}>
                    {offerDetailsToLabelMap[value]}
                  </span>
                </Table.Cell>
                {offers.map((offer) => (
                  <Table.Cell className={classes.bodyCell} key={offer.id}>
                    <OfferDetailsTableIcon value={offer[value]} />
                  </Table.Cell>
                ))}
              </Table.Row>
            </>
          ))}
          <Table.Row>
            <Table.Cell>
              <span className={classes.label}>{LabelEnum.Sheet}</span>
            </Table.Cell>
            {offers.map((offer) => (
              <Table.Cell className={classes.bodyCell} key={offer.id}>
                <a
                  className={classes.hypers}
                  href={offer?.additional?.productCardUrl}
                  download
                  target="_blank"
                  rel="noreferrer"
                >
                  {LabelEnum.Discharge}
                </a>
              </Table.Cell>
            ))}
          </Table.Row>
          <Table.Row>
            <Table.Cell />
            {offers.map(({ id }) => (
              <Table.Cell className={classes.bodyCell} key={id}>
                <Button
                  color="Brand"
                  size="Small"
                  className={classes.button}
                  onClick={handlePrimaryButtonClick(id)}
                  data-cy-offer-id={id}
                >
                  {LabelEnum.ShouldWeCallYou}
                </Button>
              </Table.Cell>
            ))}
          </Table.Row>
        </Table.Body>
      </Table.Table>
    </div>
  );
}

const OfferHead = ({ offer, onCloseButtonClick }: OfferHeadInterface) => {
  const { classes } = useStyles();
  const logoIcon = offer.insurerName && insurersConfig[offer.insurerName]?.icon;
  const price = offer.pricePerPerson?.finalPrice || offer.price?.finalPrice;
  const isGroup =
    !!offer.pricePerPerson &&
    offer.pricePerPerson.finalPrice !== offer.price!.finalPrice;

  const handleCloseButtonClick = () => {
    onCloseButtonClick(offer.id);
  };

  return (
    <div className={classes.offerHead}>
      {logoIcon && <Icon name={logoIcon} data-cy="insurer-logo" />}
      <div className={classes.price}>
        <Typography
          variant="h3"
          component="strong"
          data-cy="offer-comparison-price"
        >
          {price.toFixed(2)}&nbsp;{LabelEnum.Pound}
        </Typography>
        {'\n'}
        {isGroup ? LabelEnum.ForAllPersons : LabelEnum.ForAll}
      </div>
      <IconButton
        className={classes.removeInsurer}
        onClick={handleCloseButtonClick}
      >
        <Icon name="CloseCircle" />
      </IconButton>
    </div>
  );
};
