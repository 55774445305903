import { RootState } from 'app/store';

export const selectQuotation = (state: RootState) => state.quotation;
export const selectOffers = (state: RootState) => state.quotationResults.offers;
export const selectInsurers = (state: RootState) =>
  state.quotationResults.insurers;
export const selectQuotationResultsLoading = (state: RootState) =>
  state.quotationResults.isLoading;
export const selectOfferById = (id?: string) => (state: RootState) =>
  id && state.quotationResults.offers[id];
