import { TextField } from '@mui/material';
import InputMask from 'react-input-mask';

import { ControlContainer } from 'app/components/ControlContainer';
import { ZipCodeControlProps } from './ZipCodeControl.interfaces';
import { useStyles } from './ZipCodeControl.styles';
import { useZipCodeControl } from './useZipCodeControl';

const mask = '99999';
const maskChar = null;

export default function ZipCodeControl(props: ZipCodeControlProps) {
  const { input, label, meta, tooltip } = props;
  const { classes, cx } = useStyles();
  const { tag, delayedTag, handleBlur, handleChange } =
    useZipCodeControl(props);

  return (
    <ControlContainer
      label={label}
      meta={meta}
      name={input.name}
      tooltip={tooltip}
    >
      <div className={classes.root}>
        <InputMask
          mask={mask}
          maskChar={maskChar}
          {...input}
          onChange={handleChange}
          onBlur={handleBlur}
        >
          {() => (
            <TextField
              InputProps={{
                disabled: false,
                name: 'postal',
              }}
              type="tel"
              placeholder={'12345'}
              classes={{
                root: classes.inputRoot,
              }}
              error={!!(meta.touched && meta.error)}
            />
          )}
        </InputMask>
        <div className={cx(classes.tag, { [classes.tagVisible]: !!tag })}>
          {tag || delayedTag}
        </div>
      </div>
    </ControlContainer>
  );
}
