import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(({ palette, spacing, typography }) => ({
  root: {
    display: 'flex',
    flexFlow: 'row wrap',
    gap: spacing(2),
  },
  inputRoot: {
    minWidth: 70,
    '& input::placeholder': {
      color: palette.neutral.grey[40],
    },
  },
  tag: {
    ...typography.body.S,
    padding: spacing(3),
    opacity: 0,

    border: 'solid 2px',
    borderRadius: 10,
    borderColor: palette.brand,
    backgroundColor: palette['brand-10'],

    display: 'flex',
    flex: '0 1 auto',
    alignItems: 'center',
    justifyContent: 'center',

    transition: 'opacity 200ms ease-in-out',
  },
  tagVisible: {
    opacity: 1,
  },
  alert: {
    marginTop: spacing(2),
    backgroundColor: 'transparent',
  },
}));
