import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(
  ({ palette, spacing, breakpoints, typography }) => ({
    root: {
      borderRadius: 8,
    },
    rootCheapest: {
      border: '5px solid',
      borderColor: palette['brand-20'],
      backgroundColor: palette['brand-20'],
    },
    rootQuality: {
      border: '5px solid',
      borderColor: palette.ui['attention-10'],
      backgroundColor: palette.ui['attention-10'],
    },
    rootOverall: {
      border: '5px solid',
      borderColor: palette.ui['info-10'],
      backgroundColor: palette.ui['info-10'],
    },
    card: {
      padding: spacing(4),
      backgroundColor: palette.white,
      borderRadius: 12,

      [breakpoints.up('md')]: {
        padding: spacing(6),
      },
    },
    gridContainer: {
      display: 'grid',
      gap: spacing(3),
      alignItems: 'center',
      gridTemplateAreas: `
    'logo price'
    'filters filters'
    'buttons buttons'
    'compare compare'
    `,
      gridTemplateColumns: 'auto 60%',
      [breakpoints.up('md')]: {
        gridTemplateColumns: '24% auto auto',
        gridTemplateAreas: `'logo filters price'
    'compare buttons buttons'`,
      },
    },
    compare: {
      gridArea: 'compare',
      marginLeft: spacing(-2),
    },
    checkboxChecked: {
      '& ~ span': {
        ...typography.body.S,
        fontWeight: 600,
        color: palette.brand,
      },
    },
    checkboxDisabled: {
      '& ~ span': {
        color: palette.neutral.grey[40],
      },
    },
    buttons: {
      gridArea: 'buttons',
      display: 'flex',
      flexDirection: 'row',
      gap: spacing(1),
      [breakpoints.up('md')]: {
        gap: spacing(2),
      },
    },
    logo: {
      gridArea: 'logo',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',

      '& svg, & img': {
        width: '100%',
        height: 'auto',
        maxWidth: 140,
        maxHeight: 65,
      },
    },
    price: {
      gridArea: 'price',
      textAlign: 'right',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      alignSelf: 'baseline',
      [breakpoints.up('md')]: {
        maxWidth: 220,
      },
    },
    priceTitle: {
      ...typography.body.S,
      whiteSpace: 'nowrap',
      lineHeight: '14px',
      color: palette.black,
    },
    priceTitleGroup: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-end',

      '& > span': {
        flex: '0 1',
      },
    },
    priceSubtitle: {
      ...typography.body.S,
      color: palette.black,
      textDecoration: 'underline',
      flex: '0 1',
    },
    detailsTextButton: {
      whiteSpace: 'nowrap',
    },
    filters: {
      gridArea: 'filters',
    },
    callUsButton: {
      whiteSpace: 'pre-wrap',

      [breakpoints.up('sm')]: {
        whiteSpace: 'nowrap',
      },
    },
  })
);
