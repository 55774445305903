export enum LabelEnum {
  DateRequired = 'Selecciona una fecha válida.',
  EmailRequired = 'Por favor, introduce tu correo electrónico.',
  FieldRequired = 'Este campo es obligatorio.',
  OptionRequired = 'Selecciona una de las opciones.',
  PhoneNumberRequired = 'Por favor, introduce tu número de teléfono.',
  EmailInvalid = 'Introduce un correo electrónico válido (p.ej. tu@gmail.com)',
  PhoneNumberInvalid = 'Introduce un número de teléfono válido.',
  ZipCodeInvalid = 'El código postal debe tener 5 dígitos (p. ej. 08017, 28660, 46680).',
  NetworkError = 'Network error',
  NumberOfPersonsControlLabel = '¿Cuántas personas necesitan el seguro?',
  NumberOfPersonsTooltip = 'Indica el número de personas que vas a incluir en la póliza para que disfruten de los servicios del seguro médico.',
  EmailControlLabel = 'Email',
  EmailControlInfoMessage = 'No vamos a facilitar tu correo electrónico a otras aseguradoras, sólo será para ayudarte en tu comparativa.',
  EmailControlPlaceholder = 'your@email.com',
  BirthDateLabel = 'Fecha de nacimiento',
  SectionMessage = 'Form section custom message',
  SectionLabel = 'Datos de contacto',
  SectionSublabel = '¡El último paso antes de ver tus ofertas!',
  ServerError = 'Server error occured',
  Tooltip = 'El copago es para aquellas personas que acuden poco al médico y quieren pagar menos cada mes, asumiendo una parte en cada acto médico que necesiten. Si no lo marcas, tendrás las ofertas de “tarifa plana” pagando siempre lo mismo cada mes.',
  CalculationAdvert = 'Compara tu seguro de salud entre varias aseguradoras en 1 minuto.',
  CalculationFormTitle = 'Comparador de\u00A0seguros de\u00A0salud',
  StartDateLabel = 'Fecha comienzo del seguro',
  StartDateTooltip = 'Selecciona la fecha desde la que podrás tener acceso a las coberturas que deseas contratar.',
  PhoneNumberControlLabel = 'Número de teléfono',
  PhoneNumberControlInfoMessage = 'No vamos a facilitar tu número de teléfono a otras aseguradoras, sólo será para ayudarte en tu comparativa.',
  Age = 'Edad',
  PPCTitle1 = 'Seguros de',
  PPCTitle2 = 'salud',
  PPCSubtitle = 'En Arpem.com puedes comparar tu seguro de salud entre diferentes aseguradoras y encontrar el producto que más seajuste a tus necesidades.',
  PPCFormTitle = 'Calcula tu\nprecio ahora',
  Insurant = 'Asegurado',
  CurrentlyInsured = '¿Estás asegurado actualmente?',
  More = 'Más',
  Products = '¿Qué productos te interesan?',
  ZipCode = 'Código postal',
  ZipCodeTooltip = 'Indica el código postal de residencia, es fundamental para poder darte asistencia médica en tu propio domicilio.',
  PreviousInsurerName = '¿Cuál es la última aseguradora donde has tenido seguro?',
  PreviousInsurerNameTooltip = 'Indica la aseguradora en la que estás asegurado actualmente.',
  PhonePrefix = '+34',
  MarketingConsentControlLabel = 'Autorizo que ARPEM conserve mi información como correduría de seguros con la finalidad de ofrecerme seguros de otros ramos que puedan ser de mi interés.',
  PrivacyPolicyConsentControlLabel = 'He leído y acepto el aviso legal y la política de privacidad con la finalidad de recibir información sobre el seguro y la cesión de mis datos a las entidades aseguradoras, a excepción del número de teléfono y del email.',
  LegalNotice = 'aviso legal',
  PrivacyPolicy = 'política de privacidad',
  ConsentsAcceptAllControlLabel = 'Acepto todo',
  ConsentsLabel = 'Términos y Condiciones',
  Consent = 'De conformidad con la normativa de protección de datos, ARPEM NETWORKS, S.L., tratará tus datos con la finalidad de remitir información sobre las mejores ofertas de seguros como correduría de seguros, así como de comunicar los datos a las compañías aseguradoras para permitirte contratar sus servicios. Igualmente, con tu consentimiento podremos conservar tu información para ofrecerte servicios complementarios de seguros en otros ramos que puedan ser de tu interés. Puedes ejercer tus derechos en materia de protección de datos o solicitar información adicional sobre el tratamiento en lopd@arpem.com o contactar con nuestro dpo en dpo@arpem.com',
  ConsentsRequired = 'Por favor, acepta las condiciones de uso y la política de privacidad para avanzar.',
  CalculationFormSubmitButton = '¡Termina y mira tus ofertas!',
  CalculationFormSubmitButtonPPC = 'Calcular',
  Enviar = 'Enviar',
  PreviousInsurerPlaceholder = 'La última aseguradora',
  CallAgent = 'Habla con nosotros',
  CallAgentPhoneNumber = '911 238 417',
  CallAgentFullPhoneNumber = '+34911238417',
  DetailsOfOfferNr = 'Número de Cotización:',
  ChangeData = 'Modifica los datos',
  RecommendedOffer = 'Producto recomendado',
  RecommendedOfferCheapest = 'Oferta más económica',
  RecommendedOfferOverall = 'Best Calidad Precio',
  RecommendedOfferQuality = 'Best quality offer',
  WeWillCallYou = '¡Te llamamos!',
  ShouldWeCallYou = '¿Te llamamos?',
  CallUs = '¡Llámanos!',
  CallUsAt = 'Llámanos al:',
  OrderOnline = 'Contrata Online',
  CheckOfferDetails = 'Detalle de coberturas',
  ForAll = '/\u00A0al\u00A0mes',
  ForAllPersons = '/\u00A0al\u00A0mes\u00A0persona',
  Pound = '€',
  Compare = 'Compara\nseguro',
  CoPayment = 'Copago',
  Hospitalization = 'Hospitalización',
  SelfEmployed = 'Autónomos',
  Refund = 'Reembolso',
  WithHospitalization = 'Con Hospitalización',
  NoHospitalization = 'Sin Hospitalización',
  WithCoPayment = 'Con Copago',
  NoCoPayment = 'Sin Copago',
  Next = 'Siguiente',
  Other = 'Otra',
  FoundOffers = 'ofertas encontradas',
  InsuranceCoverage = 'Coberturas del seguro',
  Ad = 'En Arpem podemos ayudarte a\u00A0encontrar el mejor seguro.',
  DentalCoverage = 'Cobertura dental',
  EmergencyService = 'Servicio de Urgencia',
  GeneralMedicine = 'Medicina general',
  MedicalOrSurgicalHospitalization = 'Hospitalización médica o quirúrgica',
  PediatricsAndChildcare = 'Pediatría y Puericultura',
  RehabilitationAndPhysiotherapy = 'Rehabilitación y Fisioterapia',
  Specialties = 'Especialidades',
  Copay = 'Copagos',
  Lack = 'Carencia',
  Sheet = 'Ficha de producto',
  Discharge = 'Descargar',
  ContactAdvisor = 'Habla con tu asesor',
  OrCallUs = 'O llámanos al',
  ContactNumber = '911 238 417',
  WorkingHours = 'Horario de atención:\nLunes a viernes de 9:00h a 20:00h',
  LearnMore = 'Más información',
  CallRequestedSuccessfully_1 = '¡Lo tenemos! Hemos recibido\u00A0tu\u00A0solicitud.',
  CallRequestedSuccessfully_2 = 'Alguien de nuestro súperequipo te llamará en breve.',
  CallRequestedAfterHours_1 = '¡Vaya! Nuestro superequipo ahora está descansando.',
  CallRequestedAfterHours_2 = 'Te llamaremos lo antes posible para resolver todas tus dudas.',
  Close = 'Close',
  ShowComparison = 'Muestra la comparativa',
  Effect = 'Efecto',
  Person = 'persona',
  Persons = 'personas',
  Login = 'Login',
  Logout = 'Finalizar la sesión',
  PasswordControlLabel = 'Contraseña',
  LoginSucceded = 'Iniciado sesión con éxito',
  LogoutSucceded = 'Se desconectó con éxito',
  UsernameInvalid = 'La dirección de correo electrónico proporcionada no existe',
  PasswordInvalid = 'Ingrese la contraseña correcta',
  Password = 'La contraseña',
  None = 'None',
  LandingPage = 'https://arpem.com',
  ListingFooterPart1 = 'Nuestros precios se han obtenido a través de la información introducida en nuestro formulario. Los precios pueden sufrir leves modificaciones. Así mismo, una vez gestionada la contratación es posible que la aseguradora quiera profundizar en tu estado de salud y valorar el alta de la misma.\n\n',
  ListingFooterPart2 = 'Nuestros agentes están aquí para ayudarte no dudes en hablar con nosotros:\n',
  DisabledCompareTooltip = 'Solo puedes comparar hasta tres ofertas',
  CompanyTrademark = '2023 © Arpem Networks, SL',
}
