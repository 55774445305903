import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(({ spacing }) => ({
  root: {
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
    flex: '1 0 100%',
    gap: spacing(2),
  },
  radioGroup: {
    display: 'flex',
    flexFlow: 'row wrap',
    gap: spacing(2),
  },
  select: {
    width: 100,
  },
}));
