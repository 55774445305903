import { FormScreenProps } from './FormScreen.interfaces';

export default function FormScreen(props: FormScreenProps) {
  const { label, children, screenKey } = props;

  return (
    <div id={screenKey}>
      {label}
      {children}
    </div>
  );
}
