import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(({ palette, spacing, typography }) => ({
  root: {
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'row',
    alignItems: 'center',
    padding: spacing(3),

    color: palette.black,
    ...typography.body.S,

    border: 'solid 2px',
    borderRadius: 10,
    borderColor: palette.neutral.grey[20],
    backgroundColor: palette.white,

    cursor: 'pointer',
    userSelect: 'none',

    gap: spacing(2),

    '&:has(.Mui-focusVisible), &:hover': {
      borderColor: palette.neutral.grey[30],
    },

    '& .MuiCheckbox-root': {
      padding: 0,
    },
  },
  rootChecked: {
    backgroundColor: palette['brand-10'],
    borderColor: palette.brand,

    '&:hover': {
      borderColor: palette.brand,
    },
  },
  label: {
    flex: '1 1 auto',
  },
}));
