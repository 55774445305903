import { LabelEnum } from '../enums/Label.enum';
import { RouteEnum } from '../enums/Route.enum';

export const getAbsoluteOfferDetailsPath = (offerId: string) => {
  return `${RouteEnum.ROOT_VIEW}/${RouteEnum.CALCULATOR_VIEW}/${RouteEnum.RESULTS_VIEW}/${offerId}`;
};

export const getAbsoluteOfferDetailsContactPath = (offerId: string) => {
  return `${getAbsoluteOfferDetailsPath(offerId)}/${
    RouteEnum.OFFER_DETAILS_CONTACT
  }`;
};

export const getAbsoluteResultsContactPath = (offerId: string) => {
  return fillPathParam(RouteEnum.RESULTS_VIEW_CONTACT, {
    key: 'offerId',
    value: offerId,
  });
};

export const fillPathParam = (
  path: string,
  param: { key: string; value: string }
) => {
  return path.replace(`:${param.key}`, param.value);
};

export const getRegulationsConsentLabel = () => {
  const html = LabelEnum.PrivacyPolicyConsentControlLabel.replace(
    LabelEnum.LegalNotice,
    `<a target="_blank" href=${RouteEnum.LEGAL_NOTICE}>${LabelEnum.LegalNotice}</a>`
  ).replace(
    LabelEnum.PrivacyPolicy,
    `<a target="_blank" href=${RouteEnum.PRIVACY_POLICY}>${LabelEnum.PrivacyPolicy}</a>`
  );
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: html,
      }}
    />
  );
};
