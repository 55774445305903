import { useEffect, useState } from 'react';

import { mapQuotationToFormValues } from 'app/shared/helpers/quotation';
import { useTypedDispatch } from 'app/shared/hooks/useTypedDispatch';
import { useTypedSelector } from 'app/shared/hooks/useTypedSelector';
import { ErrorResponseDataInterface } from 'app/shared/interfaces/ErrorResponseData.interface';
import {
  selectQuotation,
  submitQuotationAction,
} from 'app/store/data/quotation';
import { setIsQuotationSubmittedByUserAction } from 'app/store/data/quotationResults';
import { FormRenderProps } from 'react-final-form';
import { scrollToTarget } from 'app/shared/helpers/scrollToTarget';
import { useNavigate } from 'react-router-dom';
import { RouteEnum } from 'app/shared/enums/Route.enum';

export function usePPCCalculationForm() {
  const dispatch = useTypedDispatch();
  const initialValues = useInitialValues();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const isPPC = true;

  const onSubmitError = (error: ErrorResponseDataInterface) => {
    setIsLoading(false);
  };

  const onSubmitSuccess = () => {
    dispatch(setIsQuotationSubmittedByUserAction(true));
  };

  const handleSubmit = async () => {
    setIsLoading(true);

    const response = await dispatch(submitQuotationAction({ isPPC }));

    if ('error' in response) {
      const err = response.error as ErrorResponseDataInterface;
      onSubmitError(err);
    } else {
      onSubmitSuccess();
      navigate(RouteEnum.RESULTS_VIEW);
    }
  };

  const handleSubmitCapture = ({ hasValidationErrors }: FormRenderProps) => {
    if (hasValidationErrors) {
      scrollToTarget('[data-field-error="true"]');
    }
  };

  return {
    handleSubmit,
    handleSubmitCapture,
    initialValues,
    isLoading,
  };
}

const useInitialValues = () => {
  const quotation = useTypedSelector(selectQuotation);
  const [initialValues, setInitialValues] = useState(
    mapQuotationToFormValues(quotation)
  );

  useEffect(() => {
    if (quotation.id !== initialValues.id) {
      setInitialValues(mapQuotationToFormValues(quotation));
    }
  }, [initialValues.id, quotation]);

  return initialValues;
};
