import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useSendAcquisitionParams } from 'app/shared/hooks/useSendAcquisitionParams';
import { useTypedDispatch } from 'app/shared/hooks/useTypedDispatch';
import { useTypedSelector } from 'app/shared/hooks/useTypedSelector';
import {
  initQuotationAction,
  selectQuotationStatus,
} from 'app/store/data/quotation';
import { initQuotationByIdAction } from 'app/store/data/quotation/quotationActions';
import { selectIsLoggedIn } from 'app/store/data/user';

export function useCalculatorBaseView() {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useTypedDispatch();
  const quotationStatus = useTypedSelector(selectQuotationStatus);

  const isLoggedIn = useTypedSelector(selectIsLoggedIn);
  const { state } = useLocation();

  const initQuotation = () => {
    const quotationId = state?.quotationId;

    if (quotationId) {
      dispatch(initQuotationByIdAction({ quotationId }));
    } else {
      dispatch(initQuotationAction());
    }
  };

  const setLoadingStatus = () => {
    if (quotationStatus) {
      setIsLoading(false);
    }
  };

  useEffect(initQuotation, [dispatch, state?.quotationId]);
  useEffect(setLoadingStatus, [quotationStatus]);
  useSendAcquisitionParams();

  return { isLoading, isLoggedIn };
}
