import { DidomiSDK } from '@didomi/react';
import { REACT_APP_DIDOMI_COOKIES_API_KEY } from 'app/shared/helpers/envConfig';
import { initSentry } from 'app/shared/helpers/sentry';

export default function CookiePanel() {
  return (
    <DidomiSDK
      apiKey={REACT_APP_DIDOMI_COOKIES_API_KEY}
      iabVersion={2}
      gdprAppliesGlobally={false}
      onReady={(Didomi) => {
        if (Didomi.isConsentRequired()) {
          Didomi.getObservableOnUserConsentStatusForVendor('c:sentry')
            .filter((status: boolean) => status)
            .first()
            .subscribe(() => initSentry());
        } else {
          initSentry();
        }
      }}
    />
  );
}
