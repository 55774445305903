import { Button } from 'app/components/Button';
import { useLogoutButton } from './useLogoutButton';
import { LabelEnum } from 'app/shared/enums/Label.enum';
import { LogoutButtonProps } from './LogoutButton.interfaces';
import { useStyles } from './LogoutButton.styles';

export default function LogoutButton(props: LogoutButtonProps) {
  const { classes } = useStyles();

  const { isLoggedIn, logout } = useLogoutButton();

  if (!isLoggedIn) {
    return null;
  }

  return (
    <div className={classes.root}>
      <Button color="Brand" size="Small" onClick={logout}>
        {LabelEnum.Logout}
      </Button>
    </div>
  );
}
