import axios, { AxiosPromise, AxiosRequestConfig, AxiosResponse } from 'axios';

import { HeaderContentTypeParamEnum } from 'app/shared/enums/HeaderContentTypeParam.enum';
import { LabelEnum } from 'app/shared/enums/Label.enum';
import { MessageTypeEnum } from 'app/shared/enums/MessageType.enum';
import { REACT_APP_API_URL } from 'app/shared/helpers/envConfig';
import { store } from 'app/store';
import { showNotificationAction } from 'app/store/data/notification';

const baseConfig = {
  baseURL: REACT_APP_API_URL,
  headers: {
    Accept: HeaderContentTypeParamEnum.ApplicationJson,
    'Content-Type': HeaderContentTypeParamEnum.ApplicationJson,
  },
  withCredentials: true,
};

const onFullfilledRequest = (response: AxiosResponse) => response;

const onRejectedResponse = (error: any): any => {
  const responseStatusCode = error.response?.status;
  const serverErrorCodes = [
    500, 501, 502, 503, 504, 505, 506, 507, 508, 510, 511,
  ];
  const isServerError = serverErrorCodes.includes(responseStatusCode);
  const isNetworkError = !error.response;
  let notificationContent;

  if (isNetworkError) {
    notificationContent = LabelEnum.NetworkError;
  } else if (isServerError) {
    notificationContent = LabelEnum.ServerError;
  }

  if (notificationContent && !axios.isCancel(error)) {
    store.dispatch(
      showNotificationAction({
        type: MessageTypeEnum.Error,
        content: notificationContent,
      })
    );
  }

  return Promise.reject(error);
};

const axiosInstance = axios.create(baseConfig);

axiosInstance.interceptors.response.use(
  onFullfilledRequest,
  onRejectedResponse
);

export const ApiService = {
  get(path: string, config?: AxiosRequestConfig): AxiosPromise {
    return axiosInstance.get(path, {
      ...{ responseType: 'json' },
      ...config,
    });
  },

  getMock(mockName: string, config?: AxiosRequestConfig): AxiosPromise {
    return axios.get(`/mocks/${mockName}.json`, {
      ...{ responseType: 'json' },
      ...config,
    });
  },

  getPdf(path: string): AxiosPromise {
    return axios.get(path, {
      responseType: 'blob',
    });
  },

  post<T = unknown>(path: string, data?: {}, config?: AxiosRequestConfig) {
    return axiosInstance.post<T>(path, data, {
      ...{
        responseType: 'json',
      },
      ...config,
    });
  },

  put(path: string, data?: {}, config?: AxiosRequestConfig): AxiosPromise {
    return axiosInstance.put(path, data, {
      ...{
        responseType: 'json',
      },
      ...config,
    });
  },

  patch(path: string, data: {}, config?: AxiosRequestConfig): AxiosPromise {
    return axiosInstance.patch(path, data, {
      ...{
        headers: {
          'Content-Type': HeaderContentTypeParamEnum.ApplicationJsonPatchJson,
        },
      },
      ...config,
    });
  },

  delete(path: string, config?: AxiosRequestConfig) {
    return axiosInstance.delete(path, {
      ...{
        responseType: 'json',
      },
      ...config,
    });
  },

  upload(path: string, data?: {}, config?: AxiosRequestConfig) {
    return axiosInstance.post(path, data, {
      ...{
        headers: {
          'Content-Type': HeaderContentTypeParamEnum.ApplicationData,
        },
      },
      ...config,
    });
  },
};
