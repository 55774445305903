import acquisitionReducer from './acquisitionSlice';

export * from './state.interface';

// Actions
export { setIsAcquisitionRead } from './acquisitionSlice';

// Selectors
export { selectIsAcquisitionRead } from './acquisitionSelectors';

// Reducer
export default acquisitionReducer;
