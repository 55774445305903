import { LabelEnum } from 'app/shared/enums/Label.enum';

const phoneNumber = (value: string) => {
  const re = /^(\+34)[6-9]\d{8}$/;

  if (!value) {
    return undefined;
  }

  return re.test(value) ? undefined : LabelEnum.PhoneNumberInvalid;
};

export default phoneNumber;
