import notificationReducer from './notificationSlice';

export * from './state.interface';
export * from './actionPayload.interface';

// Selectors
export { selectNotification } from './notificationSelectors';

// Synchronous actions
export {
  showNotificationAction,
  hideNotificationAction,
} from './notificationSlice';

// Hooks
export { useNotification } from './notificationHooks';

// Reducer
export default notificationReducer;
