import { Checkbox } from '@mui/material';

import { Icon } from '../Icon';
import { RowCheckboxProps } from './RowCheckbox.interfaces';
import { useStyles } from './RowCheckbox.styles';

export default function RowCheckbox({
  label,
  value,
  checked,
  name,
  icon,
  onChange,
}: RowCheckboxProps) {
  const { classes, cx } = useStyles();

  return (
    <label className={cx(classes.root, { [classes.rootChecked]: checked })}>
      {!!icon && <Icon name={icon} />}
      <div className={classes.label}>{label}</div>
      <Checkbox
        value={value}
        name={name}
        onChange={onChange}
        checked={checked}
        icon={<Icon name={'CheckboxUnchecked'} />}
        checkedIcon={<Icon name={'CheckboxChecked'} />}
        disableRipple
      />
    </label>
  );
}
