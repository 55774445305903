import React from 'react';
import { Typography } from '@mui/material';

import { useStyles } from './FormSectionHeader.styles';
import { FormSectionHeaderProps } from './FormSectionHeader.interfaces';

export default function FormSectionHeader({
  label,
  subLabel,
}: FormSectionHeaderProps) {
  const { classes } = useStyles();

  return (
    <header className={classes.root}>
      {label && (
        <Typography className={classes.label} variant="h4">
          {label}
        </Typography>
      )}
      {subLabel && (
        <Typography className={classes.sublabel}>{subLabel}</Typography>
      )}
    </header>
  );
}
