import { Components, Theme } from '@mui/material';
import { palette, primaryColors } from './palette';
import { typography } from './typography';

export const components: Components<Omit<Theme, 'components'>> = {
  MuiPaper: {
    styleOverrides: {
      root: {
        borderRadius: '20px',
        boxShadow: '0px 0px 14px 17px rgba(0,0,0,0.1)',
      },
    },
  },
  MuiPickersCalendarHeader: {
    styleOverrides: {
      root: {
        textTransform: 'capitalize',
      },
    },
  },
  MuiPickersDay: {
    styleOverrides: {
      root: {
        '&.Mui-selected': {
          backgroundColor: palette.brand,
          color: palette.white,

          '&:focus': {
            backgroundColor: palette.brand,
          },

          '&.Mui-selected:focus:hover': {
            backgroundColor: palette.brand,
          },

          '&:hover, &:focus:hover': {
            backgroundColor: palette['brand-20'],
          },
        },
        '&:not(.Mui-selected)': {
          border: 'none',
        },
      },
    },
  },
  PrivatePickersMonth: {
    styleOverrides: {
      root: {
        '&.Mui-selected': {
          backgroundColor: palette.brand,
          color: palette.white,

          '&:focus': {
            backgroundColor: palette.brand,
          },

          '&.Mui-selected:focus:hover': {
            backgroundColor: palette.brand,
          },

          '&:hover, &:focus:hover': {
            backgroundColor: palette['brand-20'],
          },
        },
      },
    },
  },
  PrivatePickersYear: {
    styleOverrides: {
      modeDesktop: {
        '& .Mui-selected': {
          transition: 'background-color 300ms',
          backgroundColor: palette.brand,
          color: palette.white,

          '&:focus': {
            backgroundColor: palette.brand,
          },

          '&.Mui-selected:focus:hover': {
            backgroundColor: palette.brand,
          },

          '&:hover, &:focus:hover': {
            backgroundColor: palette['brand-20'],
          },
        },
      },
    },
  },
  MuiAutocomplete: {
    styleOverrides: {
      paper: {
        borderRadius: 10,
      },
      listbox: {
        '& li.MuiAutocomplete-option': {
          ...typography.body?.S,
          padding: 10,
        },
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        ...typography.body?.S,
        color: primaryColors.black,

        '&[data-shrink="false"]': {
          top: 2,
        },

        '&[data-shrink="false"].MuiFormLabel-filled': {
          opacity: 0,
        },

        '&.Mui-focused': {
          color: primaryColors.black,
        },

        '&.Mui-focused input': {
          color: palette.neutral.grey[40],
          backgroundColor: palette.white,
        },
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      notchedOutline: {
        borderWidth: 2,
        borderColor: palette.neutral.grey[20],
      },
      root: {
        borderRadius: 10,
        ...typography.body?.S,
        backgroundColor: palette.white,

        '& input': {
          zIndex: 2,

          // chrome autofill background workaround
          '&:-webkit-autofill': {
            transitionDelay: '9999s',
            transitionProperty: 'background-color, color',
          },
        },

        '& button': {
          zIndex: 2,
        },

        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: palette.ui.focus,
          boxShadow: `0 0 0 3px ${palette.ui['focus-10']}`,
        },

        '&:not(.Mui-focused):not(.Mui-error) > input:not([value=""]) ~ fieldset':
          {
            backgroundColor: palette['brand-10'],
            borderColor: palette.brand,
          },

        '&:hover:not(.Mui-focused):not(.Mui-error)>input[value=""] ~ fieldset':
          {
            borderColor: palette.neutral.grey[30],
          },

        '&:hover:not(.Mui-focused) > input:not([value=""]) ~ fieldset': {
          borderWidth: 3,
        },

        '&.Mui-error fieldset': {
          borderColor: palette.ui.error,
          backgroundColor: palette.ui['error-10'],
        },

        '.MuiInputAdornment-root': {
          color: primaryColors.black,
          zIndex: 1,
        },
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        borderRadius: 50,
      },
      contained: {
        color: primaryColors.white,
      },
      sizeLarge: {
        paddingTop: 16,
        paddingBottom: 16,
        textTransform: 'initial',
        ...typography.body?.L,
        fontWeight: '600',
      },
    },
  },
};
