import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(
  ({ spacing, breakpoints, typography, palette }) => ({
    root: {},
    optionsContainer: {
      display: 'grid',
      gridTemplateColumns: '1fr',
      gap: spacing(1),
      marginTop: spacing(2),

      [breakpoints.up('md')]: {
        gap: spacing(3),
        marginTop: spacing(3),
      },
    },
    optionCheckbox: {
      '& .MuiCheckbox-root': {
        padding: '0 9px 0px 0px',
      },

      '& span': {
        color: palette.black,
        ...typography.body.XS,

        [breakpoints.up('md')]: {
          ...typography.body.S,
        },
      },
    },
    optionRadio: {
      '& .MuiRadio-root': {
        padding: '0 9px 0px 0px',
      },

      '& span': {
        color: palette.black,
        ...typography.body.XS,

        [breakpoints.up('md')]: {
          ...typography.body.S,
        },
      },
    },
  })
);
