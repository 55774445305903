import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import React from 'react';

import { CheckboxControlProps } from './CheckboxControl.interfaces';
import { useStyles } from './CheckboxControl.styles';

export default function CheckboxControl(props: CheckboxControlProps) {
  const { input, label, meta, onValueChange } = props;

  const { classes, cx } = useStyles();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;

    input.onChange(checked);

    if (onValueChange) {
      onValueChange({
        value: checked,
      });
    }
  };

  const handleBlur = () => {
    input.onBlur();
  };

  return (
    <FormControlLabel
      data-field-key={input.name}
      classes={{
        label: cx(classes.label, props?.classes?.label),
      }}
      checked={!!input.value}
      control={
        <Checkbox
          className={cx({
            [classes.error]: meta.touched && meta.error,
          })}
          onBlur={handleBlur}
          onChange={handleChange}
        />
      }
      label={label}
    />
  );
}
