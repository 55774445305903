import { Snackbar, SnackbarOrigin } from '@mui/material';
import { useEffect, useState } from 'react';

import {
  hideNotificationAction,
  selectNotification,
} from 'app/store/data/notification';
import { Alert } from 'app/components/Alert';
import { useTypedDispatch } from 'app/shared/hooks/useTypedDispatch';
import { useTypedSelector } from 'app/shared/hooks/useTypedSelector';

const anchor: SnackbarOrigin = {
  vertical: 'top',
  horizontal: 'right',
};

const transitionDuration = 150;

export default function Notification() {
  const dispatch = useTypedDispatch();
  const [isVisible, setIsVisible] = useState(false);
  const notification = useTypedSelector(selectNotification);

  useEffect(() => {
    setIsVisible(!!notification);
  }, [notification]);

  const handleClose = () => {
    setIsVisible(false);
    setTimeout(() => {
      dispatch(hideNotificationAction());
    }, transitionDuration);
  };

  return (
    <Snackbar
      onClose={handleClose}
      open={isVisible}
      anchorOrigin={anchor}
      autoHideDuration={notification?.duration}
      transitionDuration={transitionDuration}
    >
      <div>
        <Alert title={notification?.content} type={notification?.type} />
      </div>
    </Snackbar>
  );
}
