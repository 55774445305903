import { LabelEnum } from '../enums/Label.enum';
import { OfferDetails } from '../enums/OfferDetails.enum';

export const offerDetailsToLabelMap: Record<OfferDetails, LabelEnum> = {
  [OfferDetails.GeneralMedicine]: LabelEnum.GeneralMedicine,
  [OfferDetails.PediatricsAndChildcare]: LabelEnum.PediatricsAndChildcare,
  [OfferDetails.MedicalOrSurgicalHospitalization]:
    LabelEnum.MedicalOrSurgicalHospitalization,
  [OfferDetails.EmergencyService]: LabelEnum.EmergencyService,
  [OfferDetails.Specialties]: LabelEnum.Specialties,
  [OfferDetails.RehabilitationAndPhysiotherapy]:
    LabelEnum.RehabilitationAndPhysiotherapy,
  [OfferDetails.DentalCoverage]: LabelEnum.DentalCoverage,
  [OfferDetails.Copay]: LabelEnum.Copay,
  [OfferDetails.Lack]: LabelEnum.Lack,
};
