import { createAsyncThunk } from '@reduxjs/toolkit';
import { QuotationService } from 'app/services/quotation.service';

import { RootState } from 'app/store';
import {
  fetchQuotationCopyAction,
  selectQuotationId,
} from 'app/store/data/quotation';
import { clearQuotationResults } from '.';
import { setIsQuotationModified } from '../ui';

export const fetchQuotationResultsAction = createAsyncThunk(
  'quotation/initQuotationResults',
  async (_, { getState, dispatch }) => {
    const state = getState() as RootState;
    const quotationId = selectQuotationId(state);

    dispatch(clearQuotationResults());

    try {
      const { data } = await QuotationService.getQuotationResultsFor(
        quotationId
      );

      return data;
    } catch (error) {
      await dispatch(fetchQuotationCopyAction());
      dispatch(clearQuotationResults());
      dispatch(setIsQuotationModified(true));
      throw error;
    }
  }
);
