import { Container } from '@mui/system';

import CalculationForm from 'app/components/CalculationForm/CalculationForm';
import { CalculationHeader } from 'app/components/CalculationHeader';
import { useStyles } from './CalculationView.styles';

export default function CalculationView() {
  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      <Container maxWidth="sm">
        <CalculationHeader />
        <CalculationForm />
      </Container>
    </div>
  );
}
