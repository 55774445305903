import { Outlet } from 'react-router-dom';

import { CookiePanel } from 'app/components/CookiePanel';
import { LoadingScreen } from 'app/components/LoadingScreen';
import { Notification } from 'app/components/Notification';
import { useRootView } from './useRootView';

export default function RootView() {
  const { isLoading } = useRootView();

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <>
      <Notification />
      <Outlet />
      <CookiePanel />
    </>
  );
}
