import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';

import { LabelEnum } from 'app/shared/enums/Label.enum';
import { ControlContainer } from 'app/components/ControlContainer';
import { Icon } from 'app/components/Icon';
import { PasswordControlProps } from './PasswordControl.interfaces';

export default function PasswordControl(props: PasswordControlProps) {
  const { input, label, meta, onControlBlur, onValueChange, tooltip } = props;

  const error = Boolean(meta.touched && (meta.error || meta.submitError));

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const { value } = event.target;

    input.onBlur();

    if (onControlBlur) {
      onControlBlur({ value, shouldPatch: meta.valid });
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    input.onChange(value);

    if (onValueChange) {
      onValueChange({
        value,
        shouldPatch: false,
      });
    }
  };

  return (
    <ControlContainer
      label={label}
      meta={meta}
      name={input.name}
      tooltip={tooltip}
    >
      <TextField
        type="password"
        autoComplete="on"
        onChange={handleChange}
        onBlur={handleBlur}
        error={error}
        placeholder={LabelEnum.Password}
        value={input.value}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Icon name="Lock" />
            </InputAdornment>
          ),
        }}
      />
    </ControlContainer>
  );
}
