import { LabelEnum } from 'app/shared/enums/Label.enum';
import {
  GTMElement,
  GTMEvent,
  GTMSection,
  useGTM,
} from 'app/shared/hooks/useGTM';
import { Icon } from '../Icon';
import { useStyles } from './CallAgent.styles';

const UserLogo = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.userIconContainer}>
      <Icon name="UserRounded" className={classes.userIcon} />
    </div>
  );
};

const CallAgent = () => {
  const { classes } = useStyles();
  const { addResultsEvent } = useGTM();

  const handlePhoneNumberClick = () => {
    addResultsEvent(GTMEvent.ClickToCall, {
      section: GTMSection.Header,
      element: { type: GTMElement.Link, text: LabelEnum.CallAgentPhoneNumber },
    });
  };

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <p className={classes.label}>{LabelEnum.CallAgent}</p>
        <a
          className={classes.phoneNumber}
          href={`tel:${LabelEnum.CallAgentFullPhoneNumber}`}
          onClick={handlePhoneNumberClick}
        >
          <Icon name="PhoneRounded" />
          <span>{LabelEnum.CallAgentPhoneNumber}</span>
        </a>
      </div>
      <UserLogo />
    </div>
  );
};

export default CallAgent;
