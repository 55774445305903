import React from 'react';
import { useStyles } from './ProgressIndicator.styles';
import { ProgressIndicatorProps } from './ProgressIndicator.interfaces';

export default function ProgressIndicator({
  total,
  active,
}: ProgressIndicatorProps) {
  const { classes, cx } = useStyles();

  return (
    <div className={classes.root}>
      {new Array(total).fill(undefined).map((_, index) => (
        <div
          key={index}
          className={cx(classes.step, {
            [classes.stepActive]: active >= index,
          })}
        />
      ))}
    </div>
  );
}
