import { LabelEnum } from 'app/shared/enums/Label.enum';
import { Button } from '../Button';
import { FormControlErrorText } from '../FormControlErrorText';
import { Icon } from '../Icon';
import { PhoneInput } from '../PhoneInput';
import { OfferDetailsContactProps } from './OfferDetailsContact.interfaces';
import { useStyles } from './OfferDetailsContact.styles';
import { useOfferDetailsContact } from './useOfferDetailsContact';
export default function OfferDetailsContact({
  close,
}: OfferDetailsContactProps) {
  const { classes, cx } = useStyles();
  const { viewState, handleCallRequestSubmit, phoneInput, onPhoneNumberClick } =
    useOfferDetailsContact();

  return (
    <div className={classes.root}>
      <img
        alt=""
        srcSet={'/images/contact@2x.png 2x, /images/contact.png 1x'}
        src={'/images/contact.png'}
        className={classes.image}
      />
      {viewState === 'DEFAULT' && (
        <form className={classes.content} onSubmit={handleCallRequestSubmit}>
          <h5 className={cx(classes.header, classes.title)}>
            {LabelEnum.ContactAdvisor}
          </h5>
          <PhoneInput {...phoneInput} error={!!phoneInput.error} />
          {phoneInput.error && (
            <FormControlErrorText className={classes.error}>
              {phoneInput.error}
            </FormControlErrorText>
          )}
          <Button
            color="Brand"
            size="Medium"
            className={classes.button}
            type="submit"
          >
            {LabelEnum.WeWillCallYou}
          </Button>
          <h5 className={cx(classes.header, classes.callUs)}>
            {LabelEnum.OrCallUs}
          </h5>
          <a
            className={classes.contactNumber}
            href={`tel:${LabelEnum.CallAgentFullPhoneNumber}`}
            onClick={onPhoneNumberClick}
          >
            <Icon name="PhoneOutline" />
            <p>{LabelEnum.ContactNumber}</p>
          </a>
          <p className={classes.sidenote}>{LabelEnum.WorkingHours}</p>
        </form>
      )}
      {viewState === 'SUCCESS' && (
        <div className={cx(classes.content, classes.contentRequested)}>
          <h5 className={classes.requestedTitle}>
            {LabelEnum.CallRequestedSuccessfully_1}
          </h5>
          <h5 className={classes.requestedBody}>
            {LabelEnum.CallRequestedSuccessfully_2}
          </h5>
        </div>
      )}
      {viewState === 'AFTER_HOURS' && (
        <div className={cx(classes.content, classes.contentRequested)}>
          <h5 className={classes.requestedTitle}>
            {LabelEnum.CallRequestedAfterHours_1}
          </h5>
          <h5 className={classes.requestedBody}>
            {LabelEnum.CallRequestedAfterHours_2}
          </h5>
        </div>
      )}
      {viewState !== 'DEFAULT' && (
        <Button
          color="Brand"
          size="Medium"
          className={classes.closeButton}
          onClick={close}
        >
          {LabelEnum.Close}
        </Button>
      )}
    </div>
  );
}
