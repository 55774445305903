import React from 'react';
import { useStyles } from './ResultsFilters.styles';
import { ResultsFiltersProps } from './ResultsFilters.interfaces';
import { FilterGroup } from '../FilterGroup';

export default function ResultsFilters(props: ResultsFiltersProps) {
  const { classes } = useStyles();
  const { filters, selectedFilters, setSelectedFilters } = props;

  return (
    <div className={classes.root} data-cy="filters">
      {filters.map(({ filters, group, type }) => (
        <FilterGroup
          key={group}
          classes={{ content: classes.filters }}
          options={filters}
          onSelectedOptionsChange={setSelectedFilters}
          selectedOptions={selectedFilters}
          label={group}
          type={type}
        />
      ))}
    </div>
  );
}
