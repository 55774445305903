import { LabelEnum } from 'app/shared/enums/Label.enum';
import { OfferDetails } from 'app/shared/enums/OfferDetails.enum';
import { offerDetailsToLabelMap } from 'app/shared/helpers/offerDetailsToLabelMap';
import { Icon } from '../Icon';
import {
  OfferDetailsTableProps,
  OfferDetailsTableRowProps,
} from './OfferDetailsTable.interfaces';
import { useStyles } from './OfferDetailsTable.styles';

const OfferDetailsTableRow = ({
  children,
  label,
}: OfferDetailsTableRowProps) => {
  const { classes } = useStyles();

  return (
    <div className={classes.row}>
      <div className={classes.rowLabel}>{label}</div>
      <div className={classes.rowValue}>{children}</div>
    </div>
  );
};

const OfferDetailsTableIcon = ({ value }: { value: boolean }) => {
  return value ? <Icon name="CheckCircle" /> : <Icon name="CloseCircle" />;
};

export default function OfferDetailsTable({ offer }: OfferDetailsTableProps) {
  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      {Object.values(OfferDetails).map((value) => (
        <OfferDetailsTableRow label={offerDetailsToLabelMap[value]} key={value}>
          <OfferDetailsTableIcon value={offer[value]} />
        </OfferDetailsTableRow>
      ))}
      <OfferDetailsTableRow label={LabelEnum.Sheet}>
        <a
          className={classes.hypers}
          href={offer?.additional?.productCardUrl}
          target="_blank"
          rel="noreferrer"
          download
        >
          {LabelEnum.Discharge}
        </a>
      </OfferDetailsTableRow>
    </div>
  );
}
