import React from 'react';
import { useStyles } from './OfferDetailsView.styles';
import { OfferDetailsViewProps } from './OfferDetailsView.interfaces';
import { Outlet } from 'react-router-dom';
import { Box, Grid, Typography } from '@mui/material';
import { LabelEnum } from 'app/shared/enums/Label.enum';
import { Icon } from 'app/components/Icon';
import { insurersConfig } from 'app/shared/helpers/insurersConfig';
import { OfferDetailsTable } from 'app/components/OfferDetailsTable';
import { OfferDetailsContact } from 'app/components/OfferDetailsContact';
import { Button } from 'app/components/Button';
import { RouteEnum } from 'app/shared/enums/Route.enum';
import { Modal } from 'app/components/Modal';
import { useOfferDetailsView } from './useOfferDetailsView';

export default function OfferDetailsView(props: OfferDetailsViewProps) {
  const { classes, cx } = useStyles();
  const { offer, handleClose, background } = useOfferDetailsView();

  if (!offer) {
    return null;
  }

  const logoIcon = offer.insurerName && insurersConfig[offer.insurerName]?.icon;
  const price = offer.pricePerPerson?.finalPrice || offer.price?.finalPrice;
  const isGroup =
    !!offer.pricePerPerson &&
    offer.pricePerPerson.finalPrice !== offer.price!.finalPrice;

  return (
    <Modal fullScreen open close={handleClose}>
      <div className={classes.root}>
        <Box display="flex" flexDirection="row" gap={6}>
          <Grid container>
            <Grid item xs={12} md={8}>
              <div className={classes.header}>
                <Typography
                  component={'h2'}
                  variant="h4"
                  className={classes.title}
                >
                  {LabelEnum.InsuranceCoverage}
                </Typography>
                <div className={classes.ad}>
                  <Icon name="ShieldCheckFilled" width={32} height={32} />
                  <span className={classes.adText}>{LabelEnum.Ad}</span>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className={classes.insurer}>
                {logoIcon && <Icon name={logoIcon} />}
                <span
                  className={cx(classes.price, {
                    [classes.priceGroup]: isGroup,
                  })}
                  data-cy="offer-details-price"
                >
                  <strong>
                    {price?.toFixed(2)}
                    {'\u00A0'}
                    {LabelEnum.Pound}{' '}
                  </strong>
                  {isGroup ? `\n${LabelEnum.ForAllPersons}` : LabelEnum.ForAll}
                </span>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.table}>
                <OfferDetailsTable offer={offer} />
              </div>
            </Grid>
          </Grid>
          <div className={classes.offerDetails}>
            <OfferDetailsContact close={handleClose} />
          </div>
        </Box>
        <footer className={classes.footer}>
          <Button
            color="Brand"
            size="Medium"
            component="Link"
            to={RouteEnum.OFFER_DETAILS_CONTACT}
            state={{ background }}
            data-cy="learn-more-button"
          >
            {LabelEnum.LearnMore}
          </Button>
        </footer>
        <Outlet />
      </div>
    </Modal>
  );
}
