import { LabelEnum } from '../enums/Label.enum';

export const composeValidators =
  (...validators: any) =>
  (value: string | boolean | number) =>
    validators.reduce(
      (
        error: LabelEnum,
        validator: (value: string | boolean | number) => LabelEnum | undefined
      ) => error || validator(value),
      undefined
    );
