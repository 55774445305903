import { DictService } from 'app/services/dict.service';
import isEmpty from 'lodash-es/isEmpty';
import isEqual from 'lodash-es/isEqual';
import { useEffect, useState } from 'react';
import { DictionariesEnum } from '../enums/Dictionaries.enum';
import { RequestParamsInterface } from '../interfaces/RequestParams.interface';

export const useDictionary = <T,>(
  name: DictionariesEnum,
  params?: Partial<RequestParamsInterface>
): T | undefined => {
  const [dictionary, setDictionary] = useState<T | undefined>(undefined);
  const [prevParams, setPrevParams] = useState<
    Partial<RequestParamsInterface> | undefined
  >(params);

  useEffect(() => {
    if (isEqual(prevParams, params)) {
      return;
    }
    setPrevParams(params);

    if (params === undefined || !isEmpty(params)) {
      setPrevParams(params);
      DictService.getDictionary(name, params as RequestParamsInterface)
        .then(({ data }) => {
          setDictionary(data as T);
        })
        .catch((err: Error) => {});
    }
  }, [name, params, prevParams]);

  return dictionary;
};
