import { RouteEnum } from 'app/shared/enums/Route.enum';
import { Navigate, useParams, useSearchParams } from 'react-router-dom';

export default function QuotationByRecoveryKey() {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const recoveryKey = searchParams.get('key');
  const productId = searchParams.get('productId');

  return (
    <Navigate
      to={`${RouteEnum.ROOT_VIEW}/${RouteEnum.CALCULATOR_VIEW}`}
      replace={true}
      state={{ quotationId: params.recoveryKey || recoveryKey, productId }}
    />
  );
}
