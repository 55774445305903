import { AnonymisedFields } from 'app/store/data/quotation';
import { FieldsEnum } from '../enums/Fields.enum';
import { QuotationInterface } from '../interfaces/Quotation.interface';

export const isAnonymised = (value: unknown) =>
  typeof value === 'string' && value.includes('***');

export const getAnonymisedFields = (
  quotation: QuotationInterface
): AnonymisedFields => ({
  [FieldsEnum.EmailAddress]: isAnonymised(quotation.emailAddress),
  [FieldsEnum.PhoneNumber]: isAnonymised(quotation.phoneNumber),
});

export const clearAnonymization = (text: string) => text.replace(/\*/g, '');

const PII_QUESTIONS = [FieldsEnum.EmailAddress, FieldsEnum.PhoneNumber];

export const isQuestionPII = (key: FieldsEnum) => PII_QUESTIONS.includes(key);

export const anonymize = (value: string | number | boolean) =>
  String(value).slice(0, 2) + '***';
