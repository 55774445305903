import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(({ spacing }) => ({
  root: {
    '& input[type=number]': {
      MozAppearance: 'textfield',
    },

    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },

    '& img': {
      width: 'auto',
      height: 'auto',
      maxWidth: 60,
      maxHeight: 60,
      zIndex: 2,
    },
  },

  endAdornment: {},
}));
