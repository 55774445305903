import { AxiosPromise } from 'axios';

import { ApiService } from './api.service';
import { RequestParamsInterface } from 'app/shared/interfaces/RequestParams.interface';
import { AnswerGroup } from 'app/shared/interfaces/AnswerGroup.interface';

export type DictionaryEntryKey = string | number;
export type DictionaryEntryLabel = string;
export type DictionaryEntryShortLabel = string;
export type DictionaryEntrySubLabel = string;

export interface DictionaryEntry {
  key: DictionaryEntryKey;
  value: DictionaryEntryLabel;
  valueShort?: DictionaryEntryShortLabel;
  additionals?: Array<string>;
  additionalInfo?: string;
  selected?: boolean;
  group?: AnswerGroup['groupName'];
  installmentsInfo?: {
    installments: Array<string>;
    installmentLabel: string;
    oneInstallmentGainLabel: string;
    oneInstallmentGainValue: string;
    finalPriceLabel: string;
    internalInstallments: null | boolean;
    internalInstallmentsCount: null | number;
    internalInstallmentPrice: null | string;
    internalInstallmentsRrso: null | string;
  };
  subLabels?: Array<DictionaryEntrySubLabel>;
  message?: string;
  disabled?: boolean;
}

export type Dictionary<T extends DictionaryEntry = DictionaryEntry> = Array<T>;

const getDictionaryAfterTimeout = (
  dictionaryKey: string,
  timeout: number,
  params?: RequestParamsInterface
): AxiosPromise<Dictionary> =>
  new Promise((resolve) => {
    setTimeout(
      () =>
        resolve(ApiService.get(`dictionaries/${dictionaryKey}`, { params })),
      timeout
    );
  });
const promiseList: Array<Promise<object>> = [];

export const DictService = {
  getDictionary(
    dictionaryKey: string,
    params?: RequestParamsInterface
  ): AxiosPromise<Dictionary> {
    const promise = ApiService.get(`dictionaries/${dictionaryKey}`, { params })
      // HACK: sometimes backend returns null with no reason
      .then((response) => {
        if (!response.data) {
          return new Promise((resolve) =>
            setTimeout(
              () =>
                resolve(
                  ApiService.get(`dictionaries/${dictionaryKey}`, { params })
                ),
              100
            )
          );
        }
        return response;
      })
      .catch(() => getDictionaryAfterTimeout(dictionaryKey, 200, params))
      .catch(() => getDictionaryAfterTimeout(dictionaryKey, 400, params));
    promiseList.push(promise as AxiosPromise<Dictionary>);
    return promise as AxiosPromise<Dictionary>;
  },

  resolveAllDict() {
    return Promise.all(promiseList).finally(() => {
      promiseList.length = 0;
    });
  },
};
