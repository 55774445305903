import { AppLogo } from '../AppLogo';
import { CallAgent } from '../CallAgent';
import { ProgressIndicator } from '../ProgressIndicator';
import { useStyles } from './ResultsHeader.styles';

export default function ResultsHeader() {
  const { classes } = useStyles();

  return (
    <header className={classes.root}>
      <div className={classes.content}>
        <a href={window.location.protocol + '//' + window.location.host}>
          <AppLogo />
        </a>
        <CallAgent />
      </div>
      <ProgressIndicator total={1} active={1} />
    </header>
  );
}
