import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(({ palette }) => ({
  root: {
    borderRadius: '20px',
    '& input::placeholder': {
      color: palette.black,
    },

    '& .MuiInputAdornment-root': {
      marginRight: 0,
    },
    '& .MuiSvgIcon-root': {
      color: palette.brand,
    },
  },
}));
