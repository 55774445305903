import { AxiosPromise } from 'axios';

import { ApiService } from 'app/services/api.service';
import { fillPathParam } from 'app/shared/helpers/router';
import {
  RequestCallParams,
  RequestCallResponse,
} from 'app/shared/interfaces/RequestCall.interface';
import omit from 'lodash-es/omit';

const CallService = {
  url: '/health-insurances/quotations/:quotationId/callbacks', // for testing purposes

  requestCall(data: RequestCallParams): AxiosPromise<RequestCallResponse> {
    const url = fillPathParam(this.url, {
      key: 'quotationId',
      value: data.quotationId,
    });

    return ApiService.post(url, omit(data, 'quotationId'));
  },
};

Object.freeze(CallService);

export { CallService };
