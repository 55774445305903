import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(
  ({ spacing, palette, typography, breakpoints }) => ({
    root: {
      [breakpoints.up('md')]: {
        padding: spacing(3, 6),
        border: '1px solid',
        borderRadius: 10,
        borderColor: palette.neutral.grey[20],
      },
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: spacing(3, 0),

      '&:not(:last-child)': {
        borderBottom: '1px solid',
        borderColor: palette.neutral.grey[20],
      },
    },
    rowLabel: {
      color: palette.neutral.grey[50],
      ...typography.body.S,
      flex: '1 0 75%',
    },
    rowValue: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flex: '1 0 25%',
      maxHeight: 18,
    },
    hypers: {
      ...typography.body.S,
      padding: 0,
      border: 'none',
      textDecorationLine: 'underline',
      backgroundColor: 'transparent',
      color: palette.neutral.grey[50],
      cursor: 'pointer',
    },
  })
);
