import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import debounce from 'lodash-es/debounce';

import { QuotationService } from 'app/services/quotation.service';
import { FieldsEnum } from 'app/shared/enums/Fields.enum';
import { JsonPatchValueType } from 'app/shared/types/JsonPatchValue.type';
import { RootState } from 'app/store';
import { DateTime } from 'luxon';
import {
  selectQuotation,
  selectQuotationId,
  setQuotationQuestionAction,
} from '.';

const date = DateTime.now();
const formattedDate = date.toFormat('yyyy-MM-dd');

export const createNewQuotationAction = createAsyncThunk(
  'quotation/createNewQuotation',
  async () => {
    const { data } = await QuotationService.create();

    return data;
  }
);

export const fetchQuotationCopyAction = createAsyncThunk(
  'quotation/setQuotationCopy',
  async (_, { getState }) => {
    const state = getState() as RootState;
    const quotation = selectQuotation(state);

    try {
      const { data } = await QuotationService.requestForCopy(quotation.id!);

      return quotation.startDate
        ? { ...data, startDate: quotation.startDate }
        : data;
    } catch (error) {}
  }
);

export const initQuotationAction = createAsyncThunk(
  'quotation/initQuotation',
  async (_, { dispatch }) => {
    try {
      const { data } = await QuotationService.getLast();

      return data;
    } catch (error) {
      dispatch(createNewQuotationAction());
    }
  }
);

export const initQuotationByIdAction = createAsyncThunk(
  'quotation/initQuotation',
  async ({ quotationId }: { quotationId: string }, { dispatch }) => {
    // The recovery id we get in the url has uppercase and lowercase letters.
    // Backend only accepts id with lowercase letters.
    const parsedQuotationId = quotationId.toLowerCase();

    try {
      const { data } = await QuotationService.getQuotationById(
        parsedQuotationId
      );

      return data;
    } catch (error) {}
  }
);

export const submitQuotationAction = createAsyncThunk(
  'quotation/submitQuotation',
  async (
    { isPPC = false }: { isPPC?: boolean },
    { getState, rejectWithValue }
  ) => {
    const state = getState() as RootState;
    const quotation = selectQuotation(state);
    const quotationId = selectQuotationId(state);
    let data = Object.assign({}, quotation, {
      anonymised: undefined,
    });

    if (isPPC) {
      data = Object.assign({}, quotation, {
        anonymised: undefined,
        startDate: formattedDate,
        currentlyInsured: false,
      });
    }

    try {
      await QuotationService.requestForQuotationResults({
        quotationId,
        data,
      });
    } catch (error) {
      console.log('error');
      const err = error as AxiosError;

      return rejectWithValue(err);
    }
  }
);

export const updateQuotationDebouncedAction = createAsyncThunk(
  'quotation/updateQuotationDebounced',
  async (
    {
      name,
      value,
    }: {
      name: FieldsEnum;
      value: JsonPatchValueType;
    },
    { getState, dispatch }
  ) => {
    const state = getState() as RootState;
    const quotationId = selectQuotationId(state);

    const updateQuotation = () => {
      if (typeof value !== 'object' || Array.isArray(value) || value === null) {
        QuotationService.update(quotationId, name, value);
        dispatch(setQuotationQuestionAction({ name, value }));
      } else {
        Object.entries(value).forEach(([key, value]) => {
          QuotationService.update(quotationId, key, value);
          dispatch(
            setQuotationQuestionAction({ name: key as FieldsEnum, value })
          );
        });
      }
    };

    return debounce(updateQuotation, 100)();
  }
);
