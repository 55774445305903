import LoadingButton from '@mui/lab/LoadingButton';
import { Box } from '@mui/material';
import { Form } from 'app/components/Form';
import { PPCCalculationFormConfig } from 'app/components/PPCCalculationFormConfig';
import { LabelEnum } from 'app/shared/enums/Label.enum';
import { Icon } from '../Icon';
import { usePPCCalculationForm } from './usePPCCalculationForm';

export default function PPCCalculationForm() {
  const { handleSubmit, initialValues, isLoading, handleSubmitCapture } =
    usePPCCalculationForm();

  return (
    <Form
      action={handleSubmit}
      initialValues={initialValues}
      isLoading={isLoading}
      onSubmitCapture={handleSubmitCapture}
      FormButton={SubmitButton}
    >
      <PPCCalculationFormConfig />
    </Form>
  );
}

const SubmitButton = ({ isLoading }: { isLoading: boolean }) => (
  <LoadingButton
    type="submit"
    size="large"
    variant="contained"
    disableElevation
    fullWidth
    loading={isLoading}
  >
    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
      {LabelEnum.CalculationFormSubmitButtonPPC}
      <Icon name="ArrowRight" />
    </Box>
  </LoadingButton>
);
