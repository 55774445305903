import { FormConsents } from 'app/components/FormConsents';
import { FormRow } from 'app/components/FormRow';
import { FormScreen } from 'app/components/FormScreen';
import { FormSection } from 'app/components/FormSection';
import { InsurantsAgeFormRow } from 'app/components/InsurantsAgeFormRow';
import { BusinessFunctionsEnum } from 'app/shared/enums/BusinessFunctions.enum';
import { ControlTypeEnum } from 'app/shared/enums/ControlType.enum';
import { DictionariesEnum } from 'app/shared/enums/Dictionaries.enum';
import { FieldsEnum } from 'app/shared/enums/Fields.enum';
import { LabelEnum } from 'app/shared/enums/Label.enum';
import { MessageTypeEnum } from 'app/shared/enums/MessageType.enum';
import { MessagesEnum } from 'app/shared/enums/Messages.enum';
import { ScreensEnum } from 'app/shared/enums/Screens.enum';
import { composeValidators } from 'app/shared/helpers/composeValidators';
import { scrollToTarget } from 'app/shared/helpers/scrollToTarget';
import { useFormValidate } from 'app/shared/hooks/useFormValidate';
import { useQuestionVisibility } from 'app/shared/hooks/useQuestionVisibility';
import { useTypedSelector } from 'app/shared/hooks/useTypedSelector';
import { selectQuotation } from 'app/store/data/quotation';
import { selectIsQuotationModified } from 'app/store/data/ui';
import {
  date,
  email,
  phoneNumber,
  required,
} from 'app/validators/fieldLevelValidators';
import requiredCheckboxGroup from 'app/validators/fieldLevelValidators/requiredCheckboxGroup';
import requiredDate from 'app/validators/fieldLevelValidators/requiredDate';
import requiredOption from 'app/validators/fieldLevelValidators/requiredOption';
import zipCode from 'app/validators/fieldLevelValidators/zipCode';
import { get } from 'lodash-es';
import { useEffect } from 'react';

export const calculationFormScreens = {
  [ScreensEnum.CalculationFormScreen1]: {
    label: undefined,
    subLabel: undefined,
  },
  [ScreensEnum.CalculationFormScreen2]: {
    label: LabelEnum.SectionLabel,
    subLabel: LabelEnum.SectionSublabel,
  },
};

export default function CalculationFormConfig({
  activeScreenIndex,
}: {
  activeScreenIndex: number;
}) {
  const checkVisiblity = useQuestionVisibility();
  const isRegulationConsentGiven = useRegulationConsent();
  useModifiedFormValidation();

  return (
    <>
      <FormScreen screenKey={ScreensEnum.CalculationFormScreen1}>
        <FormSection>
          <FormRow
            name={FieldsEnum.NumberOfPersons}
            label={LabelEnum.NumberOfPersonsControlLabel}
            type={ControlTypeEnum.RadioSelect}
            tooltip={LabelEnum.NumberOfPersonsTooltip}
            answersDictionaryKey={DictionariesEnum.NumberOfPersons}
            validate={requiredOption}
            onChange={[BusinessFunctionsEnum.OnNumberOfPersonsChange]}
          />
          <InsurantsAgeFormRow
            name={FieldsEnum.InsuredPersons}
            type={ControlTypeEnum.InsurersSelect}
            answersDictionaryKey={DictionariesEnum.InsuredPersons}
          />
          <FormRow
            name={FieldsEnum.PostalCode}
            label={LabelEnum.ZipCode}
            type={ControlTypeEnum.ZipCode}
            tooltip={LabelEnum.ZipCodeTooltip}
            validate={composeValidators(required, zipCode)}
          />
          <FormRow
            name={FieldsEnum.StartDate}
            validate={composeValidators(
              requiredDate,
              date({
                minDate: {
                  days: 0,
                },
                maxDate: {
                  days: 365,
                },
              })
            )}
            type={ControlTypeEnum.Date}
            label={LabelEnum.StartDateLabel}
            tooltip={LabelEnum.StartDateTooltip}
            minDate={{
              days: 0,
            }}
            maxDate={{
              days: 365,
            }}
          />

          <FormRow
            name={FieldsEnum.CurrentlyInsured}
            label={LabelEnum.CurrentlyInsured}
            type={ControlTypeEnum.Radio}
            answersDictionaryKey={DictionariesEnum.YesNo}
            validate={requiredOption}
          />
          <FormRow
            name={FieldsEnum.PreviousInsurerName}
            label={LabelEnum.PreviousInsurerName}
            placeholder={LabelEnum.PreviousInsurerPlaceholder}
            type={ControlTypeEnum.InsurersSelect}
            tooltip={LabelEnum.PreviousInsurerNameTooltip}
            answersDictionaryKey={DictionariesEnum.PreviousInsurerName}
            validate={requiredOption}
            visible={checkVisiblity(FieldsEnum.PreviousInsurerName)}
          />
          <FormRow
            name={FieldsEnum.Products}
            label={LabelEnum.Products}
            type={ControlTypeEnum.CheckboxGroup}
            answersDictionaryKey={DictionariesEnum.Products}
            validate={requiredCheckboxGroup}
          />
        </FormSection>
      </FormScreen>
      {activeScreenIndex > 0 && (
        <FormScreen screenKey={ScreensEnum.CalculationFormScreen2}>
          <FormSection
            {...calculationFormScreens[ScreensEnum.CalculationFormScreen2]}
          >
            <FormRow
              name={FieldsEnum.EmailAddress}
              validate={composeValidators(required, email)}
              type={ControlTypeEnum.Email}
              label={LabelEnum.EmailControlLabel}
              shouldPatch={isRegulationConsentGiven}
              messages={[
                {
                  key: MessagesEnum.EmailControlInfoMessage,
                  content: LabelEnum.EmailControlInfoMessage,
                  type: MessageTypeEnum.Info,
                  visible: true,
                },
              ]}
            />
            <FormRow
              name={FieldsEnum.PhoneNumber}
              validate={phoneNumber}
              type={ControlTypeEnum.PhoneNumber}
              label={LabelEnum.PhoneNumberControlLabel}
              shouldPatch={isRegulationConsentGiven}
              messages={[
                {
                  key: MessagesEnum.PhoneNumberControlInfoMessage,
                  content: LabelEnum.PhoneNumberControlInfoMessage,
                  type: MessageTypeEnum.Info,
                  visible: true,
                },
              ]}
            />
            <FormConsents />
          </FormSection>
        </FormScreen>
      )}
    </>
  );
}

const useModifiedFormValidation = () => {
  const { validateForm } = useFormValidate();
  const isQuotationModified = useTypedSelector(selectIsQuotationModified);

  useEffect(() => {
    if (isQuotationModified) {
      validateForm();
      scrollToTarget('[data-field-error="true"]');
    }
  }, [isQuotationModified, validateForm]);
};

const useRegulationConsent = (): boolean => {
  const quotation = useTypedSelector(selectQuotation);
  return !!get(quotation, 'consents.regulations.consent');
};
