import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit';

import acquisitionReducer from './data/acquisition';
import notificationReducer from './data/notification';
import offerComparisonReducer from './data/offerComparison';
import quotationReducer from './data/quotation';
import quotationResultsReducer from './data/quotationResults';
import UIReducer from './data/ui';
import userReducer from './data/user';

export const store = configureStore({
  reducer: {
    notification: notificationReducer,
    quotation: quotationReducer,
    quotationResults: quotationResultsReducer,
    offerComparison: offerComparisonReducer,
    user: userReducer,
    acquisition: acquisitionReducer,
    ui: UIReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
