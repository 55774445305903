import LoadingButton from '@mui/lab/LoadingButton';
import { businessFunctionsMutators } from 'app/services/businessFunctions.service';
import { LabelEnum } from 'app/shared/enums/Label.enum';
import arrayMutators from 'final-form-arrays';
import { Form as ReactFinalForm } from 'react-final-form';
import { FormProps } from './Form.interfaces';
import { useStyles } from './Form.styles';

export default function Form(props: FormProps) {
  const {
    action,
    children,
    initialValues,
    isLoading,
    submitLabel = LabelEnum.Enviar,
    onSubmitCapture,
    FormButton,
  } = props;

  const { classes } = useStyles();

  return (
    <ReactFinalForm
      onSubmit={action}
      initialValues={initialValues}
      mutators={{
        ...arrayMutators,
        ...businessFunctionsMutators,
      }}
      render={(props) => (
        <form
          onSubmit={props.handleSubmit}
          noValidate
          onSubmitCapture={() => {
            onSubmitCapture?.(props);
          }}
        >
          <div className={classes.formContent}>{children}</div>
          {FormButton ? (
            <FormButton isLoading={isLoading} />
          ) : (
            <LoadingButton
              className={classes.button}
              type="submit"
              size="large"
              variant="contained"
              disableElevation
              fullWidth
              loading={isLoading}
            >
              {submitLabel}
            </LoadingButton>
          )}
        </form>
      )}
    />
  );
}
