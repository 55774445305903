import { AxiosPromise } from 'axios';

import { ApiService } from 'app/services/api.service';
import { UserInterface } from 'app/shared/interfaces/User.interface';
import { CredentialsInterface } from 'app/shared/interfaces/Credentials.interface';
import { b64EncodeUnicode } from 'app/services/util.service';

export const getAuthorizationKey = ({
  username,
  password,
}: CredentialsInterface) => {
  const authString = `${username}:${password}`;
  return 'Basic' + b64EncodeUnicode(authString);
};

export const UserService = {
  url: 'users',

  getCurrentUserInfo(): AxiosPromise<UserInterface> {
    return ApiService.get(`${this.url}/me`);
  },

  logout() {
    return ApiService.get(`${this.url}/me`, { headers: { Logout: '' } });
  },

  login(credentials: CredentialsInterface): AxiosPromise<UserInterface> {
    return ApiService.get(`${this.url}/me`, {
      headers: {
        Authorization: getAuthorizationKey(credentials),
      },
    });
  },
};
