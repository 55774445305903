import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles<void, 'removeInsurer'>()(
  ({ typography, palette, spacing, breakpoints }, _, classes) => ({
    root: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      top: 80,
      maxWidth: '100%',
      overflowY: 'auto',
      overflowX: 'auto',

      [breakpoints.up('md')]: {
        top: spacing(4),
      },
    },
    title: {
      color: palette.black,
      display: 'none',
      [breakpoints.up('md')]: { display: 'block' },
    },
    label: {
      ...typography.body.S,
      color: palette.neutral.grey[50],
    },
    price: {
      ...typography.body.S,
      color: palette.black,
      whiteSpace: 'pre-line',
      textAlign: 'center',

      '& strong': {
        ...typography.h4,
      },
    },

    bodyCell: {
      display: 'grid',
      placeContent: 'center',
    },
    offerHead: {
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'center',
      position: 'relative',
      paddingBottom: spacing(4),
      gap: spacing(1),

      [`&:hover .${classes.removeInsurer}`]: {
        display: 'block',
      },

      '& > svg, & > img': {
        width: 'auto',
        height: 'auto',
        maxWidth: 140,
        maxHeight: 65,
      },
    },
    removeInsurer: {
      position: 'absolute',
      top: spacing(1),
      left: 'calc(50% + 50px)',
      backgroundColor: 'unset',
      display: 'none',

      '& svg': {
        width: 18,
        height: 18,
      },
      '& path': {
        fill: palette.neutral.grey[30],
      },
    },
    button: {
      width: 130,
    },
    hypers: {
      ...typography.body.S,
      padding: 0,
      border: 'none',
      textDecorationLine: 'underline',
      backgroundColor: 'transparent',
      color: palette.neutral.grey[50],
      cursor: 'pointer',
    },
  })
);
