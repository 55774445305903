import React from 'react';
import { useStyles } from './IconButton.styles';
import { IconButtonProps } from './IconButton.interfaces';

export default function IconButton({
  children,
  onClick,
  className,
  ...props
}: IconButtonProps) {
  const { classes, cx } = useStyles();

  return (
    <button
      className={cx(classes.root, className)}
      onClick={onClick}
      type="button"
      {...props}
    >
      {children}
    </button>
  );
}
