// https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding

export const b64EncodeUnicode = (str: string) => {
  return btoa(
    encodeURIComponent(str).replace(
      /%([0-9A-F]{2})/g,
      function toSolidBytes(match: string, p1: string) {
        return String.fromCharCode(parseInt(`0x${p1}`, 16));
      }
    )
  );
};
