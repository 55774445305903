import { DictionariesEnum } from 'app/shared/enums/Dictionaries.enum';
import useDelayedValue from 'app/shared/hooks/useDelayedValue';
import { useDictionary } from 'app/shared/hooks/useDictionary';
import { CityWithProvince } from 'app/shared/interfaces/CityWithProvince.interface';
import { isZipCodeFormatValid } from 'app/validators/fieldLevelValidators/zipCode';
import { ChangeEvent, FocusEvent, useMemo } from 'react';
import { ZipCodeControlProps } from './ZipCodeControl.interfaces';

export const useZipCodeControl = (props: ZipCodeControlProps) => {
  const { input, onValueChange, onControlBlur } = props;
  const tag = useTag(input.value);
  const delayedTag = useDelayedValue(tag, 200);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    input.onChange(value);

    if (onValueChange) {
      onValueChange({ value, shouldPatch: false });
    }
  };

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    const value = event.target.value;
    input.onBlur(event);

    onControlBlur?.({ value });
  };

  return { tag, delayedTag, handleBlur, handleChange };
};

const useTag = (zipCode: string): string | undefined => {
  const isFormatValid = isZipCodeFormatValid(zipCode);
  const params = useMemo(
    () => (isFormatValid ? { zipCode: zipCode } : {}),
    [zipCode, isFormatValid]
  );
  const dictionary =
    useDictionary<CityWithProvince[]>(
      DictionariesEnum.CitiesWithProvince,
      params
    ) || [];
  const label =
    dictionary.length === 1 ? dictionary[0]?.city : dictionary[0]?.province;

  return isFormatValid ? label : undefined;
};
