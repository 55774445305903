import { FormRowContainerProps } from './FormRowContainer.interfaces';
import { useStyles } from './FormRowContainer.styles';
import { RowMessages } from 'app/components/RowMessages';

export default function FormRowContainer(props: FormRowContainerProps) {
  const { messages, children } = props;

  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      {children}
      <RowMessages messages={messages} />
    </div>
  );
}
