import { Container } from '@mui/system';
import { Navigate } from 'react-router-dom';
import Fade from '@mui/material/Fade';

import { RouteEnum } from 'app/shared/enums/Route.enum';
import { AppLogo } from 'app/components/AppLogo';
import { LoginForm } from 'app/components/LoginForm';
import { useStyles } from './LoginView.styles';
import { useTypedSelector } from 'app/shared/hooks/useTypedSelector';
import { selectIsLoggedIn } from 'app/store/data/user';

export default function LoginView() {
  const { classes } = useStyles();

  const isLoggedIn = useTypedSelector(selectIsLoggedIn);

  if (isLoggedIn) {
    return <Navigate to={`/${RouteEnum.CALCULATOR_VIEW}`} replace={true} />;
  }

  return (
    <div className={classes.root}>
      <Container maxWidth="xs">
        <Fade in={!isLoggedIn}>
          <div className={classes.loginFormWrapper}>
            <div className={classes.logo}>
              <AppLogo />
            </div>
            <LoginForm />
          </div>
        </Fade>
      </Container>
    </div>
  );
}
