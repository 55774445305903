import { useTypedSelector } from 'app/shared/hooks/useTypedSelector';
import { selectOffers } from 'app/store/data/quotationResults/quotationResultsSelectors';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

export const useOfferDetailsView = () => {
  const offer = useOffer();
  const location = useLocation();
  const navigate = useNavigate();

  const background = location.state.background;
  const handleClose = () => navigate(-1);

  return {
    offer,
    background,
    handleClose,
  };
};

const useOffer = () => {
  const offers = useTypedSelector(selectOffers);
  const { offerId } = useParams();

  return offerId ? offers[offerId] : undefined;
};
