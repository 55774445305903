export const me = {
  id: '19965538-895d-4ea3-a149-7b17555edb00',
  address: {
    zipCode: null,
    city: null,
    streetName: null,
    streetNumber: null,
    apartmentNumber: null,
  },
  username: null,
  firstName: null,
  lastName: null,
  phoneNumber: null,
  privileges: [],
  anonymous: true,
  emailVerified: false,
  operator: false,
  iban: null,
  consents: [],
};
