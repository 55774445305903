import { useEffect, useState, useRef } from 'react';

export default function useDelayedValue<T>(value: T, delay: number = 0) {
  const [delayedValue, setDelayedValue] = useState<T>(value);
  const timeoutRef = useRef<NodeJS.Timeout | null>();

  useEffect(() => {
    timeoutRef.current = setTimeout(() => {
      setDelayedValue(value);
    }, delay);

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [delay, value]);

  return delayedValue;
}
