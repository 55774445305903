import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(({ spacing, typography, palette }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: -spacing(2),
    userSelect: 'none',
    cursor: 'pointer',
  },
  text: {
    ...typography.body.S,
    color: palette.black,
  },
  icon: {
    width: 20,
    height: 20,
    border: '2px solid',
    borderRadius: 5,
    borderColor: palette.neutral.grey[20],
    backgroundColor: palette.white,
  },
  iconChecked: {
    borderColor: palette.brand,
    backgroundColor: palette.brand,
    position: 'relative',
    '& svg': {
      position: 'absolute',
      top: -1.875,
      left: -1.875,
    },
    '& path': {
      fill: palette.white,
    },
  },
}));
