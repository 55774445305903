import uiReducer from './uiSlice';

export * from './state.interface';

// Actions
export { setIsQuotationModified } from './uiSlice';

// Selectors
export { selectIsQuotationModified } from './uiSelectors';

// Reducer
export default uiReducer;
