import { BreakpointsOptions } from '@mui/material';

/**
 * DEFAULT BREAKPOINTS
 * xs, extra-small: 0px
 * sm, small: 600px
 * md, medium: 900px
 * lg, large: 1200px
 * xl, extra-large: 1536px
 */

export const breakpoints: BreakpointsOptions = {
  values: {
    xs: 0,
    sm: 600,
    desktop: 800,
    md: 940,
    lg: 1200,
    xl: 1536,
  },
};
