import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(({ spacing, palette }) => ({
  root: {
    display: 'flex',
    flex: '1 0 100%',
    margin: '0 auto',
    gap: spacing(2),
  },
  step: {
    height: 5,
    borderRadius: 3,
    flex: 1,
    backgroundColor: palette.neutral.grey[20],
  },
  stepActive: {
    backgroundColor: palette.brand,
  },
}));
