import { ControlTypeEnum } from 'app/shared/enums/ControlType.enum';
import {
  CheckboxControl,
  DateControl,
  EmailControl,
  PhoneNumberControl,
  RadioSelectControl,
  RadioControl,
  CheckboxGroupControl,
  SelectControl,
  InsurersSelectControl,
  ZipCodeControl,
} from 'app/components/Controls';

export const getFormControl = {
  [ControlTypeEnum.Email]: EmailControl,
  [ControlTypeEnum.Date]: DateControl,
  [ControlTypeEnum.PhoneNumber]: PhoneNumberControl,
  [ControlTypeEnum.RadioSelect]: RadioSelectControl,
  [ControlTypeEnum.Radio]: RadioControl,
  [ControlTypeEnum.CheckboxGroup]: CheckboxGroupControl,
  [ControlTypeEnum.Select]: SelectControl,
  [ControlTypeEnum.InsurersSelect]: InsurersSelectControl,
  [ControlTypeEnum.ZipCode]: ZipCodeControl,
  [ControlTypeEnum.Checkbox]: CheckboxControl,
};
