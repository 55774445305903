import {
  CssBaseline,
  StyledEngineProvider,
  ThemeProvider,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { BrowserRouter } from 'react-router-dom';

import Router from 'app/router/Router';
import { useGTMInit } from 'app/shared/hooks/useGTM';
import theme from 'app/styles/theme';

export default function App() {
  useGTMInit();

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="es">
          <CssBaseline />
          <BrowserRouter>
            <Router />
          </BrowserRouter>
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
