import React from 'react';
import { useStyles } from './Alert.styles';
import { AlertProps } from './Alert.interfaces';
import { MessageTypeEnum } from 'app/shared/enums/MessageType.enum';
import { Icon } from '../Icon';
import { IconName } from '../Icon/Icon.interfaces';

const typeToIcon: Record<MessageTypeEnum, IconName> = {
  [MessageTypeEnum.Error]: 'Exclametion',
  [MessageTypeEnum.Warning]: 'AlertRounded',
  [MessageTypeEnum.Info]: 'Bulb',
  [MessageTypeEnum.Success]: 'CheckRounded',
};

export default function Alert(props: AlertProps) {
  const { classes, cx } = useStyles();
  const { title, content, type = MessageTypeEnum.Info } = props;

  return (
    <div
      className={cx(classes.root, {
        [classes.info]: type === MessageTypeEnum.Info,
        [classes.error]: type === MessageTypeEnum.Error,
        [classes.success]: type === MessageTypeEnum.Success,
        [classes.warning]: type === MessageTypeEnum.Warning,
      })}
    >
      <AlertIcon type={type} />
      <div className={classes.text}>
        {!!title && <div className={classes.title}>{title}</div>}
        {!!content && <div className={classes.content}>{content}</div>}
      </div>
    </div>
  );
}

const AlertIcon = ({ type }: { type: MessageTypeEnum }) => {
  const { classes, cx } = useStyles();
  const icon = typeToIcon[type];

  return (
    <div
      className={cx(classes.iconContainer, {
        [classes.iconContainerInfo]: type === MessageTypeEnum.Info,
        [classes.iconContainerError]: type === MessageTypeEnum.Error,
        [classes.iconContainerSuccess]: type === MessageTypeEnum.Success,
        [classes.iconContainerWarning]: type === MessageTypeEnum.Warning,
      })}
    >
      <Icon name={icon} />
    </div>
  );
};
