import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(({ spacing, typography }) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: spacing(2, 4),
  },
  icon: { minHeight: 47 },
  text: {
    marginLeft: spacing(3),
  },
  label: {
    ...typography.body.XS,
    lineHeight: '16px',
  },
  title: {
    ...typography.body.S,
    fontWeight: 600,
  },
}));
