import React from 'react';
import { useStyles } from './ResultsSectionHeader.styles';
import { ResultsSectionHeaderProps } from './ResultsSectionHeader.interfaces';
import { Skeleton, Typography } from '@mui/material';
import { LabelEnum } from 'app/shared/enums/Label.enum';
import { Icon } from '../Icon';

export default function ResultsSectionHeader(props: ResultsSectionHeaderProps) {
  const { title, description, offerNumber, onModifyClick, isLoading } = props;
  const { classes } = useStyles();

  if (isLoading && !title) {
    return <ResultsSectionHeaderSkeleton />;
  }

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography component="h1" variant="h3" className={classes.title}>
          {title}
        </Typography>
        <div className={classes.subheader}>
          <span className={classes.description}>{description}</span>
          <button className={classes.anchor} onClick={onModifyClick}>
            <Icon name="PencilRounded" />
            <span>{LabelEnum.ChangeData}</span>
          </button>
        </div>
      </div>
      <div className={classes.number}>
        <span>{LabelEnum.DetailsOfOfferNr}</span>
        <span>{offerNumber}</span>
      </div>
    </div>
  );
}

const ResultsSectionHeaderSkeleton = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Skeleton width={450} variant="text" className={classes.title} />
        <div className={classes.subheader}>
          <Skeleton
            width={210}
            variant="text"
            className={classes.description}
          />
          <Skeleton
            width={160}
            variant="text"
            className={classes.description}
          />
        </div>
      </div>
      <div className={classes.number}>
        <Skeleton width={140} variant="text" />
        <Skeleton width={60} variant="text" />
      </div>
    </div>
  );
};
