import { PopperPlacementType } from '@mui/material';
import { useIsMobile } from 'app/shared/hooks/useIsMobile';
import { useEffect, useState } from 'react';

export const useFormTooltip = () => {
  const isMobile = useIsMobile();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const placement: PopperPlacementType = isMobile ? 'top-end' : 'right-start';

  const modifiers = [
    { name: 'offset', options: { offset: isMobile ? [-20, 0] : [-7, 17] } },
    {
      name: 'preventOverflow',
      options: {
        altAxis: true,
      },
    },
  ];

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleClose, { once: true });

    return () => {
      window.removeEventListener('scroll', handleClose);
    };
  }, [anchorEl]);

  return { placement, modifiers, anchorEl, handleClick, handleClose };
};
