import { Skeleton } from '@mui/material';
import { Container } from '@mui/system';
import { ResultsFilters } from 'app/components/ResultsFilters';
import { ResultsHeader } from 'app/components/ResultsHeader';
import { ResultsOffer } from 'app/components/ResultsOffer';
import { ResultsSectionHeader } from 'app/components/ResultsSectionHeader';
import { LabelEnum } from 'app/shared/enums/Label.enum';
import { useStyles } from './ResultsView.styles';
import { getCheckboxLabel } from './ResultsView.utils';
import { useResultsView } from './useResultsView';
import { Outlet } from 'react-router-dom';

export default function ResultsView() {
  const {
    offers,
    filters,
    isMobile,
    headerInfo,
    selectedFilters,
    isLoadingResults,
    changeCalculation,
    setSelectedFilters,
    compareOfferIds,
    onFooterPhoneNumberClick,
    toggleOfferComparison,
    handleOfferCompareLabelClick,
  } = useResultsView();
  const { classes } = useStyles();

  const isCompareLimitReached = compareOfferIds.length > 2;

  return (
    <Container maxWidth="md" className={classes.root}>
      <ResultsHeader />
      <main>
        <ResultsSectionHeader
          {...headerInfo}
          isLoading={isLoadingResults}
          onModifyClick={changeCalculation}
        />
        <section className={classes.content}>
          <ResultsFilters
            filters={filters}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
          />
          <ul className={classes.offers}>
            {offers.map((offer, index) => {
              const isCompareSelected = compareOfferIds.includes(offer.id);
              const isCompareDisabled =
                !isCompareSelected && isCompareLimitReached;
              const checkboxLabel = getCheckboxLabel(compareOfferIds, offer.id);

              return (
                <li key={index}>
                  <ResultsOffer
                    offer={offer}
                    isCompareSelected={isCompareSelected}
                    onCheckboxChange={toggleOfferComparison}
                    onCheckboxLabelClick={handleOfferCompareLabelClick}
                    checkboxLabel={checkboxLabel}
                    isMobile={isMobile}
                    compareDisabled={isCompareDisabled}
                  />
                </li>
              );
            })}
            {!offers.length && isLoadingResults && <OffersSkeleton />}
          </ul>
        </section>
      </main>
      <footer className={classes.footer}>
        <p>{LabelEnum.ListingFooterPart1}</p>
        <p>
          {LabelEnum.ListingFooterPart2}
          <a
            href={`tel:${LabelEnum.CallAgentFullPhoneNumber}`}
            onClick={onFooterPhoneNumberClick}
          >
            <strong>{LabelEnum.CallAgentPhoneNumber}</strong>
          </a>
        </p>
      </footer>
      <Outlet />
    </Container>
  );
}

export const OffersSkeleton = () => {
  return (
    <>
      {new Array(3).fill(
        <li>
          <Skeleton
            height={180}
            variant="rectangular"
            style={{ borderRadius: 12 }}
          />
        </li>
      )}
    </>
  );
};
