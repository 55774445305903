import { Field } from 'react-final-form';

import { LabelEnum } from 'app/shared/enums/Label.enum';
import { Form } from 'app/components/Form';
import { useStyles } from './LoginForm.styles';
import { useLoginForm } from './useLoginForm';
import { EmailControl, PasswordControl } from '../Controls';
import { FieldsEnum } from 'app/shared/enums/Fields.enum';
import {
  email,
  required,
  requiredEmail,
} from 'app/validators/fieldLevelValidators';
import { composeValidators } from 'app/shared/helpers/composeValidators';

export default function LoginForm() {
  const { classes } = useStyles();

  const { handleSubmit, isLoading } = useLoginForm();

  return (
    <Form
      action={handleSubmit}
      isLoading={isLoading}
      submitLabel={LabelEnum.Login}
    >
      <div className={classes.field}>
        <Field
          name={FieldsEnum.Username}
          component={EmailControl}
          label={LabelEnum.EmailControlLabel}
          validate={composeValidators(requiredEmail, email)}
        />
      </div>
      <div className={classes.field}>
        <Field
          name={FieldsEnum.Password}
          component={PasswordControl}
          label={LabelEnum.PasswordControlLabel}
          validate={required}
        />
      </div>
    </Form>
  );
}
