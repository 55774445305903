import { BrowserOptions, init } from '@sentry/browser';
import { Integrations } from '@sentry/tracing';

import { REACT_APP_SENTRY_DSN } from 'app/shared/helpers/envConfig';

const config: BrowserOptions = {
  dsn: REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  environment: process.env.NODE_ENV,
};

export const initSentry = () => {
  if (!REACT_APP_SENTRY_DSN) {
    console.warn('Sentry DSN missing');
    return;
  }

  init(config);
};
