import React from 'react';

import { LabelEnum } from 'app/shared/enums/Label.enum';

import {
  ResultsOfferBadgeConfig,
  ResultsOfferBadgeProps,
  ResultsOfferBadgeType,
} from './ResultsOfferBadge.interfaces';
import { useStyles } from './ResultsOfferBadge.styles';
import { Icon } from '../Icon';

const configs: Record<ResultsOfferBadgeType, ResultsOfferBadgeConfig> = {
  cheapest: {
    icon: 'OfferCheapest',
    text: LabelEnum.RecommendedOfferCheapest,
  },
  quality: {
    icon: 'OfferQuality',
    text: LabelEnum.RecommendedOfferQuality,
  },
  overall: {
    icon: 'OfferOverall',
    text: LabelEnum.RecommendedOfferOverall,
  },
};

const ResultsOfferBadge = ({ type }: ResultsOfferBadgeProps) => {
  const { classes } = useStyles();
  const { text, icon } = configs[type];

  return (
    <div className={classes.root}>
      <Icon className={classes.icon} name={icon} />
      <div className={classes.text}>
        <div className={classes.label}>{LabelEnum.RecommendedOffer}</div>
        <div className={classes.title}>{text}</div>
      </div>
    </div>
  );
};

export default ResultsOfferBadge;
