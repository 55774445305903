import { MutableState, Tools } from 'final-form';
import get from 'lodash-es/get';

import { patchValue } from 'app/services/businessFunctionsHelpers';
import { BusinessFunctionsEnum } from 'app/shared/enums/BusinessFunctions.enum';
import { FieldsEnum } from 'app/shared/enums/Fields.enum';
import { adjustLength } from 'app/shared/helpers/array';
import { InsuredPersonInterface } from 'app/shared/interfaces/CalculationData.interface';

export const businessFunctionsMutators = {
  [BusinessFunctionsEnum.OnAcceptAllConsentsChange](
    args: [],
    state: MutableState<object, Partial<any>>,
    utils: Tools<object, Partial<any>>
  ) {
    const { changeValue } = utils;
    const { values } = state.formState;
    const fieldValue = get(values, FieldsEnum.AcceptAllConsents);

    changeValue(state, FieldsEnum.RegulationsConsent, () => fieldValue);
    patchValue(FieldsEnum.RegulationsConsent, fieldValue);
    changeValue(state, FieldsEnum.MarketingConsent, () => fieldValue);
    patchValue(FieldsEnum.MarketingConsent, fieldValue);

    const phoneNumber = get(values, FieldsEnum.PhoneNumber);
    const emailAddress = get(values, FieldsEnum.EmailAddress);

    if (fieldValue) {
      patchValue(FieldsEnum.PhoneNumber, phoneNumber);
      patchValue(FieldsEnum.EmailAddress, emailAddress);
    }
  },
  [BusinessFunctionsEnum.OnRegulationsConsentChange](
    _args: [],
    state: MutableState<object, Partial<any>>
  ) {
    const { values } = state.formState;
    const regulationsConsent = get(values, FieldsEnum.RegulationsConsent);
    const phoneNumber = get(values, FieldsEnum.PhoneNumber);
    const emailAddress = get(values, FieldsEnum.EmailAddress);

    if (regulationsConsent) {
      patchValue(FieldsEnum.PhoneNumber, phoneNumber);
      patchValue(FieldsEnum.EmailAddress, emailAddress);
    }
  },
  [BusinessFunctionsEnum.OnNumberOfPersonsChange](
    _: [],
    state: MutableState<object, Partial<any>>,
    utils: Tools<object, Partial<any>>
  ) {
    const { changeValue } = utils;
    const { values } = state.formState;
    const defaultInsuredPerson = { age: undefined };

    const numberOfPersons = Number(
      get(values, FieldsEnum.NumberOfPersons) || 0
    );
    const insuredPersons: InsuredPersonInterface[] =
      get(values, FieldsEnum.InsuredPersons) || [];

    const updatedInsuredPersons = adjustLength(
      insuredPersons,
      numberOfPersons,
      defaultInsuredPerson
    );

    changeValue(state, FieldsEnum.InsuredPersons, () => updatedInsuredPersons);
    patchValue(FieldsEnum.InsuredPersons, updatedInsuredPersons);
  },
};
