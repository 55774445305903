import { LabelEnum } from 'app/shared/enums/Label.enum';

const validateRequired = <T>(
  value: string | number | boolean | undefined | null | Array<T>,
  error: LabelEnum
) => {
  return value !== undefined && value !== null && value !== ''
    ? undefined
    : error;
};

export default validateRequired;
