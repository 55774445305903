import { ReactComponent as TooltipOn } from './icons/tooltip-off.svg';
import { ReactComponent as TooltipOff } from './icons/tooltip-on.svg';
import { ReactComponent as Alert } from './icons/alert.svg';
import { ReactComponent as ChevronDown } from './icons/chevron-down.svg';
import { ReactComponent as Briefcase } from './icons/briefcase.svg';
import { ReactComponent as Coins } from './icons/coins.svg';
import { ReactComponent as Hospital2 } from './icons/hospital2.svg';
import { ReactComponent as Refresh } from './icons/refresh.svg';
import { ReactComponent as Check } from './icons/Check.svg';
import { ReactComponent as CheckboxChecked } from './icons/checkbox-checked.svg';
import { ReactComponent as CheckboxUnchecked } from './icons/checkbox-unchecked.svg';
import { ReactComponent as Mail } from './icons/mail.svg';
import { ReactComponent as Phone } from './icons/phone.svg';
import { ReactComponent as PhoneOutline } from './icons/phone-outlined.svg';
import { ReactComponent as PhoneRounded } from './icons/icon-rounded-phone.svg';
import { ReactComponent as PencilRounded } from './icons/icon-rounded-pencil.svg';
import { ReactComponent as UserRounded } from './icons/icon-rounded-user.svg';
import { ReactComponent as ChevronUp } from './icons/icon-chevron-up.svg';
import { ReactComponent as CheckCircle } from './icons/CheckCircle.svg';
import { ReactComponent as CloseCircle } from './icons/CloseCircle.svg';
import { ReactComponent as Close } from './icons/close.svg';
import { ReactComponent as ShieldCheckFilled } from './icons/shield-check-filled.svg';
import { ReactComponent as RadioChecked } from './icons/radio-checked.svg';
import { ReactComponent as RadioUnchecked } from './icons/radio-unchecked.svg';
import { ReactComponent as DrawnArrowRight } from './icons/drawn-arrow-right.svg';

import { ReactComponent as ArrowRight } from './icons/icon_arrow-right.svg';
import { ReactComponent as ArrowDown } from './icons/icon_arrow_down.svg';

import { ReactComponent as AlertRounded } from './icons/alert-rounded.svg';
import { ReactComponent as Bulb } from './icons/bulb.svg';
import { ReactComponent as CheckRounded } from './icons/check-rounded.svg';
import { ReactComponent as Exclametion } from './icons/exclametion.svg';
import { ReactComponent as Lock } from './icons/icon-lock.svg';

import { ReactComponent as OfferCheapest } from './icons/listing-cheapest.svg';
import { ReactComponent as OfferQuality } from './icons/listing-quality.svg';
import { ReactComponent as OfferOverall } from './icons/listing-overall.svg';

import { ReactComponent as Adeslas } from './icons/Adeslas-1.svg';
import { ReactComponent as Aegon } from './icons/Aegon-1.svg';
import { ReactComponent as Asefa } from './icons/AsefaSalud-1.svg';
import { ReactComponent as Asisa } from './icons/Asisa-1.svg';
import { ReactComponent as Axa } from './icons/Axa-1.svg';
import { ReactComponent as Fiatc } from './icons/Fiatc-1.svg';
import { ReactComponent as Nara } from './icons/Nara-1.svg';
import { ReactComponent as Sanitas } from './icons/Sanitas-1.svg';
import { ReactComponent as NuevaMutua } from './icons/NuevaMutua-1.svg';
import { ReactComponent as DKV } from './icons/DKV-1.svg';
import { ReactComponent as IMQ } from './icons/IMQ-1.svg';
import { ReactComponent as Allianz } from './icons/allianz.svg';

const insurers = {
  Adeslas,
  Aegon,
  Asefa,
  Asisa,
  Axa,
  DKV,
  Fiatc,
  Nara,
  Sanitas,
  NuevaMutua,
  IMQ,
  Allianz,
};

export const icons = {
  ...insurers,
  TooltipOn,
  ArrowRight,
  ArrowDown,
  TooltipOff,
  Alert,
  ChevronDown,
  Briefcase,
  Coins,
  Hospital2,
  Refresh,
  Check,
  CheckboxChecked,
  CheckboxUnchecked,
  RadioChecked,
  RadioUnchecked,
  Lock,
  Mail,
  Phone,
  PhoneOutline,
  PhoneRounded,
  UserRounded,
  PencilRounded,
  ChevronUp,
  OfferCheapest,
  OfferOverall,
  OfferQuality,
  CheckCircle,
  Close,
  CloseCircle,
  ShieldCheckFilled,
  AlertRounded,
  CheckRounded,
  Exclametion,
  Bulb,
  DrawnArrowRight,
};
