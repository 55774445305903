import { ControlContainer } from 'app/components/ControlContainer';
import { PhoneInput } from 'app/components/PhoneInput';
import { parseNumber } from 'app/shared/helpers/phone';
import { PhoneNumberControlProps } from './PhoneNumberControl.interfaces';

const prefix = '+34';
const mask = '999 999 999';

export default function PhoneNumberControl(props: PhoneNumberControlProps) {
  const { input, label, meta, onControlBlur, onValueChange, tooltip } = props;
  const isAnon = input.value.includes('*');

  const handleFocus = (_: React.FocusEvent<HTMLInputElement>) => {
    if (!isAnon) {
      return;
    }

    const value = '';
    input.onChange(value);

    if (onValueChange) {
      onValueChange({
        value,
        shouldPatch: false,
      });
    }
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const value = parseNumber(event.target.value);
    const formattedValue = `${prefix}${value}`;
    input.onBlur();

    if (onControlBlur) {
      onControlBlur({ value: formattedValue, shouldPatch: true });
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const eventValue = event.target.value
      .replace(prefix, '')
      .replace(/[^\d]/gi, '')
      .trim()
      .slice(0, 9);
    const formattedValue = `${prefix}${eventValue}`;
    input.onChange(formattedValue);

    if (onValueChange) {
      onValueChange({
        value: formattedValue,
        shouldPatch: false,
      });
    }
  };

  return (
    <ControlContainer
      label={label}
      meta={meta}
      name={input.name}
      tooltip={tooltip}
    >
      <PhoneInput
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        error={!!(meta.touched && meta.error)}
        value={input.value}
        mask={mask}
        maskChar={isAnon ? '*' : null}
      />
    </ControlContainer>
  );
}
