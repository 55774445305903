export enum QuotationStatusEnum {
  Calculated = 'CALCULATED',
  Checkout = 'CHECKOUT',
  CheckoutError = 'CHECKOUT_ERROR',
  ConvertedToPolicy = 'CONVERTED_TO_POLICY',
  Created = 'CREATED',
  InProgress = 'IN_PROGRESS',
  PolicyConversionRequested = 'POLICY_CONVERSION_REQUESTED',
  Redirected = 'REDIRECTED',
  SentToCalculation = 'SENT_TO_CALCULATION',
  Upsale = 'UPSALE',
}
