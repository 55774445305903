import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(
  ({ palette, spacing, breakpoints, typography }) => ({
    root: {
      position: 'relative',

      padding: spacing(4),
      [breakpoints.up('sm')]: {
        padding: spacing(6),
      },

      backgroundColor: palette.white,
      borderRadius: 12,
    },
    label: {
      ...typography.body.M,
      fontWeight: 600,
      color: palette.black,
      display: 'inline-block',

      [breakpoints.up('sm')]: {
        gap: spacing(4),
      },
    },
    fields: {
      display: 'flex',
      flexDirection: 'column',
      border: 'none',
      margin: 0,
      padding: 0,

      gap: spacing(4),
      [breakpoints.up('sm')]: {
        gap: spacing(5),
      },
    },
    tooltip: {
      position: 'absolute',
      top: spacing(3),
      right: spacing(3),
    },
  })
);
