import { useFormState } from 'react-final-form';
import { FieldsEnum } from '../enums/Fields.enum';

export const useQuestionVisibility = () => {
  const { values } = useFormState();

  return (name: FieldsEnum) => {
    switch (name) {
      case FieldsEnum.PreviousInsurerName:
        return typeof values[FieldsEnum.CurrentlyInsured] === 'string'
          ? values[FieldsEnum.CurrentlyInsured] === 'true'
          : values[FieldsEnum.CurrentlyInsured];
      default:
        return true;
    }
  };
};
