import { ControlContainer } from 'app/components/ControlContainer';
import { Icon } from 'app/components/Icon';
import { Select } from 'app/components/Select';
import { SelectOption } from 'app/components/Select/Select.interfaces';
import { LabelEnum } from 'app/shared/enums/Label.enum';
import { keyToConfig } from 'app/shared/helpers/insurersConfig';
import React from 'react';
import { InsurersSelectControlProps } from './InsurersSelectControl.interfaces';
import { useStyles } from './InsurersSelectControl.styles';

export default function InsurersSelectControl(
  props: InsurersSelectControlProps
) {
  const { dictionary = [], input, label, meta, onValueChange, tooltip } = props;
  const { classes, cx } = useStyles();

  const options = dictionary
    .filter(({ key }) => key !== 'none')
    .map(({ key }) => {
      return {
        value: key.toString(),
        label: keyToConfig[key]?.name,
        icon: keyToConfig[key]?.icon,
      };
    });

  const value = options.find(({ value }) => value === input.value) || null;

  const handleChange = (
    _: React.SyntheticEvent<Element, Event>,
    value: SelectOption | null
  ) => {
    input.onChange(value?.value);

    if (value?.value && onValueChange) {
      onValueChange({ value: value.value });
    }
  };

  return (
    <ControlContainer
      label={label}
      meta={meta}
      name={input.name}
      tooltip={tooltip}
    >
      <Select
        options={options}
        onChange={handleChange}
        onBlur={input.onBlur}
        value={value}
        textFieldProps={{
          placeholder: LabelEnum.PreviousInsurerPlaceholder,
          error: !!(meta.touched && meta.error),
          classes: {
            root: classes.input,
          },
        }}
        renderOption={(props, option) => (
          <li
            key={option.label}
            {...props}
            className={cx(props.className, classes.option)}
            data-value={option.value}
          >
            {option.icon && <Icon name={option.icon} />}
            {option.label || LabelEnum.Other}
          </li>
        )}
      />
    </ControlContainer>
  );
}
