import { useEffect, useState } from 'react';

import { ScreensEnum } from 'app/shared/enums/Screens.enum';
import {
  FormValues,
  mapQuotationToFormValues,
} from 'app/shared/helpers/quotation';
import { scrollToTarget } from 'app/shared/helpers/scrollToTarget';
import { GTMEvent, useGTM } from 'app/shared/hooks/useGTM';
import { usePrevious } from 'app/shared/hooks/usePrevious';
import { useTypedDispatch } from 'app/shared/hooks/useTypedDispatch';
import { useTypedSelector } from 'app/shared/hooks/useTypedSelector';
import { ErrorResponseDataInterface } from 'app/shared/interfaces/ErrorResponseData.interface';
import {
  selectQuotation,
  submitQuotationAction,
} from 'app/store/data/quotation';
import { setIsQuotationSubmittedByUserAction } from 'app/store/data/quotationResults';
import { selectIsQuotationModified } from 'app/store/data/ui';
import { FormRenderProps } from 'react-final-form';
import { calculationFormScreens } from '../CalculationFormConfig/CalculationFormConfig';

export function useCalculationForm() {
  const dispatch = useTypedDispatch();
  const initialValues = useInitialValues();
  const { activeScreenIndex, nextScreen } = useActiveScreen(initialValues);
  const [isLoading, setIsLoading] = useState(false);
  const { addDataLayer } = useGTM();

  const onSubmitError = (error: ErrorResponseDataInterface) => {
    setIsLoading(false);
  };

  const onSubmitSuccess = () => {
    addDataLayer(GTMEvent.FormSubmit);
    dispatch(setIsQuotationSubmittedByUserAction(true));
  };

  const handleSubmit = async () => {
    setIsLoading(true);

    const response = await dispatch(submitQuotationAction({}));

    if ('error' in response) {
      const err = response.error as ErrorResponseDataInterface;
      onSubmitError(err);
    } else {
      onSubmitSuccess();
    }
  };

  const handleSubmitCapture = ({ hasValidationErrors }: FormRenderProps) => {
    if (hasValidationErrors) {
      scrollToTarget('[data-field-error="true"]');
    }
  };

  return {
    activeScreenIndex,
    handleSubmit,
    handleSubmitCapture,
    initialValues,
    isLoading,
    nextScreen,
  };
}

const useInitialValues = () => {
  const quotation = useTypedSelector(selectQuotation);
  const [initialValues, setInitialValues] = useState(
    mapQuotationToFormValues(quotation)
  );

  useEffect(() => {
    if (quotation.id !== initialValues.id) {
      setInitialValues(mapQuotationToFormValues(quotation));
    }
  }, [initialValues.id, quotation]);

  return initialValues;
};

const screens = [
  ScreensEnum.CalculationFormScreen1,
  ScreensEnum.CalculationFormScreen2,
];

const useActiveScreen = (initialValues: FormValues) => {
  const [activeScreenIndex, setActiveScreenIndex] = useState(0);
  const prevScreenIndex = usePrevious(activeScreenIndex);
  const { addDataLayer } = useGTM();
  const nextScreen = () => setActiveScreenIndex(1);
  const isQuotationModified = useTypedSelector(selectIsQuotationModified);
  const isInitialScrollDisabled = isQuotationModified;

  const isFirstStepFilled =
    initialValues.emailAddress ||
    initialValues.phoneNumber ||
    initialValues.consents?.marketing ||
    initialValues.consents?.regualtions;

  useEffect(() => {
    if (isFirstStepFilled) {
      nextScreen();
    }
  }, [isFirstStepFilled]);

  useEffect(() => {
    if (isInitialScrollDisabled) {
      return;
    }

    const screen = document.getElementById(ScreensEnum.CalculationFormScreen2);

    if (screen && activeScreenIndex === 1) {
      screen.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [activeScreenIndex, isInitialScrollDisabled]);

  useEffect(() => {
    if (prevScreenIndex !== activeScreenIndex) {
      addDataLayer(GTMEvent.StepView, {
        step_number: activeScreenIndex + 1,
        step_name: calculationFormScreens[screens[activeScreenIndex]].label,
      });
    }
  }, [activeScreenIndex, addDataLayer, prevScreenIndex]);

  return { activeScreenIndex, nextScreen };
};
