import { AxiosPromise, AxiosRequestConfig } from 'axios';

import { ApiService } from 'app/services/api.service';
import { parseModelPath } from 'app/shared/helpers/quotation';
import { AcquisitionParams } from 'app/shared/interfaces/AcquisitionParams.interface';
import { QuotationInterface } from 'app/shared/interfaces/Quotation.interface';
import { QuotationResultWithOffersInterface } from 'app/shared/interfaces/QuotationResultWithOffers.interface';
import { JsonPatchValueType } from 'app/shared/types/JsonPatchValue.type';
import { QuotationState } from 'app/store/data/quotation/state.interface';

export const QuotationService = {
  url: 'health-insurances/quotations',

  create(
    initialValues: Partial<QuotationInterface> = {}
  ): AxiosPromise<QuotationInterface> {
    return ApiService.post(this.url, initialValues);
  },

  getLast(): AxiosPromise<QuotationInterface> {
    return ApiService.get(`${this.url}/last`, {});
  },

  getQuotationById(quotationId: string): AxiosPromise<QuotationInterface> {
    return ApiService.get(`${this.url}/${quotationId}`);
  },

  requestForCopy(quotationId: string): AxiosPromise<QuotationInterface> {
    return ApiService.post(`${this.url}/${quotationId}/copies`, {});
  },

  update(quotationId: string, modelPath: string, value: JsonPatchValueType) {
    return ApiService.patch(`${this.url}/${quotationId}`, [
      {
        op: 'replace',
        path: parseModelPath(modelPath),
        value,
      },
    ]);
  },

  requestForQuotationResults({
    quotationId,
    data,
  }: {
    quotationId: string;
    data: Omit<QuotationState, 'anonymised'>;
  }) {
    //TODO: check for cleared quotation status
    return ApiService.post(`${this.url}/${quotationId}/results`, data);
  },

  getQuotationResultsFor(
    quotationId: string,
    config?: AxiosRequestConfig
  ): AxiosPromise<QuotationResultWithOffersInterface[]> {
    return ApiService.get(`${this.url}/${quotationId}/results`, config);
  },

  setAcquisitionParams(quotationId: string, data: AcquisitionParams) {
    return ApiService.post(`${this.url}/${quotationId}/acquisitions`, data);
  },
};
