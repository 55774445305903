import { LabelEnum } from 'app/shared/enums/Label.enum';
import {
  getAbsoluteOfferDetailsContactPath,
  getAbsoluteOfferDetailsPath,
} from 'app/shared/helpers/router';
import {
  GTMElement,
  GTMEvent,
  GTMSection,
  useGTM,
} from 'app/shared/hooks/useGTM';
import { useIsMobile } from 'app/shared/hooks/useIsMobile';
import { useTypedDispatch } from 'app/shared/hooks/useTypedDispatch';
import { useTypedSelector } from 'app/shared/hooks/useTypedSelector';
import {
  removeComparedOfferId,
  selectComparedOfferIds,
} from 'app/store/data/offerComparison';
import { selectOffers } from 'app/store/data/quotationResults/quotationResultsSelectors';
import { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const useOfferComparisonView = () => {
  const { offers, removeOffer } = useComparedOffers();
  const { handleClose, handleContact } = useNavigationHandlers();
  const { addResultsEvent } = useGTM();

  const onContactButtonClick = (offerId: string) => {
    addResultsEvent(GTMEvent.OrderCallClick, {
      element: { type: GTMElement.Button, text: LabelEnum.ShouldWeCallYou },
      section: GTMSection.ComparePopup,
      offer: offers.find((offer) => offer.id === offerId),
    });
    handleContact(offerId);
  };

  useEffect(() => {
    if (offers.length === 0) {
      handleClose();
    }
  }, [handleClose, offers]);

  return {
    offers,
    removeOffer,
    handleClose,
    handleContact: onContactButtonClick,
  };
};

const useComparedOffers = () => {
  const dispatch = useTypedDispatch();
  const offersState = useTypedSelector(selectOffers);

  const offerIds = useTypedSelector(selectComparedOfferIds);
  const offers = offerIds.map((id) => offersState[id]).filter((o) => !!o);

  const removeOffer = (id: string) => dispatch(removeComparedOfferId(id));

  return { offers, removeOffer };
};

const useNavigationHandlers = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useIsMobile();

  const handleClose = useCallback(() => navigate(-1), [navigate]);

  const handleContact = useCallback(
    (offerId: string) => {
      const path = isMobile
        ? getAbsoluteOfferDetailsContactPath(offerId)
        : getAbsoluteOfferDetailsPath(offerId);
      navigate(path, {
        state: location.state,
        replace: true,
      });
    },
    [location.state, navigate, isMobile]
  );

  return { handleClose, handleContact };
};
