import { Checkbox as MUICheckbox } from '@mui/material';
import { MouseEvent } from 'react';

import { Icon } from '../Icon';
import { CheckboxProps } from './Checkbox.interfaces';
import { useStyles } from './Checkbox.styles';

export default function Checkbox({ onLabelClick, ...props }: CheckboxProps) {
  const { classes } = useStyles();

  const handleLabelClick = (event: MouseEvent) => {
    if (!props.disabled && onLabelClick) {
      onLabelClick(event);
    }
  };

  return (
    <label className={classes.root}>
      <MUICheckbox
        {...props}
        color="primary"
        classes={{
          ...props.classes,
        }}
        name={props.value}
        icon={<Icon name="CheckboxUnchecked" />}
        checkedIcon={<Icon name="CheckboxChecked" />}
        disableRipple
      />
      <span className={classes.text} onClick={handleLabelClick}>
        {props.label}
      </span>
    </label>
  );
}
