import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles({ name: 'Alert' })(
  ({ typography, spacing, palette }) => ({
    root: {
      backgroundColor: palette.ui['error-10'],
      padding: spacing(3),
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: spacing(3),
      borderRadius: 20,
    },
    title: {
      ...typography.h6,
      color: palette.black,
    },
    content: {
      ...typography.body.S,
      color: palette.neutral.grey[50],
    },
    text: {
      display: 'flex',
      flexDirection: 'column',
      gap: spacing(1),
    },
    iconContainer: {
      display: 'grid',
      placeContent: 'center',
      width: 50,
      height: 50,
      borderRadius: '100%',
    },
    iconContainerWarning: {
      backgroundColor: palette.ui['attention'],
    },
    iconContainerSuccess: {
      backgroundColor: palette.ui['success'],
    },
    iconContainerError: {
      backgroundColor: palette.ui['error'],
    },
    iconContainerInfo: {
      backgroundColor: palette.ui['info'],
    },
    warning: {
      backgroundColor: palette.ui['attention-10'],
    },
    success: {
      backgroundColor: palette.ui['success-10'],
    },
    error: {
      backgroundColor: palette.ui['error-10'],
    },
    info: {
      backgroundColor: palette.ui['info-10'],
    },
  })
);
