import React from 'react';
import { useStyles } from './Modal.styles';
import { ModalProps } from './Modal.interfaces';
import { Dialog, Theme } from '@mui/material';
import { IconButton } from '../IconButton';
import { Icon } from '../Icon';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function Modal({
  children,
  fullScreen = false,
  close,
  ...props
}: ModalProps) {
  const { classes } = useStyles();
  const matches = useMediaQuery<Theme>((theme) => theme.breakpoints.up('md'));

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      fullScreen={fullScreen && !matches}
      onClose={close}
      className={classes.modal}
      data-cy="modal"
      {...props}
    >
      <div className={classes.closeIcon}>
        <IconButton onClick={close} data-cy="close">
          <Icon name="Close" />
        </IconButton>
      </div>
      {children}
    </Dialog>
  );
}
