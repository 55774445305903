import { Tooltip, Typography } from '@mui/material';
import { ChangeEvent, MouseEvent } from 'react';

import { Button } from 'app/components/Button';
import { LabelEnum } from 'app/shared/enums/Label.enum';
import { getAbsoluteResultsContactPath } from 'app/shared/helpers/router';
import {
  GTMElement,
  GTMEvent,
  GTMSection,
  useGTM,
} from 'app/shared/hooks/useGTM';
import { useLocation } from 'react-router-dom';
import { Checkbox } from '../Checkbox';
import { InsuranceLogo } from '../InsuranceLogo';
import { ResultsOfferBadge } from '../ResultsOfferBadge';
import { ResultsOfferFilterList } from '../ResultsOfferFilterList';
import { TextButton } from '../TextButton';
import { ResultsOfferProps } from './ResultsOffer.interfaces';
import { useStyles } from './ResultsOffer.styles';

const ResultsOffer = (props: ResultsOfferProps) => {
  const {
    offer,
    isMobile,
    isCompareSelected = false,
    onCheckboxChange,
    onCheckboxLabelClick,
    checkboxLabel = LabelEnum.Compare,
    compareDisabled = false,
  } = props;
  const { classes, cx } = useStyles();
  const location = useLocation();
  const { addResultsEvent } = useGTM();

  const price = offer.pricePerPerson?.finalPrice || offer.price.finalPrice;
  const isGroup =
    !!offer.pricePerPerson &&
    offer.pricePerPerson.finalPrice !== offer.price!.finalPrice;

  const handleCheckboxChange = (_: ChangeEvent) => {
    onCheckboxChange(offer.id);
  };

  const handleCheckboxLabelClick = (event: MouseEvent) => {
    event.preventDefault();
    onCheckboxLabelClick(offer.id);
  };

  const callUsLocation = isMobile
    ? getAbsoluteResultsContactPath(offer.id)
    : offer.id;

  if (offer.doNotShow) {
    return null;
  }

  const [priceMain, priceFraction] = price.toFixed(2).split('.');

  const onPhoneNumberClick = () => {
    addResultsEvent(GTMEvent.ClickToCall, {
      element: {
        type: GTMElement.Button,
        text: `${LabelEnum.CallUsAt} ${LabelEnum.ContactNumber}`,
      },
      section: GTMSection.Offer,
      offer,
    });
  };

  const onOrderCallClick = () => {
    addResultsEvent(GTMEvent.OrderCallClick, {
      element: { type: GTMElement.Button, text: LabelEnum.ShouldWeCallYou },
      section: GTMSection.Offer,
      offer,
    });
  };

  const onOfferDetailsClick = () => {
    addResultsEvent(GTMEvent.OfferDetailsClick, {
      element: { type: GTMElement.Link, text: LabelEnum.CheckOfferDetails },
      section: GTMSection.Offer,
      offer,
    });
  };

  return (
    <div
      className={cx(classes.root, {
        [classes.rootCheapest]: offer.type === 'cheapest',
        [classes.rootQuality]: offer.type === 'quality',
        [classes.rootOverall]: offer.type === 'overall',
      })}
      data-cy="offer"
    >
      {offer.type && offer.type !== 'regular' && (
        <ResultsOfferBadge type={offer.type} />
      )}
      <div className={classes.card}>
        <div className={classes.gridContainer}>
          <div className={classes.logo}>
            {!!offer.insurerName && <InsuranceLogo name={offer.insurerName} />}
          </div>
          <div className={classes.filters}>
            <ResultsOfferFilterList filters={offer.filter} />
          </div>
          <div className={classes.buttons}>
            <Button
              color="Grey"
              size="Small"
              component="a"
              href={`tel:${LabelEnum.CallAgentFullPhoneNumber}`}
              state={{ background: location }}
              onClick={onPhoneNumberClick}
              className={classes.callUsButton}
            >
              <span>
                {LabelEnum.CallUsAt}
                {'\n'}
                <strong>{LabelEnum.ContactNumber}</strong>
              </span>
            </Button>
            <Button
              color="Brand"
              size="Small"
              component="Link"
              to={callUsLocation}
              state={{ background: location }}
              onClick={onOrderCallClick}
            >
              {LabelEnum.ShouldWeCallYou}
            </Button>
          </div>
          <div className={classes.compare}>
            <Tooltip
              title={LabelEnum.DisabledCompareTooltip}
              disableFocusListener={!compareDisabled}
              disableHoverListener={!compareDisabled}
              disableTouchListener={!compareDisabled}
              enterTouchDelay={0}
            >
              <div>
                <Checkbox
                  classes={{
                    checked: classes.checkboxChecked,
                    disabled: classes.checkboxDisabled,
                  }}
                  label={checkboxLabel}
                  checked={isCompareSelected}
                  onChange={handleCheckboxChange}
                  onLabelClick={handleCheckboxLabelClick}
                  disabled={compareDisabled}
                />
              </div>
            </Tooltip>
          </div>
          <div className={classes.price}>
            <div className={classes.priceTitle} data-cy="offer-price">
              <Typography variant="h3" component="strong">
                {priceMain}
              </Typography>
              <Typography variant="h5" component="strong">
                {`.${priceFraction}\u00A0${LabelEnum.Pound} `}
              </Typography>
              {isGroup ? LabelEnum.ForAllPersons : LabelEnum.ForAll}
            </div>
            <TextButton
              to={offer.id}
              state={{ background: location }}
              onClick={onOfferDetailsClick}
              className={classes.detailsTextButton}
            >
              {LabelEnum.CheckOfferDetails}
            </TextButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResultsOffer;
