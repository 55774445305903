import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(
  ({ typography, palette, spacing, breakpoints }) => ({
    root: {},
    title: {
      whiteSpace: 'pre-line',
      color: palette.black,
      marginTop: spacing(3),
      [breakpoints.up('sm')]: {
        marginTop: spacing(4),
      },
    },
    advert: {
      display: 'inline-block',

      marginTop: spacing(5),
      [breakpoints.up('sm')]: {
        marginTop: spacing(8),
      },
      padding: spacing(0, 1),

      ...typography.body.M,
      color: palette.black,
      backgroundColor: palette.ui['attention-10'],
      borderRadius: 5,
    },
  })
);
