import { Navigate, useSearchParams } from 'react-router-dom';

export default function OfferById() {
  const [searchParams] = useSearchParams();
  const offerId = searchParams.get('offerId');
  const quotationId = searchParams.get('key');

  return (
    <Navigate
      to={`/seguros-salud/calculation/results/${offerId}`}
      replace={true}
      state={{ quotationId: quotationId, offerId: offerId }}
    />
  );
}
