import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles({ name: 'Table' })(
  ({ palette, spacing, breakpoints }) => ({
    cell: {},
    row: {
      display: 'grid',
      gridAutoFlow: 'column',
      minWidth: 'fit-content',
      padding: spacing(3, 0),
      gridTemplateColumns: '120px repeat(auto-fit, minmax(140px, 1fr))',

      [breakpoints.up('md')]: {
        gridTemplateColumns: '275px repeat(auto-fit, minmax(140px, 1fr))',
      },

      '& > div:not(:first-child)': {
        minWidth: 140,
      },

      '&:not(:last-child)': {
        borderBottom: '1px solid',
        borderColor: palette.neutral.grey[20],
      },
    },
    body: {},
    head: {
      margin: spacing(-3, 0),
    },
    table: {
      padding: spacing(0, 4),
    },
  })
);
