export enum FieldsEnum {
  EmailAddress = 'emailAddress',
  Hospitalization = 'hospitalization',
  CoPayment = 'coPayment',
  SelfEmployer = 'selfEmployer',
  Refund = 'refund',
  NumberOfPersons = 'numberOfPersons',
  PostalCode = 'postalCode',
  CurrentlyInsured = 'currentlyInsured',
  PreviousInsurerName = 'previousInsurerName',
  SourceOfContact = 'sourceOfContact',
  BirthDate = 'birthDate',
  StartDate = 'startDate',
  PhoneNumber = 'phoneNumber',
  InsuredPersons = 'insuredPersons',
  Products = 'products',
  MarketingConsent = 'consents.marketing.consent',
  RegulationsConsent = 'consents.regulations.consent',
  AcceptAllConsents = 'acceptAllConsents',
  Username = 'username',
  Password = 'password',
}
