import {
  Box,
  Container,
  Grid,
  Theme,
  Typography,
  styled,
  useMediaQuery,
} from '@mui/material';
import AppLogo from 'app/components/AppLogo/AppLogo';
import { Icon } from 'app/components/Icon';
import { InfiniteCarousel } from 'app/components/InfiniteCarousel';
import PPCCalculationForm from 'app/components/PPCCalculationForm/PPCCalculationForm';
import { LabelEnum } from 'app/shared/enums/Label.enum';
import { useTypedDispatch } from 'app/shared/hooks/useTypedDispatch';
import {
  initQuotationAction,
  initQuotationByIdAction,
} from 'app/store/data/quotation/quotationActions';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { ReactComponent as DoctorsMobileSvg } from './doctors-mobile.svg';
import { ReactComponent as DoctorsWebSvg } from './doctors-web.svg';
import { useSendAcquisitionParams } from 'app/shared/hooks/useSendAcquisitionParams';

export default function PPCCalculationView() {
  const dispatch = useTypedDispatch();
  const { state } = useLocation();

  const initQuotation = () => {
    const quotationId = state?.quotationId;

    if (quotationId) {
      dispatch(initQuotationByIdAction({ quotationId }));
    } else {
      dispatch(initQuotationAction());
    }
  };

  useEffect(initQuotation, [dispatch, state?.quotationId]);
  useSendAcquisitionParams();

  return (
    <Box
      sx={({ palette, breakpoints }) => ({
        minHeight: '100vh',
        backgroundColor: palette.white,
        pt: 8,
        [breakpoints.up('sm')]: {
          pt: 10,
        },
      })}
    >
      <Helmet>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <Container
        maxWidth="md"
        sx={({ breakpoints }) => ({
          p: 0,
          [breakpoints.up('sm')]: { p: 0 },
        })}
      >
        <Box sx={{ mb: 4, px: 4 }}>
          <AppLogoStyled />
        </Box>
        <Grid container columnSpacing={12}>
          <Grid
            item
            xs={12}
            desktop={6}
            sx={{ display: 'flex', flexDirection: 'column' }}
          >
            <Typography
              variant="h1"
              sx={({ breakpoints }) => ({
                px: 4,
                [breakpoints.up('desktop')]: { mt: '15%' },
              })}
            >
              {LabelEnum.PPCTitle1}&nbsp;{LabelEnum.PPCTitle2}
            </Typography>
            <Typography
              sx={({ typography, breakpoints }) => ({
                ...typography.body.M,
                mt: 3,
                px: 4,
                [breakpoints.up('desktop')]: { mt: 4 },
              })}
            >
              {LabelEnum.PPCSubtitle}
            </Typography>
            <Box
              sx={({ breakpoints }) => ({
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                position: 'relative',
                pl: 4,
                gap: 4,
                [breakpoints.up('desktop')]: {
                  display: 'none',
                },
              })}
            >
              <Typography
                component="h4"
                sx={({ breakpoints, typography }) => ({
                  ...typography.h4,
                  pt: 4,
                  alignSelf: 'flex-end',
                  mb: 6,
                  [breakpoints.down('sm')]: {
                    fontSize: '26px',
                    lineHeight: '32px',
                  },
                })}
              >
                {LabelEnum.PPCFormTitle}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  '& img': {
                    maxHeight: '120px',
                    display: 'flex',
                  },
                }}
              >
                <DoctorsMobileSvg />
              </Box>
              <Box sx={{ position: 'absolute', bottom: -25, left: 35 }}>
                <Icon name="ArrowDown" />
              </Box>
            </Box>
            <Box
              sx={({ breakpoints }) => ({
                mt: '15%',
                display: 'none',
                [breakpoints.up('desktop')]: {
                  display: 'block',
                },
              })}
            >
              <DoctorsWebSvg />
            </Box>
          </Grid>
          <Grid item xs={12} desktop={6}>
            <Box
              sx={({ palette, breakpoints }) => ({
                backgroundColor: palette['brand-10'],
                borderRadius: 5,
                pt: 7,
                px: 4,
                pb: 6,
                '.MuiFormControl-root .label-wrapper': {
                  justifyContent: 'flex-start',
                  gap: 3,
                  '.tooltip': {
                    top: 0,
                    right: 0,
                    [breakpoints.down('sm')]: {
                      top: 0,
                      right: 0,
                    },
                  },
                },
                [breakpoints.up('desktop')]: {
                  p: 8,
                },
                '.MuiBox-root': {
                  p: 0,
                  backgroundColor: 'transparent',
                },
              })}
            >
              <Box
                sx={({ breakpoints }) => ({
                  mb: 4,
                  position: 'relative',
                  display: 'none',

                  [breakpoints.up('desktop')]: {
                    display: 'block',
                  },
                })}
              >
                <Box
                  sx={{
                    width: 36,
                    height: 36,
                    position: 'absolute',
                    left: '-58px',
                    top: '5px',
                  }}
                >
                  <Icon name="DrawnArrowRight" />
                </Box>
                <Typography
                  component={'h4'}
                  sx={({ breakpoints, typography }) => ({
                    ...typography.h4,
                    [breakpoints.down('sm')]: {
                      fontSize: '26px',
                      lineHeight: '32px',
                    },
                  })}
                >
                  {LabelEnum.PPCFormTitle}
                </Typography>
              </Box>
              <PPCCalculationForm />
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Box
        sx={({ breakpoints }) => ({
          my: 7,
          [breakpoints.up('desktop')]: {
            my: 10,
          },
        })}
      >
        <LogoCarousel />
      </Box>
      <Box
        component={'footer'}
        sx={({ palette }) => ({
          backgroundColor: palette.black,
          py: 6,
          px: 4,
        })}
      >
        <Container
          maxWidth="lg"
          sx={({ breakpoints }) => ({
            display: 'flex',
            flexDirection: 'column',
            gap: 4,

            [breakpoints.up('desktop')]: {
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            },
          })}
        >
          <Box
            sx={({ palette, typography, breakpoints }) => ({
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              a: {
                ...typography.body.S,
                color: palette.neutral.grey[40],
                textDecoration: 'none',
                '&::first-letter': {
                  textTransform: 'capitalize',
                },
              },
              [breakpoints.up('desktop')]: {
                flexDirection: 'row',
                gap: 4,
              },
            })}
          >
            <a href="https://www.arpem.com/aviso-legal/">
              {LabelEnum.LegalNotice}
            </a>
            <a href="https://www.arpem.com/politica-de-privacidad/">
              {LabelEnum.PrivacyPolicy}
            </a>
          </Box>
          <Box
            sx={({ palette, typography }) => ({
              color: palette.neutral.grey[50],
              ...typography.body.S,
            })}
          >
            {LabelEnum.CompanyTrademark}
          </Box>
        </Container>
      </Box>
    </Box>
  );
}

const AppLogoStyled = styled(AppLogo)<Theme>(({ theme: { breakpoints } }) => ({
  '& svg': {
    height: 30,
    width: 112,

    [breakpoints.up('sm')]: {
      height: 30,
      width: 112,
    },
  },
}));

export const companiesImageArray = [
  { id: 0, name: 'mapfre', src: '/images/mapfrePPC.png' },
  { id: 1, name: 'genesis', src: '/images/genesisPPC.png' },
  { id: 2, name: 'direct', src: '/images/directPPC.png' },
  { id: 3, name: 'alianz', src: '/images/alianzPPC.png' },
  { id: 4, name: 'fiatc', src: '/images/fiatcPPC.png' },
  { id: 5, name: 'reale', src: '/images/realePPC.png' },
  { id: 6, name: 'verti', src: '/images/vertiPPC.png' },
  { id: 7, name: 'regal', src: '/images/regalPPC.png' },
];

const LogoCarousel = () => {
  const isDesktop = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.up('desktop')
  );
  return (
    <Box
      sx={({ breakpoints }) => ({
        position: 'relative',
        '&::after': {
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          content: '" "',
          position: 'absolute',
          zIndex: 2,
          [breakpoints.up('desktop')]: {
            background:
              'linear-gradient(to right, rgba(255,255,255,1) 5%,rgba(255,255,255,0) 20%,rgba(255,255,255,0) 80%,rgba(255,255,255,1) 95%)',
          },
        },
      })}
    >
      <Box
        sx={{
          filter: 'grayscale(100%)',
          opacity: 0.4,
        }}
      >
        <InfiniteCarousel itemWidth={isDesktop ? 215 : 140} animationSpeed={80}>
          {companiesImageArray.map((image) => (
            <Box
              sx={({ breakpoints }) => ({
                img: {
                  width: 90,
                  mx: 5,
                  [breakpoints.up('desktop')]: {
                    mx: 8,
                    width: 135,
                  },
                },
              })}
            >
              <img key={image.id} src={image.src} alt={image.name} />
            </Box>
          ))}
        </InfiniteCarousel>
      </Box>
    </Box>
  );
};
