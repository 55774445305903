import { BasicFormRowContainer } from 'app/components/BasicFormRowContainer';
import { ControlContainer } from 'app/components/ControlContainer';
import { FormRow } from 'app/components/FormRow';
import { BusinessFunctionsEnum } from 'app/shared/enums/BusinessFunctions.enum';
import { ControlTypeEnum } from 'app/shared/enums/ControlType.enum';
import { FieldsEnum } from 'app/shared/enums/Fields.enum';
import { LabelEnum } from 'app/shared/enums/Label.enum';
import { useFieldMeta } from 'app/shared/hooks/useFieldMeta';
import { requiredConsent } from 'app/validators/fieldLevelValidators';
import { FormConsentsProps } from './FormConsents.interfaces';
import { useStyles } from './FormConsents.styles';
import { getRegulationsConsentLabel } from 'app/shared/helpers/router';

export default function FormConsents(props: FormConsentsProps) {
  const { classes, cx } = useStyles();

  const regulationsConsentLabel = getRegulationsConsentLabel();
  const regulationsConsentMeta = useFieldMeta(FieldsEnum.RegulationsConsent);
  const marketingConsentMeta = useFieldMeta(FieldsEnum.MarketingConsent);

  const bothVisible =
    !regulationsConsentMeta.initial && !marketingConsentMeta.initial;
  const nonVisible =
    regulationsConsentMeta.initial && marketingConsentMeta.initial;

  if (nonVisible) {
    return null;
  }

  return (
    <div className={classes.root}>
      <ControlContainer
        meta={regulationsConsentMeta}
        label={LabelEnum.ConsentsLabel}
      >
        <div className={cx([classes.consent, 'consent-box'])}>
          {LabelEnum.Consent}
        </div>
        <div className={classes.questions}>
          <FormRow
            name={FieldsEnum.AcceptAllConsents}
            type={ControlTypeEnum.Checkbox}
            label={
              <span className={classes.acceptAllCheckboxLabel}>
                {LabelEnum.ConsentsAcceptAllControlLabel}
              </span>
            }
            renderFormRowContainer={BasicFormRowContainer}
            onChange={[BusinessFunctionsEnum.OnAcceptAllConsentsChange]}
            shouldPatch={false}
            visible={bothVisible}
          />
          <FormRow
            name={FieldsEnum.RegulationsConsent}
            validate={requiredConsent}
            type={ControlTypeEnum.Checkbox}
            label={regulationsConsentLabel}
            renderFormRowContainer={BasicFormRowContainer}
            visible={!regulationsConsentMeta.initial}
            classes={{ label: classes.consentLabel }}
            onChange={[BusinessFunctionsEnum.OnRegulationsConsentChange]}
          />
          <FormRow
            name={FieldsEnum.MarketingConsent}
            type={ControlTypeEnum.Checkbox}
            label={LabelEnum.MarketingConsentControlLabel}
            renderFormRowContainer={BasicFormRowContainer}
            visible={!marketingConsentMeta.initial}
            classes={{ label: classes.consentLabel }}
          />
        </div>
      </ControlContainer>
    </div>
  );
}
