import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(({ palette, typography }) => ({
  label: {
    ...typography.body.XS,
  },
  error: {
    color: palette.ui.error,
  },
}));
