import React from 'react';
import { useStyles } from './SelectEndAdornment.styles';
import { SelectEndAdornmentProps } from './SelectEndAdornment.interfaces';
import { Icon } from '../Icon';

export default function SelectEndAdornment({
  isOpen,
}: SelectEndAdornmentProps) {
  const { classes, cx } = useStyles();

  return (
    <div className={classes.root}>
      <Icon
        name="ChevronDown"
        className={cx(classes.icon, { [classes.open]: isOpen })}
      />
    </div>
  );
}
