import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(
  ({ typography, spacing, palette, breakpoints }) => ({
    root: {
      display: 'flex',
      flexDirection: 'row',
      flex: 1,
    },
    header: {
      flex: 1,
    },
    subheader: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: spacing(2),
      gap: spacing(2),

      [breakpoints.up('md')]: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: spacing(4),
      },
    },

    title: {
      ...typography.h3,
      color: palette.black,
      margin: 0,
      maxWidth: 300,

      [breakpoints.up('md')]: {
        maxWidth: 450,
      },
    },
    description: {
      ...typography.body.S,
      color: palette.neutral.grey[50],
      [breakpoints.up('md')]: {
        color: palette.black,
      },
    },

    anchor: {
      ...typography.body.S,
      fontWeight: 600,
      color: palette.brand,
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      border: 'none',
      backgroundColor: 'inherit',

      '& svg': {
        width: 18,
        height: 18,
        marginRight: spacing(1),
      },

      '& path': {
        fill: palette.brand,
      },
    },

    number: {
      ...typography.body.XS,
      color: palette.neutral.grey[40],
      flexDirection: 'column',
      alignItems: 'flex-end',
      display: 'none',
      [breakpoints.up('md')]: {
        display: 'flex',
      },
    },
  })
);
