import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles({ name: 'Modal' })(
  ({ breakpoints, spacing }) => ({
    modal: {
      '& .MuiPaper-root': {
        borderRadius: 12,
        marginLeft: 0,
        marginRight: 0,
      },
    },
    closeIcon: {
      position: 'absolute',
      top: spacing(2),
      right: spacing(5),
      zIndex: 2,
    },
    paper: {
      [breakpoints.down('md')]: {
        margin: 0,
        width: '100%',
        height: '100%',
        maxHeight: '100%',
      },
    },
  })
);
