import { RootState } from 'app/store';

export const selectQuotation = (state: RootState) => state.quotation;
export const selectQuotationPhoneNumber = (state: RootState) =>
  state.quotation?.phoneNumber;
export const selectQuotationPreviousInsurerName = (state: RootState) =>
  state.quotation?.previousInsurerName;

export const selectQuotationId = (state: RootState) => state.quotation.id || '';

export const selectQuotationStatus = (state: RootState) =>
  state.quotation.status;

export const selectAnonymised = (state: RootState) =>
  state.quotation.anonymised;
