import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { AcquisitionState } from './state.interface';

const initialState: AcquisitionState = {
  isAcquisitionRead: false,
};

export const acquisitionSlice = createSlice({
  name: 'acquisition',
  initialState,
  reducers: {
    setIsAcquisitionRead: (state, action: PayloadAction<boolean>) => {
      state.isAcquisitionRead = action.payload;
    },
  },
});

export const { setIsAcquisitionRead } = acquisitionSlice.actions;
export default acquisitionSlice.reducer;
