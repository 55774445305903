import { useForm } from 'react-final-form';

import { BusinessFunctionsEnum } from 'app/shared/enums/BusinessFunctions.enum';

export function useBusinessFunctions() {
  const { mutators } = useForm();

  const runBusinessFunction = (functionName: BusinessFunctionsEnum) => {
    if (!mutators[functionName]) {
      console.warn(`Unimplemented business function: ${functionName}`);
      return;
    }

    console.info(`Run business function: ${functionName}`);

    mutators[functionName]();
  };

  return { runBusinessFunction };
}
