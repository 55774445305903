import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(({ spacing, breakpoints, palette }) => ({
  root: {
    padding: spacing(8, 0, 2),
    [breakpoints.up('md')]: {
      padding: spacing(8, 8, 3),
      minHeight: '80vh',
    },
  },
  title: {
    color: palette.black,
    padding: spacing(0, 4, 6),

    [breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));
