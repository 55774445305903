import { useState } from 'react';
import { DateTime } from 'luxon';
import { DatePicker } from '@mui/x-date-pickers';
import { TextField } from '@mui/material';

import { DateControlProps } from './DateControl.interfaces';
import { ControlContainer } from 'app/components/ControlContainer';
import { useStyles } from './DateControl.styles';
import { date } from 'app/validators/fieldLevelValidators';

export default function DateControl(props: DateControlProps) {
  const {
    input,
    meta,
    label,
    minDate,
    maxDate,
    onControlBlur,
    onValueChange,
    tooltip,
  } = props;
  const { classes } = useStyles();
  const validator = date({ minDate, maxDate });

  const validate = (value: string) => {
    return !validator(value);
  };

  const [minimumDate, setMinimumDate] = useState<DateTime>();
  const [maximumDate, setMaximumDate] = useState<DateTime>();

  const setMinAndMaxDate = () => {
    if (minDate) {
      setMinimumDate(DateTime.now().plus(minDate));
    }

    if (maxDate) {
      setMaximumDate(DateTime.now().plus(maxDate));
    }
  };

  const handleAccept = (date: DateTime | null) => {
    const value = date?.toISODate() || '';
    const isValid = validate(value);

    if (onValueChange) {
      onValueChange({ value, shouldPatch: isValid });
    }
  };

  const handleChange = (date: DateTime | null) => {
    const value = date?.toISODate() || '';

    input.onChange(value);

    if (onValueChange) {
      onValueChange({ value, shouldPatch: false });
    }
  };

  const handleBlur = () => {
    input.onBlur();

    if (!input.value) {
      return;
    }

    if (onControlBlur) {
      const isValid = validate(input.value);
      onControlBlur({ value: input.value, shouldPatch: isValid });
    }
  };

  return (
    <ControlContainer
      label={label}
      meta={meta}
      name={input.name}
      tooltip={tooltip}
    >
      <DatePicker
        inputFormat={'dd-MM-yyyy'}
        InputAdornmentProps={{ position: 'start' }}
        InputProps={{
          onBlur: handleBlur,
          onFocus: setMinAndMaxDate,
        }}
        minDate={minimumDate}
        maxDate={maximumDate}
        onAccept={handleAccept}
        onChange={handleChange}
        onOpen={setMinAndMaxDate}
        className={classes.root}
        renderInput={(params) => (
          <TextField
            {...params}
            error={!!(meta.touched && meta.error)}
            inputProps={{
              ...params.inputProps,
              placeholder: 'DD-MM-AAAA',
            }}
          />
        )}
        value={input.value}
      />
    </ControlContainer>
  );
}
