export enum InsurerNameEnum {
  'Adeslas' = 'Adeslas',
  'AEGON' = 'AEGON',
  'AsefaSalud' = 'AsefaSalud',
  'Asisa' = 'Asisa',
  'Axa' = 'Axa',
  'Sanitas' = 'Sanitas',
  'Nara' = 'Nara',
  'Fiatc' = 'Fiatc',
  'Nueva' = 'Nueva',
  'DKV' = 'DKV',
  'IMQ' = 'IMQ',
  'Allianz' = 'Allianz',
  'Other' = 'Other',
}
