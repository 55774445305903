import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(({ typography, spacing, palette }) => ({
  root: {
    display: 'flex',
    flex: '1 0 100%',
    alignItems: 'center',
    marginBottom: spacing(4),

    ...typography.body.XS,
    color: palette.ui.error,
  },
  alertIcon: {
    marginRight: spacing(1),
  },
}));
