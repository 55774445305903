import dropRight from 'lodash-es/dropRight';

export const adjustLength = <T>(
  array: T[],
  length: number,
  defaultValue: unknown = undefined
): T[] => {
  const difference = length - array.length;

  return difference > 0
    ? new Array(length)
        .fill(defaultValue)
        .map((value, index) => array[index] || value)
    : dropRight(array, Math.abs(difference));
};
