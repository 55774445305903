import { InputAdornment, TextField } from '@mui/material';
import { LabelEnum } from 'app/shared/enums/Label.enum';
import InputMask from 'react-input-mask';
import { Icon } from '../Icon';
import { PhoneInputProps } from './PhoneInput.interfaces';
import { useStyles } from './PhoneInput.styles';

const defaultMask = '999 999 999';

export default function PhoneInput(props: PhoneInputProps) {
  const { classes } = useStyles();
  const { mask = defaultMask, maskChar = null } = props;
  const value = props.value.replace(LabelEnum.PhonePrefix, '');

  return (
    <InputMask
      mask={mask}
      maskChar={maskChar}
      onBlur={props.onBlur}
      onFocus={props.onFocus}
      onClick={props.onClick}
      onChange={props.onChange}
      value={value}
    >
      {() => (
        <TextField
          type="tel"
          error={props.error}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Icon name="Phone" className={classes.icon} />
                {LabelEnum.PhonePrefix}
              </InputAdornment>
            ),
          }}
        />
      )}
    </InputMask>
  );
}
