import { Box } from '@mui/material';
import { FormRow } from 'app/components/FormRow';
import { BusinessFunctionsEnum } from 'app/shared/enums/BusinessFunctions.enum';
import { ControlTypeEnum } from 'app/shared/enums/ControlType.enum';
import { DictionariesEnum } from 'app/shared/enums/Dictionaries.enum';
import { FieldsEnum } from 'app/shared/enums/Fields.enum';
import { LabelEnum } from 'app/shared/enums/Label.enum';
import { composeValidators } from 'app/shared/helpers/composeValidators';
import {
  email,
  phoneNumber,
  required,
} from 'app/validators/fieldLevelValidators';
import requiredOption from 'app/validators/fieldLevelValidators/requiredOption';
import zipCode from 'app/validators/fieldLevelValidators/zipCode';
import { FormConsents } from '../FormConsents';
import InsurantsAgeFormRow from '../InsurantsAgeFormRow/InsurantsAgeFormRow';
import PPCFormRowContainer from '../PPCFormRowContainer/PPCFormRowContainer';

export default function PPCCalculationFormConfig() {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
      <FormRow
        name={FieldsEnum.NumberOfPersons}
        label={LabelEnum.NumberOfPersonsControlLabel}
        type={ControlTypeEnum.RadioSelect}
        tooltip={LabelEnum.NumberOfPersonsTooltip}
        answersDictionaryKey={DictionariesEnum.NumberOfPersons}
        validate={requiredOption}
        onChange={[BusinessFunctionsEnum.OnNumberOfPersonsChange]}
        renderFormRowContainer={PPCFormRowContainer}
      />
      <InsurantsAgeFormRow
        name={FieldsEnum.InsuredPersons}
        type={ControlTypeEnum.InsurersSelect}
        answersDictionaryKey={DictionariesEnum.InsuredPersons}
        isPPC
      />
      <FormRow
        name={FieldsEnum.PostalCode}
        label={LabelEnum.ZipCode}
        type={ControlTypeEnum.ZipCode}
        tooltip={LabelEnum.ZipCodeTooltip}
        validate={composeValidators(required, zipCode)}
        renderFormRowContainer={PPCFormRowContainer}
      />
      <FormRow
        name={FieldsEnum.EmailAddress}
        validate={composeValidators(required, email)}
        type={ControlTypeEnum.Email}
        label={LabelEnum.EmailControlLabel}
        renderFormRowContainer={PPCFormRowContainer}
      />
      <FormRow
        name={FieldsEnum.PhoneNumber}
        validate={composeValidators(phoneNumber)}
        type={ControlTypeEnum.PhoneNumber}
        label={LabelEnum.PhoneNumberControlLabel}
        renderFormRowContainer={PPCFormRowContainer}
      />
      <Box
        sx={({ typography, palette }) => ({
          '& > div': {
            backgroundColor: 'transparent',
            p: 0,
            '.MuiFormLabel-root': { ...typography.body.M, mb: 3, mr: 0 },
            '.consent-box': {
              border: `2px solid ${palette.neutral.grey[20]}`,
            },
          },
        })}
      >
        <FormConsents />
      </Box>
    </Box>
  );
}
