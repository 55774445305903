import { useField } from 'react-final-form';

import { FieldsEnum } from 'app/shared/enums/Fields.enum';

export const useFieldMeta = (name: FieldsEnum) => {
  const { meta } = useField(name, {
    subscription: { touched: true, error: true, initial: true },
  });

  return meta;
};
