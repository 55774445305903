import { SelectOption } from 'app/components/Select/Select.interfaces';

export const SLICE_INDEX = 3;

export const getInitialValues = (
  initalValue: string
): { radio: string; select: SelectOption | null } => {
  const value = Number(initalValue);

  const radio = value > SLICE_INDEX ? '' : initalValue;
  const select =
    value > SLICE_INDEX ? { value: initalValue, label: initalValue } : null;

  return { radio, select };
};
