import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(
  ({ spacing, palette, typography, breakpoints }) => ({
    root: {
      padding: spacing(4),
      display: 'flex',
      flex: '1 0 100%',
      flexDirection: 'column',
      justifyContent: 'space-between',

      [breakpoints.up('md')]: {
        padding: spacing(8),
      },
    },
    header: {
      display: 'flex',
      flexDirection: 'column',
      gap: spacing(4),
    },
    title: { color: palette.black },
    ad: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: spacing(2),
    },
    adText: {
      color: palette.neutral.grey[50],
      ...typography.body.S,
      flex: 1,
      maxWidth: 280,
    },
    insurer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      gap: spacing(1),

      '& > svg, & > img': {
        width: 'auto',
        height: 'auto',
        maxWidth: 140,
        maxHeight: 65,
      },
    },
    price: {
      ...typography.body.S,
      color: palette.black,

      '& strong': {
        ...typography.h4,
        marginRight: 0,
      },
    },
    priceGroup: {
      textAlign: 'right',
      whiteSpace: 'break-spaces',
      '& strong': {
        marginRight: 0,
      },
    },
    table: {
      marginTop: spacing(6),
    },
    offerDetails: {
      display: 'none',
      flex: '1 0 320px',

      [breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    footer: {
      margin: spacing(3, -4, -4, -4),
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
      padding: spacing(4, 4, 6, 4),
      backgroundColor: palette['brand-10'],
      boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.25)',

      [breakpoints.up('md')]: {
        display: 'none',
      },
    },
  })
);
