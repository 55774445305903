import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(({ spacing, breakpoints }) => ({
  root: {
    marginBottom: spacing(4),

    [breakpoints.up('md')]: {
      marginBottom: spacing(8),
    },
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: spacing(5),

    [breakpoints.up('md')]: {
      marginBottom: spacing(8),
    },
  },
}));
