import React from 'react';

import { IconProps } from './Icon.interfaces';
import { icons } from './Icon.constants';

const Icon = ({ name, ...props }: IconProps) => {
  return React.createElement(icons[name], props);
};

export default Icon;
