import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import remove from 'lodash-es/remove';
import { OfferComparisonState } from './state.interface';

const initialState: OfferComparisonState = {
  comparedOfferIds: [],
};

export const quotationSlice = createSlice({
  name: 'offerComparison',
  initialState,
  reducers: {
    addComparedOfferId: (state, action: PayloadAction<string>) => {
      state.comparedOfferIds.push(action.payload);
    },
    removeComparedOfferId: (state, action: PayloadAction<string>) => {
      remove(state.comparedOfferIds, (id: string) => id === action.payload);
    },
  },
});

export const { addComparedOfferId, removeComparedOfferId } =
  quotationSlice.actions;

export default quotationSlice.reducer;
