import { FormGroup } from '@mui/material';
import React from 'react';

import { ControlContainer } from 'app/components/ControlContainer';
import { FormTooltip } from 'app/components/FormTooltip';
import { RowCheckbox } from 'app/components/RowCheckbox';
import { fieldToIconMap } from 'app/shared/helpers/fieldToIconMap';
import { CheckboxGroupControlProps } from './CheckboxGroupControl.interfaces';
import { useStyles } from './CheckboxGroupControl.styles';

export default function CheckboxGroupControl(props: CheckboxGroupControlProps) {
  const { classes } = useStyles();
  const { dictionary = [], input, onValueChange, meta, label } = props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    input.onBlur(); // to set meta.touched
    const value = { ...input.value, [event.target.name]: event.target.checked };

    input.onChange(value);
    onValueChange?.({ value, shouldPatch: true });
  };

  return (
    <ControlContainer label={label} meta={meta} name={input.name}>
      <FormGroup className={classes.group}>
        {dictionary.map(({ value, key, additionalInfo }) => (
          <div className={classes.row} key={key}>
            <RowCheckbox
              label={value}
              name={String(key)}
              value={String(key)}
              icon={fieldToIconMap[key]}
              onChange={handleChange}
              checked={Boolean(input.value[key])}
            />
            {additionalInfo && (
              <div className={classes.tooltip}>
                <FormTooltip content={additionalInfo} />
              </div>
            )}
          </div>
        ))}
      </FormGroup>
    </ControlContainer>
  );
}
