import userReducer from './userSlice';

export * from './state.interface';

// Selectors
export { selectIsLoggedIn } from './userSelectors';

// Asynchronous actions
export { getAuthAction, loginAction, logoutAction } from './userActions';

// Reducer
export default userReducer;
