import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(
  ({ breakpoints, spacing, typography }) => ({
    labelWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    label: {
      ...typography.body.M,
      fontWeight: 600,
      position: 'relative',
      transform: 'none',
      marginBottom: spacing(4),
      marginRight: spacing(7),
      whiteSpace: 'normal',
    },
    labelError: {
      marginBottom: spacing(2),
    },
    tooltip: {
      position: 'relative',
      top: spacing(-3),
      right: spacing(-3),
      [breakpoints.down('sm')]: {
        top: spacing(-0.5),
        right: spacing(-0.5),
      },
    },
  })
);
