import quotationReducer from './quotationResultsSlice';

export * from './state.interface';
export * from './actionPayload.interface';

// Selectors
export { selectQuotation } from './quotationResultsSelectors';

// Asynchronous actions
export { fetchQuotationResultsAction } from './quotationResultsActions';

// Synchronous actions
export {
  clearQuotationResults,
  setIsQuotationSubmittedByUserAction,
} from './quotationResultsSlice';

// Reducer
export default quotationReducer;
