import { LogoutButton } from 'app/components/LogoutButton';
import { Outlet } from 'react-router-dom';

import { LoadingScreen } from 'app/components/LoadingScreen';
import { useStyles } from './CalculatorBaseView.styles';
import { useCalculatorBaseView } from './useCalculatorBaseView';

export default function CalculatorBaseView() {
  const { classes } = useStyles();

  const { isLoading, isLoggedIn } = useCalculatorBaseView();

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <>
      {isLoggedIn && (
        <div className={classes.logout}>
          <LogoutButton />
        </div>
      )}
      <Outlet />
    </>
  );
}
