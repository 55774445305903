import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(({ palette }) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    backgroundColor: palette.surface,
  },
}));
