let {
  REACT_APP_API_URL,
  REACT_APP_DIDOMI_COOKIES_API_KEY,
  REACT_APP_GTM_ID = '',
  REACT_APP_SENTRY_DSN,
  REACT_APP_GTM_AUTH = undefined,
  REACT_APP_GTM_PREVIEW = undefined,
} = process.env;

export {
  REACT_APP_API_URL,
  REACT_APP_DIDOMI_COOKIES_API_KEY,
  REACT_APP_GTM_ID,
  REACT_APP_SENTRY_DSN,
  REACT_APP_GTM_PREVIEW,
  REACT_APP_GTM_AUTH,
};
