import { useEffect, useRef, useState } from 'react';

import { useMediaQuery } from '@mui/material';
import { Theme } from '@mui/system';
import { ResultsOffer } from 'app/components/ResultsOffer/ResultsOffer.interfaces';
import { LabelEnum } from 'app/shared/enums/Label.enum';
import { QuotationResultStatusEnum } from 'app/shared/enums/QuotationResultStatus.enum';
import { RouteEnum } from 'app/shared/enums/Route.enum';
import { getAbsoluteResultsContactPath } from 'app/shared/helpers/router';
import {
  GTMElement,
  GTMEvent,
  GTMSection,
  useGTM,
} from 'app/shared/hooks/useGTM';
import { useTypedDispatch } from 'app/shared/hooks/useTypedDispatch';
import { useTypedSelector } from 'app/shared/hooks/useTypedSelector';
import { QuotationResultWithOffersInterface } from 'app/shared/interfaces/QuotationResultWithOffers.interface';
import {
  addComparedOfferId,
  removeComparedOfferId,
  selectComparedOfferIds,
} from 'app/store/data/offerComparison';
import {
  fetchQuotationCopyAction,
  selectQuotation,
  submitQuotationAction,
} from 'app/store/data/quotation';
import { selectQuotationPreviousInsurerName } from 'app/store/data/quotation/quotationSelectors';
import {
  clearQuotationResults,
  fetchQuotationResultsAction,
} from 'app/store/data/quotationResults';
import {
  selectOffers,
  selectQuotationResultsLoading,
} from 'app/store/data/quotationResults/quotationResultsSelectors';
import { setIsQuotationModified } from 'app/store/data/ui';
import { DateTime } from 'luxon';
import { batch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { filters, recommendations } from './ResultsView.constants';
import { HeaderInfo, SelectedRecommendation } from './ResultsView.interfaces';
import { getFilteredOffers, getInitialFiltersMap } from './ResultsView.utils';

export function useResultsView() {
  const { addDataLayer, addResultsEvent } = useGTM();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useTypedDispatch();
  const offers = useOffers();
  const filters = useFilters();
  useRecoveredProductId(offers);
  const filteredOffers = getFilteredOffers(offers, filters.selectedFilters).map(
    (offer, index): ResultsOffer => ({
      ...offer,
      type: index === 0 ? 'cheapest' : 'regular',
    })
  );

  const headerInfo = useHeaderInfo(offers);
  const { compareOfferIds, toggleOfferComparison } = useOfferComparison(offers);
  const isLoadingResults = useTypedSelector(selectQuotationResultsLoading);
  const isMobile = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('md')
  );

  const handleRequestForCopySuccess = () => {
    batch(() => {
      dispatch(clearQuotationResults());
      dispatch(setIsQuotationModified(true));
    });
  };

  const changeCalculation = async () => {
    try {
      addResultsEvent(GTMEvent.ModifyData, {
        element: { type: GTMElement.Link, text: LabelEnum.ChangeData },
        section: GTMSection.Header,
      });
      await dispatch(fetchQuotationCopyAction());

      handleRequestForCopySuccess();
    } catch (error) {}
  };

  const showComparisonModal = () => {
    navigate(RouteEnum.OFFER_COMPARISON, {
      state: { background: location },
    });
  };

  const handleOfferCompareLabelClick = (id: string) => {
    if (compareOfferIds.includes(id)) {
      const offer = offers.find((offer) => offer.id === id);
      addResultsEvent(GTMEvent.CompareView, {
        element: {
          type: GTMElement.Link,
          text: LabelEnum.ShowComparison,
        },
        section: GTMSection.Offer,
        offer,
      });
      showComparisonModal();
    } else {
      toggleOfferComparison(id);
    }
  };

  useEffect(() => {
    dispatch(fetchQuotationResultsAction()).then((data) => {
      const results = (data.payload ||
        []) as QuotationResultWithOffersInterface[];
      const statuses = results.map(({ status }) => status);
      const isExpired = statuses.some(
        (status) => status === QuotationResultStatusEnum.Expired
      );

      if (isExpired) {
        dispatch(submitQuotationAction({})).then((res) => {
          if (res.meta.requestStatus === 'rejected') {
            dispatch(fetchQuotationCopyAction()).then(() => {
              dispatch(clearQuotationResults());
              dispatch(setIsQuotationModified(true));
            });
          } else {
            dispatch(fetchQuotationResultsAction());
          }
        });
      }
    });
  }, [dispatch]);

  useEffect(() => {
    addDataLayer(GTMEvent.ResultsView);
  }, [addDataLayer]);

  const onFooterPhoneNumberClick = () => {
    addResultsEvent(GTMEvent.ClickToCall, {
      element: {
        type: GTMElement.Link,
        text: LabelEnum.ContactNumber,
      },
      section: GTMSection.Footer,
    });
  };

  return {
    isMobile,
    headerInfo,
    ...filters,
    compareOfferIds,
    isLoadingResults,
    offers: filteredOffers,
    changeCalculation,
    toggleOfferComparison,
    onFooterPhoneNumberClick,
    handleOfferCompareLabelClick,
  };
}

const useHeaderInfo = (offers: ResultsOffer[]): HeaderInfo => {
  const offersTotal = Object.keys(offers).length;
  const title = offersTotal ? `${offersTotal} ${LabelEnum.FoundOffers}` : '';
  const { numberOfPersons, number, startDate } =
    useTypedSelector(selectQuotation);
  const persons = `${numberOfPersons} ${
    numberOfPersons === 1 ? LabelEnum.Person : LabelEnum.Persons
  }`;
  const starts = startDate
    ? `${LabelEnum.Effect} ${DateTime.fromISO(startDate).toLocaleString({
        month: 'long',
        day: 'numeric',
      })}`
    : '';
  const description = `${persons}, ${starts}`;
  const offerNumber = number || '';

  return { title, description, offerNumber };
};

export const useRecommendationRadio = () => {
  const [selectedRecommendation, setSelectedRecommendation] =
    useState<SelectedRecommendation>(undefined);

  const handleRecommendationSelect = (
    recommendation: SelectedRecommendation
  ) => {
    setSelectedRecommendation(recommendation);
  };

  return {
    recommendations,
    selectedRecommendation,
    handleRecommendationSelect,
  };
};

export const useOffers = (): ResultsOffer[] => {
  const offers = useTypedSelector(selectOffers);
  const previousInsurerName = useSelector(selectQuotationPreviousInsurerName);
  const isOfferFromPreviousInsurer = (offer: ResultsOffer) =>
    offer.insurerName?.toLowerCase() === previousInsurerName?.toLowerCase();

  return Object.values(offers)
    .filter((offer) => !offer.doNotShow && !isOfferFromPreviousInsurer(offer))
    .sort((a, b) => a.price.finalPrice - b.price.finalPrice);
};

export const useFilters = () => {
  const quotation = useSelector(selectQuotation);
  const filtersMap = getInitialFiltersMap(quotation);
  const [selectedFilters, setSelectedFilters] = useState(filtersMap);

  return { selectedFilters, setSelectedFilters, filters };
};

export const useOfferComparison = (offers: ResultsOffer[]) => {
  const dispatch = useTypedDispatch();
  const compareOfferIds = useTypedSelector(selectComparedOfferIds);
  const { addResultsEvent } = useGTM();

  const toggleOfferComparison = (id: string) => {
    const isAlreadyAdded = compareOfferIds.includes(id);
    const offer = offers.find((offer) => offer.id === id);

    addResultsEvent(
      isAlreadyAdded ? GTMEvent.RemoveFromCompare : GTMEvent.AddToCompare,
      {
        element: {
          type: GTMElement.Checkbox,
          text: 'Compara seguro',
        },
        section: GTMSection.Offer,
        offer,
      }
    );

    if (isAlreadyAdded) {
      dispatch(removeComparedOfferId(id));
    } else {
      dispatch(addComparedOfferId(id));
    }
  };

  return { compareOfferIds, toggleOfferComparison };
};

const useRecoveredProductId = (offers: ResultsOffer[]) => {
  const location = useLocation();
  const navigate = useNavigate();
  const wasOpenedRef = useRef<boolean>(false);
  const productId: string | undefined = location?.state?.productId;
  const offer = offers.find((o) => o.productId === productId);
  const path = offer && getAbsoluteResultsContactPath(offer.id);

  useEffect(() => {
    if (!wasOpenedRef.current && path) {
      wasOpenedRef.current = true;
      window.history.replaceState({}, document.title);
      navigate(path, { state: { background: location } });
    }
  }, [location, navigate, path]);
};
