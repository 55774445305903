import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(({ spacing, typography }) => ({
  root: {
    display: 'flex',
    flexFlow: 'row wrap',
    gap: spacing(3),
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: spacing(1),
  },
  label: {
    ...typography.body.S,
  },
}));
