import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(
  ({ typography, spacing, palette, breakpoints }) => ({
    root: {
      margin: -8,
    },
    popover: {
      ...typography.body.S,
      maxWidth: 350,
      padding: spacing(4),
      backgroundColor: palette.ui['info-10'],
      border: '2px solid',
      borderColor: palette.ui.info,
      borderRadius: 12,
      color: palette.neutral.grey[50],
      zIndex: 2,
    },
    button: {
      '& svg': {
        width: 25,
        height: 25,
      },
    },
  })
);
