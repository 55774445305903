import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(({ palette }) => ({
  root: {
    width: 30,
    height: 30,
    borderRadius: '100%',
    display: 'grid',
    placeContent: 'center',
    backgroundColor: palette.neutral.grey[10],

    cursor: 'pointer',

    '& svg': {
      width: 20,
      height: 20,
    },

    '& path': {
      fill: palette.neutral.grey[40],
    },
  },
}));
