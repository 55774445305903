import quotationReducer from './quotationSlice';

export * from './state.interface';
export * from './actionPayload.interface';

// Selectors
export {
  selectQuotation,
  selectQuotationId,
  selectQuotationStatus,
} from './quotationSelectors';

// Asynchronous actions
export {
  createNewQuotationAction,
  initQuotationAction,
  fetchQuotationCopyAction,
  submitQuotationAction,
} from './quotationActions';

// Synchronous actions
export { setQuotationQuestionAction } from './quotationSlice';

// Reducer
export default quotationReducer;
