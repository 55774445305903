import { FormGroup, RadioGroup, Typography } from '@mui/material';
import {
  GTMElement,
  GTMEvent,
  GTMSection,
  useGTM,
} from 'app/shared/hooks/useGTM';
import { filterEnumToProductLabelMap } from 'app/views/ResultsView/ResultsView.constants';
import React from 'react';
import { Checkbox } from '../Checkbox';
import { Radio } from '../Radio';
import { FilterGroupProps } from './FilterGroup.interfaces';
import { useStyles } from './FilterGroup.styles';

export default function FilterGroup(props: FilterGroupProps) {
  const { onSelectedOptionsChange, options, selectedOptions, label, type } =
    props;
  const { classes, cx } = useStyles();
  const { addResultsEvent } = useGTM();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const resultsEvent = event.target.checked
      ? GTMEvent.FilterCheck
      : GTMEvent.FilterUncheck;

    addResultsEvent(resultsEvent, {
      element: {
        type: GTMElement.Checkbox,
        text: filterEnumToProductLabelMap[event.target.name],
      },
      section: GTMSection.Filters,
    });
    onSelectedOptionsChange({
      ...selectedOptions,
      [event.target.name]: event.target.checked,
    });
  };

  const handleRadioChange = (event: React.MouseEvent<HTMLButtonElement>) => {
    const value = (event.target as HTMLInputElement).value;

    onSelectedOptionsChange({
      ...selectedOptions,
      ...Object.fromEntries(options.map((o) => [o, false])),
      [value]: !selectedOptions[value],
    });
  };

  const selectedRadioValue = options.find((o) => selectedOptions[o]) || null;

  return (
    <div className={classes.root}>
      <Typography variant="h6">{label}</Typography>
      {type === 'RADIO' && (
        <RadioGroup
          className={cx(classes.optionsContainer, props.classes?.content)}
          name={label}
          value={selectedRadioValue}
        >
          {options.map((option) => (
            <div className={classes.optionRadio} key={option}>
              <Radio
                value={option}
                label={filterEnumToProductLabelMap[option]}
                onClick={handleRadioChange}
              />
            </div>
          ))}
        </RadioGroup>
      )}
      {type === 'CHECKBOX' && (
        <FormGroup
          className={cx(classes.optionsContainer, props.classes?.content)}
        >
          {options.map((option) => (
            <div className={classes.optionCheckbox} key={option}>
              <Checkbox
                value={option}
                onChange={handleChange}
                checked={selectedOptions[option]}
                label={filterEnumToProductLabelMap[option]}
              />
            </div>
          ))}
        </FormGroup>
      )}
    </div>
  );
}
