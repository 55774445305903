export enum OfferDetails {
  GeneralMedicine = 'generalMedicine',
  PediatricsAndChildcare = 'pediatricsAndChildcare',
  MedicalOrSurgicalHospitalization = 'medicalOrSurgicalHospitalization',
  EmergencyService = 'emergencyService',
  Specialties = 'specialties',
  RehabilitationAndPhysiotherapy = 'rehabilitationAndPhysiotherapy',
  DentalCoverage = 'dentalCoverage',
  Copay = 'copay',
  Lack = 'lack',
}
