export enum RouteEnum {
  ROOT_VIEW = '/seguros-salud',
  CALCULATOR_VIEW = 'calculation',
  CALCULATION_RESULTS = 'calculation/results',
  CALCULATION_FORM = 'form',
  PPC_VIEW = 'seguros-salud-ppc',
  LOGIN = 'login',
  QUOTATION_BY_ID = 'quotation-by-id/:quotationId',
  OFFER_BY_ID = 'offer-by-id',
  OFFER_ID = ':offerId',
  QUOTATION_BY_RECOVERY_KEY = 'quotations',
  RESULTS_VIEW = 'results',
  OFFER_DETAILS_CONTACT = 'contact',
  OFFER_DETAILS = '/seguros-salud/calculation/results/:offerId',
  OFFER_COMPARISON = '/seguros-salud/calculation/results/compare',
  RESULTS_VIEW_CONTACT = '/seguros-salud/calculation/results/contact/:offerId',
  LEGAL_NOTICE = 'https://www.arpem.com/aviso-legal/',
  PRIVACY_POLICY = 'https://www.arpem.com/politica-de-privacidad/',
}
