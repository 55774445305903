import { Box } from '@mui/material';
import { LabelEnum } from 'app/shared/enums/Label.enum';
import { AppLogoProps } from './AppLogo.interfaces';
import { ReactComponent as Logo } from './Logo.svg';

export default function AppLogo(props: AppLogoProps) {
  return (
    <Box
      {...props}
      component={'a'}
      href={LabelEnum.LandingPage}
      sx={({ breakpoints }) => ({
        '& svg': {
          height: 25,
          width: 96,

          [breakpoints.up('sm')]: {
            height: 40,
            width: 154,
          },
        },
      })}
    >
      <Logo />
    </Box>
  );
}
