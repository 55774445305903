import { FieldsEnum } from 'app/shared/enums/Fields.enum';
import { LabelEnum } from 'app/shared/enums/Label.enum';
import { required } from 'app/validators/fieldLevelValidators';
import get from 'lodash-es/get';
import { Field, useFormState } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { InsurantAgeControl } from '../Controls/InsurantAgeControl';
import { useFormRow } from '../FormRow/useFormRow';
import { FormTooltip } from '../FormTooltip';
import { RowMessages } from '../RowMessages';
import { InsurantsAgeFormRowProps } from './InsurantsAgeFormRow.interfaces';
import { useStyles } from './InsurantsAgeFormRow.styles';
import { Box } from '@mui/material';

export default function InsurantsAgeFormRow(props: InsurantsAgeFormRowProps) {
  const { classes } = useStyles();
  const formState = useFormState();
  const numberOfPersons = Number(
    get(formState.values, FieldsEnum.NumberOfPersons) || 0
  );

  const { name, tooltip, visible = true, messages } = props;
  const {
    dictionary = [],
    handleControlBlur,
    handleControlValueChange,
  } = useFormRow(props);

  const options = dictionary.map(({ value }) => ({ value, label: value }));

  if (!visible || numberOfPersons === 0) {
    return null;
  }

  const handleValueChange = (
    value: string,
    index: number,
    values: string[]
  ) => {
    const newValues = values.map((fieldValue, idx) =>
      idx === index ? { age: value } : fieldValue
    );

    handleControlValueChange({ value: newValues, shouldPatch: true });
  };

  const handleInputBlur = (value: string, index: number, values: string[]) => {
    const newValues = values.map((fieldValue, idx) =>
      idx === index ? { age: value } : fieldValue
    );

    handleControlBlur({ value: newValues, shouldPatch: false });
  };

  return (
    <Box className={classes.root}>
      <FieldArray name={name}>
        {({ fields }) => (
          <fieldset className={classes.fields}>
            {fields.map((field, index) => {
              const fieldName = `${field}.age`;

              return (
                <Field
                  name={fieldName}
                  options={options}
                  validate={required}
                  key={index}
                  render={({ input, meta }) => {
                    return (
                      <InsurantAgeControl
                        input={input}
                        meta={meta}
                        label={`${LabelEnum.Insurant} ${index + 1}`}
                        subLabel=""
                        options={options}
                        onChange={(value) => {
                          handleValueChange(value, index, fields.value);
                        }}
                        onBlur={(value) => {
                          handleInputBlur(value, index, fields.value);
                        }}
                      />
                    );
                  }}
                />
              );
            })}
          </fieldset>
        )}
      </FieldArray>
      <RowMessages messages={messages} />
      {tooltip && (
        <div className={classes.tooltip}>
          <FormTooltip content={tooltip} />
        </div>
      )}
    </Box>
  );
}
