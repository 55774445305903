import isNil from 'lodash-es/isNil';
import { useEffect, useState } from 'react';

import { DictService, Dictionary } from 'app/services/dict.service';
import { dictionaries } from 'app/shared/dictionaries';
import { getFormControl } from 'app/shared/helpers/getFormControl';
import { useTypedDispatch } from 'app/shared/hooks/useTypedDispatch';
import { RequestParamsInterface } from 'app/shared/interfaces/RequestParams.interface';
import { JsonPatchValueType } from 'app/shared/types/JsonPatchValue.type';
import { updateQuotationDebouncedAction } from 'app/store/data/quotation/quotationActions';
import { selectAnonymised } from 'app/store/data/quotation/quotationSelectors';
import { useSelector } from 'react-redux';
import { FormRowProps } from './FormRow.interfaces';
import { useBusinessFunctions } from './useBusinessFunctions';
import { GTMEvent, useGTM } from 'app/shared/hooks/useGTM';
import { anonymize, isQuestionPII } from 'app/shared/helpers/anonymisation';

export function useFormRow(props: FormRowProps) {
  const {
    answersDictionaryKey,
    onChange,
    name,
    shouldPatch,
    type,
    validate: validators,
  } = props;
  const [dictionary, setDictionary] = useState<Dictionary>();
  const dispatch = useTypedDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const { runBusinessFunction } = useBusinessFunctions();
  const anonymised = useSelector(selectAnonymised);
  const isAnonymised = !!anonymised[name];
  const validate = isAnonymised ? undefined : validators;
  const { addDataLayer } = useGTM();

  const component = getFormControl[type];

  const handleControlBlur = ({
    value,
    shouldPatch = true,
  }: {
    value: JsonPatchValueType;
    shouldPatch?: boolean;
  }) => {
    if (shouldPatch) {
      patchQuotation(value);
    }
  };

  const handleControlValueChange = ({
    shouldPatch = true,
    value,
  }: {
    shouldPatch: boolean;
    value: JsonPatchValueType;
  }) => {
    if (shouldPatch) {
      patchQuotation(value);
    }

    if (onChange) {
      onChange.forEach((functionName) => runBusinessFunction(functionName));
    }
  };

  const initAnswers = () => {
    const hasAnswers = !isNil(answersDictionaryKey);
    const localDictionary = hasAnswers && dictionaries[answersDictionaryKey];

    if (!hasAnswers) {
      setIsLoading(false);
    } else if (localDictionary) {
      setIsLoading(false);
      setDictionary(localDictionary);
    } else {
      fetchAnswers(answersDictionaryKey);
    }
  };

  const fetchAnswers = async (
    dictionaryKey: string,
    params?: RequestParamsInterface
  ) => {
    setIsLoading(true);

    try {
      const { data } = await DictService.getDictionary(dictionaryKey, params);

      setIsLoading(false);
      setDictionary(data);
    } catch (error) {}
  };

  const patchQuotation = (value: JsonPatchValueType) => {
    if (shouldPatch === false || value === undefined) {
      return;
    }
    dispatch(updateQuotationDebouncedAction({ name, value }));

    const safeFieldValue =
      value && isQuestionPII(name) ? anonymize(String(value)) : value;
    addDataLayer(GTMEvent.FieldInput, {
      field_name: name,
      field_value: safeFieldValue,
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(initAnswers, [answersDictionaryKey]);

  return {
    component,
    dictionary,
    handleControlBlur,
    handleControlValueChange,
    isLoading,
    validate,
  };
}
