import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

import { FormControlErrorText } from 'app/components/FormControlErrorText';
import { FormTooltip } from 'app/components/FormTooltip';
import { ControlContainerProps } from './ControlContainer.interfaces';
import { useStyles } from './ControlContainer.styles';

export default function ControlContainer(props: ControlContainerProps) {
  const { children, label, meta, name, tooltip } = props;

  const { classes, cx } = useStyles();

  const error = Boolean(meta.touched && (meta.error || meta.submitError));

  const errorText = meta.error || meta.submitError;

  return (
    <FormControl
      variant={'standard'}
      error={error}
      fullWidth
      data-field-key={name}
      data-field-error={Boolean(meta.error || meta.submitError)}
    >
      <div className={cx(classes.labelWrapper, 'label-wrapper')}>
        {label && (
          <InputLabel
            className={cx(classes.label, {
              [classes.labelError]: error,
            })}
            htmlFor={name}
            shrink
            color="primary"
          >
            {label}
          </InputLabel>
        )}
        {tooltip && (
          <div className={cx(classes.tooltip, 'tooltip')}>
            <FormTooltip content={tooltip} />
          </div>
        )}
      </div>
      {error && <FormControlErrorText>{errorText}</FormControlErrorText>}
      {children}
    </FormControl>
  );
}
