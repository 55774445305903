import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(({ spacing, breakpoints }) => ({
  root: {},
  group: {
    display: 'grid',
    gap: spacing(2),
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  tooltip: {
    position: 'relative',
    left: spacing(2),
    [breakpoints.up('sm')]: {
      left: spacing(3),
    },
  },
}));
