import { InsurerNameEnum } from '../enums/Insurers.enum';
import { QuotationResultStatusEnum } from '../enums/QuotationResultStatus.enum';
import { QuotationResultWithOffersInterface } from '../interfaces/QuotationResultWithOffers.interface';

export const quotationResults: QuotationResultWithOffersInterface[] = [
  {
    blockedDateInDays: 0,
    duration: {
      policyDuration: '2022-11-20',
      policyEndDate: '2022-11-20',
      policyStartDate: '2022-11-20',
    },
    id: '59bdb13d-e8f2-4b57-9030-d26fd7fdb6f8',
    insurer: {
      id: InsurerNameEnum.Adeslas,
      name: InsurerNameEnum.Adeslas,
    },
    offers: [
      {
        id: '1',
        number: '1',
        resultId: '1',
        insurerOfferId: '1',
        productIdForAssist: '1',
        insurerName: InsurerNameEnum.Fiatc,
        createDate: '',
        expirationDate: '',
        price: {
          equalInstallments: true,
          finalPrice: 40,
          installments: [],
        },
        pricePerPerson: {
          finalPrice: 20,
          equalInstallments: true,
          installments: [],
        },
        price2installments: null,
        price3installments: null,
        price4installments: null,
        price6installments: null,
        price12installments: null,
        paymentTypes: null,
        priceHasChanged: false,
        productId: '1',
        importantDocuments: [],
        offerFinalizationType: '',
        offerUrl: '',
        filter: {
          coPayment: true,
          hospitalization: true,
          refund: false,
          selfEmployer: false,
        },
        additional: {
          owuUrl: '',
          productCardUrl: '',
          regulationUrl: '',
        },
        generalMedicine: false,
        pediatricsAndChildcare: false,
        medicalOrSurgicalHospitalization: false,
        emergencyService: false,
        specialties: false,
        rehabilitationAndPhysiotherapy: false,
        dentalCoverage: false,
        copay: false,
        lack: false,
        doNotShow: false,
      },
      {
        id: '2',
        number: '2',
        resultId: '2',
        insurerOfferId: '2',
        productIdForAssist: '2',
        insurerName: InsurerNameEnum.Asisa,
        createDate: '',
        expirationDate: '',
        price: {
          equalInstallments: true,
          finalPrice: 46,
          installments: [],
        },
        price2installments: null,
        price3installments: null,
        price4installments: null,
        price6installments: null,
        price12installments: null,
        paymentTypes: null,
        priceHasChanged: false,
        productId: '2',
        importantDocuments: [],
        offerFinalizationType: '',
        offerUrl: '',
        filter: {
          coPayment: false,
          hospitalization: true,
          refund: false,
          selfEmployer: true,
        },
        additional: {
          owuUrl: '',
          productCardUrl: '',
          regulationUrl: '',
        },
        generalMedicine: true,
        pediatricsAndChildcare: false,
        medicalOrSurgicalHospitalization: true,
        emergencyService: false,
        specialties: false,
        rehabilitationAndPhysiotherapy: true,
        dentalCoverage: false,
        copay: false,
        lack: true,
        doNotShow: false,
        pricePerPerson: null,
      },
      {
        id: '3',
        number: '3',
        resultId: '3',
        insurerOfferId: '3',
        productIdForAssist: '3',
        insurerName: InsurerNameEnum.Adeslas,
        createDate: '',
        expirationDate: '',
        price: {
          equalInstallments: true,
          finalPrice: 52,
          installments: [],
        },
        price2installments: null,
        price3installments: null,
        price4installments: null,
        price6installments: null,
        price12installments: null,
        paymentTypes: null,
        priceHasChanged: false,
        productId: '3',
        importantDocuments: [],
        offerFinalizationType: '',
        offerUrl: '',
        filter: {
          coPayment: false,
          hospitalization: false,
          refund: false,
          selfEmployer: false,
        },
        additional: {
          owuUrl: '',
          productCardUrl: '',
          regulationUrl: '',
        },
        generalMedicine: true,
        pediatricsAndChildcare: true,
        medicalOrSurgicalHospitalization: false,
        emergencyService: false,
        specialties: false,
        rehabilitationAndPhysiotherapy: false,
        dentalCoverage: false,
        copay: false,
        lack: false,
        doNotShow: false,
        pricePerPerson: null,
      },
      {
        id: '4',
        number: '4',
        resultId: '4',
        insurerOfferId: '4',
        productIdForAssist: '4',
        insurerName: InsurerNameEnum.Nueva,
        createDate: '',
        expirationDate: '',
        price: {
          equalInstallments: true,
          finalPrice: 52,
          installments: [],
        },
        price2installments: null,
        price3installments: null,
        price4installments: null,
        price6installments: null,
        price12installments: null,
        paymentTypes: null,
        priceHasChanged: false,
        productId: '4',
        importantDocuments: [],
        offerFinalizationType: '',
        offerUrl: '',
        filter: {
          coPayment: false,
          hospitalization: false,
          refund: false,
          selfEmployer: false,
        },
        additional: {
          owuUrl: '',
          productCardUrl: '',
          regulationUrl: '',
        },
        generalMedicine: true,
        pediatricsAndChildcare: true,
        medicalOrSurgicalHospitalization: false,
        emergencyService: false,
        specialties: false,
        rehabilitationAndPhysiotherapy: false,
        dentalCoverage: false,
        copay: false,
        lack: false,
        doNotShow: true,
        pricePerPerson: null,
      },
      {
        id: '5',
        number: '5',
        resultId: '5',
        insurerOfferId: '5',
        productIdForAssist: '5',
        insurerName: InsurerNameEnum.Sanitas,
        createDate: '',
        expirationDate: '',
        price: {
          equalInstallments: true,
          finalPrice: 52,
          installments: [],
        },
        price2installments: null,
        price3installments: null,
        price4installments: null,
        price6installments: null,
        price12installments: null,
        paymentTypes: null,
        priceHasChanged: false,
        productId: '3',
        importantDocuments: [],
        offerFinalizationType: '',
        offerUrl: '',
        filter: {
          coPayment: false,
          hospitalization: false,
          refund: false,
          selfEmployer: false,
        },
        additional: {
          owuUrl: '',
          productCardUrl: '',
          regulationUrl: '',
        },
        generalMedicine: true,
        pediatricsAndChildcare: true,
        medicalOrSurgicalHospitalization: false,
        emergencyService: false,
        specialties: false,
        rehabilitationAndPhysiotherapy: false,
        dentalCoverage: false,
        copay: false,
        lack: false,
        doNotShow: false,
        pricePerPerson: null,
      },
      {
        id: '6',
        number: '6',
        resultId: '6',
        insurerOfferId: '6',
        productIdForAssist: '6',
        insurerName: InsurerNameEnum.DKV,
        createDate: '',
        expirationDate: '',
        price: {
          equalInstallments: true,
          finalPrice: 40,
          installments: [],
        },
        pricePerPerson: {
          finalPrice: 20,
          equalInstallments: true,
          installments: [],
        },
        price2installments: null,
        price3installments: null,
        price4installments: null,
        price6installments: null,
        price12installments: null,
        paymentTypes: null,
        priceHasChanged: false,
        productId: '1',
        importantDocuments: [],
        offerFinalizationType: '',
        offerUrl: '',
        filter: {
          coPayment: true,
          hospitalization: true,
          refund: false,
          selfEmployer: false,
        },
        additional: {
          owuUrl: '',
          productCardUrl: '',
          regulationUrl: '',
        },
        generalMedicine: false,
        pediatricsAndChildcare: false,
        medicalOrSurgicalHospitalization: false,
        emergencyService: false,
        specialties: false,
        rehabilitationAndPhysiotherapy: false,
        dentalCoverage: false,
        copay: false,
        lack: false,
        doNotShow: false,
      }
    ],
    quotationId: '94d99451-4ce0-4042-ac5e-dc730c30d71a',
    quotationNumber: '94d99451-4ce0-4042-ac5e-dc730c30d71a',
    status: QuotationResultStatusEnum.Prepared,
    validationErrors: [],
  },
];
