import { DateTime } from 'luxon';

import { LabelEnum } from 'app/shared/enums/Label.enum';
import { DurationInterface } from 'app/shared/interfaces/Duration.interface';

const date =
  (datesRange?: { minDate?: DurationInterface; maxDate?: DurationInterface }) =>
  (value: string) => {
    const minDate = datesRange?.minDate;
    const maxDate = datesRange?.maxDate;

    const luxonDate = DateTime.fromISO(String(value));

    let isValid = luxonDate.isValid;

    if (isValid) {
      if (minDate && luxonDate < DateTime.now().plus(minDate).startOf('day')) {
        isValid = false;
      }

      if (maxDate && luxonDate > DateTime.now().plus(maxDate).endOf('day')) {
        isValid = false;
      }
    }

    return isValid ? undefined : LabelEnum.DateRequired;
  };

export default date;
