import { RecommendationRadioOption } from 'app/components/ResultsRecommendationRadio/ResultsRecommendationRadio.interfaces';
import { FiltersEnum } from 'app/shared/enums/Filters.enum';
import { LabelEnum } from 'app/shared/enums/Label.enum';
import { OfferFiltersInterface } from 'app/shared/interfaces/OfferFilters.interface';

// temporary
export const recommendations: RecommendationRadioOption[] = [
  { label: 'Cheapest', tag: 'cheapest', value: '350 €', color: 'brand' },
  {
    label: 'Best quality',
    tag: 'quality',
    value: '550 €',
    color: 'attention',
  },
  { label: 'Calidad Precio', tag: 'overall', value: '450 €', color: 'info' },
];

export const offerProductsOrder: (keyof OfferFiltersInterface)[] = [
  'hospitalization',
  'selfEmployer',
  'coPayment',
  'refund',
];

export const offerFilterKeyToProductLabelMap: Record<
  keyof OfferFiltersInterface,
  string
> = {
  coPayment: LabelEnum.CoPayment,
  hospitalization: LabelEnum.Hospitalization,
  refund: LabelEnum.Refund,
  selfEmployer: LabelEnum.SelfEmployed,
};

export const filterEnumToProductLabelMap: Record<FiltersEnum, string> = {
  [FiltersEnum.WithCoPayment]: LabelEnum.WithCoPayment,
  [FiltersEnum.NoCoPayment]: LabelEnum.NoCoPayment,
  [FiltersEnum.WithHospitalization]: LabelEnum.WithHospitalization,
  [FiltersEnum.NoHospitalization]: LabelEnum.NoHospitalization,
  [FiltersEnum.Refund]: LabelEnum.Refund,
  [FiltersEnum.SelfEmployed]: LabelEnum.SelfEmployed,
};

export const filters: {
  group: string;
  type: 'RADIO' | 'CHECKBOX';
  filters: FiltersEnum[];
}[] = [
  {
    group: LabelEnum.Hospitalization,
    type: 'CHECKBOX',
    filters: [FiltersEnum.WithHospitalization, FiltersEnum.NoHospitalization],
  },
  {
    group: LabelEnum.CoPayment,
    type: 'CHECKBOX',
    filters: [FiltersEnum.WithCoPayment, FiltersEnum.NoCoPayment],
  },
  {
    group: LabelEnum.Other,
    type: 'CHECKBOX',
    filters: [FiltersEnum.SelfEmployed, FiltersEnum.Refund],
  },
];
