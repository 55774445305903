import { ResultsOffer } from 'app/components/ResultsOffer/ResultsOffer.interfaces';
import { FiltersEnum } from 'app/shared/enums/Filters.enum';
import { LabelEnum } from 'app/shared/enums/Label.enum';
import { OfferFiltersInterface } from 'app/shared/interfaces/OfferFilters.interface';
import { QuotationState } from 'app/store/data/quotation';
import { filters } from './ResultsView.constants';
import { FiltersMap } from './ResultsView.interfaces';

export const getFilteredOffers = (
  offers: ResultsOffer[],
  selectedFilters: FiltersMap
) => {
  return offers.filter(({ filter }) => {
    return (Object.keys(selectedFilters) as FiltersEnum[]).every((key) => {
      if (
        [FiltersEnum.Refund, FiltersEnum.SelfEmployed].includes(key) &&
        !selectedFilters[key]
      ) {
        return !filter[filterEnumToFilterInterfaceMap[key]];
      } else if (!selectedFilters[key]) {
        return true;
      }

      const value = filter[filterEnumToFilterInterfaceMap[key]];

      switch (key) {
        case FiltersEnum.NoCoPayment:
          return (
            (selectedFilters[FiltersEnum.WithCoPayment] &&
              selectedFilters[FiltersEnum.NoCoPayment]) ||
            !value
          );
        case FiltersEnum.NoHospitalization:
          return !value;

        case FiltersEnum.WithCoPayment:
          return (
            (selectedFilters[FiltersEnum.WithCoPayment] &&
              selectedFilters[FiltersEnum.NoCoPayment]) ||
            value
          );
        case FiltersEnum.Refund:
        case FiltersEnum.SelfEmployed:
        default:
          return value;
      }
    });
  });
};

const filterEnumToFilterInterfaceMap: Record<
  FiltersEnum,
  keyof OfferFiltersInterface
> = {
  [FiltersEnum.WithHospitalization]: 'hospitalization',
  [FiltersEnum.NoHospitalization]: 'hospitalization',
  [FiltersEnum.WithCoPayment]: 'coPayment',
  [FiltersEnum.NoCoPayment]: 'coPayment',
  [FiltersEnum.Refund]: 'refund',
  [FiltersEnum.SelfEmployed]: 'selfEmployer',
};

export const getCheckboxLabel = (compareOfferIds: string[], offerId: string) =>
  compareOfferIds.includes(offerId)
    ? `${LabelEnum.ShowComparison} (${compareOfferIds.length})`
    : LabelEnum.Compare;

export const getInitialFiltersMap = (quotation: QuotationState): FiltersMap => {
  const filtersMap: FiltersMap = filters
    .flatMap((group) => group.filters)
    .reduce((acc, value) => {
      acc[value] = false;
      return acc;
    }, {} as FiltersMap);

  filtersMap.WithHospitalization = !!quotation.hospitalization;
  filtersMap.WithCoPayment = !!quotation.coPayment;
  filtersMap.Refund = !!quotation.refund;
  filtersMap.SelfEmployed = !!quotation.selfEmployer;

  return filtersMap;
};
