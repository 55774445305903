import { LabelEnum } from '../enums/Label.enum';
import { removeWhitespace } from './string';

export const parseNumber = (value: string): string => {
  return `${removeWhitespace(value).slice(0, 9)}`;
};

export const getFullNumber = (value: string): string => {
  return `${LabelEnum.PhonePrefix}${parseNumber(value)}`;
};
