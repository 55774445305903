import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(({ palette, spacing, breakpoints }) => ({
  root: {
    minHeight: '100vh',
    backgroundColor: palette.surface,
    padding: spacing(8, 0),
    [breakpoints.up('sm')]: {
      padding: spacing(16, 0),
    },
  },
  logo: {
    marginBottom: spacing(3),
    textAlign: 'center',
  },
  loginFormWrapper: {
    position: 'relative',
    padding: spacing(4),
    [breakpoints.up('sm')]: {
      padding: spacing(6),
    },
    backgroundColor: palette.white,
    borderRadius: 12,
  },
}));
