import React from 'react';
import { useStyles } from './CalculationHeader.styles';
import { CalculationHeaderProps } from './CalculationHeader.interfaces';
import { AppLogo } from '../AppLogo';
import { ProgressIndicator } from '../ProgressIndicator';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import { LabelEnum } from 'app/shared/enums/Label.enum';

export default function CalculationHeader(props: CalculationHeaderProps) {
  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      <AppLogo />
      <Box mt={{ xs: 5, sm: 8 }}>
        <ProgressIndicator total={2} active={0} />
      </Box>
      <div className={classes.advert}>{LabelEnum.CalculationAdvert}</div>
      <Typography component="h1" variant="h2" className={classes.title}>
        {LabelEnum.CalculationFormTitle}
      </Typography>
    </div>
  );
}
