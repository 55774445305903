import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(
  ({ breakpoints, spacing, palette, typography }) => ({
    root: {
      padding: spacing(4),
      [breakpoints.up('sm')]: {
        padding: spacing(6),
      },
      backgroundColor: palette.white,
      borderRadius: 12,
    },
    consent: {
      ...typography.body.XS,
      color: palette.neutral.grey[40],
      height: 100,
      overflowY: 'scroll',
      marginBottom: spacing(4),
      padding: `${spacing(2)} ${spacing(3)}`,
      borderRadius: 10,
      backgroundColor: palette.neutral.grey[10],

      '&::-webkit-scrollbar': {
        width: 15,
        WebkitAppearance: 'none',
      },

      '&::-webkit-scrollbar-thumb': {
        margin: 10,
        border: `5px solid ${palette.neutral.grey[10]}`,
        borderRadius: 8,
        backgroundColor: palette.neutral.grey[30],
      },
    },
    consentLabel: {
      color: palette.neutral.grey[40],

      '& a': {
        color: 'inherit',
      },
    },
    questions: {
      display: 'flex',
      flexDirection: 'column',
      gap: spacing(3),
    },
    acceptAllCheckboxLabel: {
      ...typography.body.S,
      fontWeight: 600,
    },
  })
);
