import React from 'react';
import { useStyles } from './RadioCircle.styles';
import { RadioCircleProps } from './RadioCircle.interfaces';
import { Radio, useRadioGroup } from '@mui/material';

const RadioCircle = ({ value, label }: RadioCircleProps) => {
  const { classes } = useStyles();
  const radioGroup = useRadioGroup();

  return (
    <label className={classes.root}>
      <Radio name={radioGroup?.name} value={value} disableRipple />
      <div className={classes.label}>{label}</div>
    </label>
  );
};

export default RadioCircle;
