import React from 'react';
import { RadioGroup } from '@mui/material';

import { useStyles } from './RadioControl.styles';
import { RadioControlProps } from './RadioControl.interfaces';
import { RadioCircle } from 'app/components/RadioCircle';
import { ControlContainer } from 'app/components/ControlContainer';

export default function RadioControl(props: RadioControlProps) {
  const { classes } = useStyles();
  const { dictionary = [], label, meta, input, onValueChange } = props;
  const options = dictionary.map(({ key, value }) => ({
    value: key,
    label: value,
  }));

  const handleChange = (
    _: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    input.onChange(value);

    onValueChange?.({ value });
  };

  return (
    <ControlContainer label={label} meta={meta} name={input.name}>
      <RadioGroup
        onChange={handleChange}
        className={classes.radioGroup}
        value={input.value}
      >
        {options.map((option) => (
          <RadioCircle {...option} key={option.value} />
        ))}
      </RadioGroup>
    </ControlContainer>
  );
}
