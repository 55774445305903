import { Box } from '@mui/material';
import { RowMessages } from 'app/components/RowMessages';
import { FormRowContainerProps } from '../../components/FormRowContainer/FormRowContainer.interfaces';

export default function FormRowContainer(props: FormRowContainerProps) {
  const { messages, children } = props;

  return (
    <Box
      sx={({ palette, typography }) => ({
        '.MuiFormLabel-root': { ...typography.body.M, mb: 3, mr: 0 },
        '.MuiRadio-root': {
          backgroundColor: palette.white,
        },
      })}
    >
      {children}
      <RowMessages messages={messages} />
    </Box>
  );
}
