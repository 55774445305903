import { OfferById } from 'app/components/OfferById';
import { ProtectedRoute } from 'app/components/ProtectedRoute';
import { QuotationById } from 'app/components/QuotationById';
import { QuotationByRecoveryKey } from 'app/components/QuotationByRecoveryKey';
import { RouteEnum } from 'app/shared/enums/Route.enum';
import { isQuotationStatusAfterCalculation } from 'app/shared/helpers/quotation';
import { useTypedSelector } from 'app/shared/hooks/useTypedSelector';
import { selectQuotationStatus } from 'app/store/data/quotation';
import CalculationView from 'app/views/CalculationView/CalculationView';
import CalculatorBaseView from 'app/views/CalculatorBaseView/CalculatorBaseView';
import { LoginView } from 'app/views/LoginView';
import { OfferComparisonView } from 'app/views/OfferComparisonView';
import { OfferDetailsContactView } from 'app/views/OfferDetailsContactView';
import { OfferDetailsView } from 'app/views/OfferDetailsView';
import PPCCalculationView from 'app/views/PPCCalculationView/PPCCalculationView';
import ResultsView from 'app/views/ResultsView/ResultsView';
import RootView from 'app/views/RootView/RootView';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

export default function Router() {
  const location = useLocation();
  const background = location.state?.background;
  const quotationStatus = useTypedSelector(selectQuotationStatus);
  const isQuotationCalculated =
    quotationStatus && isQuotationStatusAfterCalculation(quotationStatus);

  return (
    <>
      <Routes location={background || location}>
        <Route path={RouteEnum.QUOTATION_BY_ID} element={<QuotationById />} />
        <Route
          path={RouteEnum.QUOTATION_BY_RECOVERY_KEY}
          element={<QuotationByRecoveryKey />}
        />
        <Route path={RouteEnum.ROOT_VIEW} element={<RootView />}>
          <Route index element={<Navigate to={RouteEnum.CALCULATOR_VIEW} />} />
          <Route path={RouteEnum.LOGIN} element={<LoginView />} />
          <Route path={RouteEnum.OFFER_BY_ID} element={<OfferById />} />
          <Route
            path={RouteEnum.QUOTATION_BY_RECOVERY_KEY}
            element={<QuotationByRecoveryKey />}
          />
          <Route
            path={RouteEnum.CALCULATOR_VIEW}
            element={<CalculatorBaseView />}
          >
            <Route
              index
              element={
                quotationStatus !== undefined ? (
                  <Navigate
                    to={RouteEnum.CALCULATION_FORM}
                    state={location.state}
                  />
                ) : null
              }
            />
            <Route
              element={
                <ProtectedRoute
                  isAllowed={!isQuotationCalculated}
                  redirectPath={RouteEnum.RESULTS_VIEW}
                />
              }
            >
              <Route
                path={RouteEnum.CALCULATION_FORM}
                element={<CalculationView />}
              />
            </Route>
            <Route
              element={
                <ProtectedRoute
                  isAllowed={!!isQuotationCalculated}
                  redirectPath={RouteEnum.CALCULATION_FORM}
                />
              }
            >
              <Route path={RouteEnum.RESULTS_VIEW} element={<ResultsView />}>
                <Route
                  path={RouteEnum.OFFER_ID}
                  element={<OfferDetailsView />}
                />
                <Route
                  path={RouteEnum.OFFER_DETAILS_CONTACT}
                  element={<OfferDetailsContactView />}
                />
              </Route>
            </Route>
          </Route>
        </Route>
        <Route path={RouteEnum.PPC_VIEW} element={<PPCCalculationView />} />
        <Route path="*" element={<Navigate to={RouteEnum.ROOT_VIEW} />} />
      </Routes>

      {background && (
        <Routes>
          <Route path={RouteEnum.OFFER_DETAILS} element={<OfferDetailsView />}>
            <Route
              path={RouteEnum.OFFER_DETAILS_CONTACT}
              element={<OfferDetailsContactView />}
            />
          </Route>
          <Route
            path={RouteEnum.RESULTS_VIEW_CONTACT}
            element={<OfferDetailsContactView />}
          />
          <Route
            path={RouteEnum.OFFER_COMPARISON}
            element={<OfferComparisonView />}
          />
        </Routes>
      )}
    </>
  );
}
