export enum ControlTypeEnum {
  Checkbox = 'CHECKBOX',
  CheckboxGroup = 'CHECKBOX_GROUP',
  Date = 'DATE',
  Email = 'EMAIL',
  Number = 'NUMBER',
  PhoneNumber = 'PHONE_NUMBER',
  ZipCode = 'ZIP_CODE',
  Text = 'TEXT',
  Select = 'SELECT',
  InsurantsAge = 'INSURANTS_AGE',
  Radio = 'RADIO',
  RadioSelect = 'RADIO_SELECT',
  InsurersSelect = 'INSURERS_SELECT',
}
