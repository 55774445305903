import React from 'react';
import { useStyles } from './TextButton.styles';
import { TextButtonProps } from './TextButton.interfaces';
import { Link } from 'react-router-dom';

export default function TextButton(props: TextButtonProps) {
  const { children, className, to, ...restProps } = props;
  const { classes, cx } = useStyles();

  return to ? (
    <Link
      to={to}
      state={restProps.state}
      className={cx(classes.root, className)}
      onClick={restProps.onClick}
    >
      {children}
    </Link>
  ) : (
    <button className={cx(classes.root, className)} {...restProps}>
      {children}
    </button>
  );
}
