import { QuotationService } from 'app/services/quotation.service';
import {
  selectIsAcquisitionRead,
  setIsAcquisitionRead,
} from 'app/store/data/acquisition';
import { selectQuotationId } from 'app/store/data/quotation';
import { useEffect } from 'react';
import {
  getUTMs,
  mapUTMsToAcquisitionParams,
  saveUTMsInCookie,
} from '../helpers/utm';
import { useTypedDispatch } from './useTypedDispatch';
import { useTypedSelector } from './useTypedSelector';

export const useSendAcquisitionParams = () => {
  const isAcquisitionRead = useTypedSelector(selectIsAcquisitionRead);
  const quotationId = useTypedSelector(selectQuotationId);
  const dispatch = useTypedDispatch();

  useEffect(() => {
    if (isAcquisitionRead || !quotationId) {
      return;
    }

    const utms = getUTMs();
    dispatch(setIsAcquisitionRead(true));

    if (utms) {
      try {
        saveUTMsInCookie(utms);
        const acquisitionParams = mapUTMsToAcquisitionParams(utms);
        QuotationService.setAcquisitionParams(quotationId, acquisitionParams);
      } catch (error) {
        console.warn(error);
      }
    }
  }, [dispatch, isAcquisitionRead, quotationId]);
};
