import { createTheme } from '@mui/material';
import { breakpoints } from './breakpoints';
import { components } from './components';
import { palette } from './palette';
import { getResponsiveTypography, typography } from './typography';

const theme = createTheme({
  palette,
  typography,
  spacing: 5,
  components,
  breakpoints,
});

theme.typography = { ...theme.typography, ...getResponsiveTypography(theme) };

export default theme;
