import { useForm } from 'react-final-form';
import { FieldsEnum } from '../enums/Fields.enum';

export const useFormValidate = () => {
  const form = useForm();
  const fields = Object.values(FieldsEnum);

  const validateForm = () => {
    fields.forEach((field) => form.blur(field));
  };

  return { validateForm };
};
