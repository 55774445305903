import { typography } from 'app/styles/theme/typography';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles({ name: 'ResultsView' })(
  ({ spacing, breakpoints, palette }) => ({
    root: {
      flex: '1 0 100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'strech',
      justifyContent: 'flex-start',
      padding: spacing(5, 4),
      position: 'relative',
      minHeight: '100vh',

      '& ul': {
        listStyle: 'none',
      },

      [breakpoints.up('md')]: {
        alignSelf: 'center',
        padding: spacing(10, 0, 6),
      },

      '& main': {
        flex: '1 0 auto',
      },
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: spacing(4),
      gap: spacing(4),

      [breakpoints.up('md')]: {
        marginTop: spacing(8),
        flexDirection: 'row',
      },
    },
    filters: {
      maxHeight: 70,

      [breakpoints.up('md')]: {
        maxHeight: 'unset',
      },
    },
    offers: {
      display: 'flex',
      flex: '1 1 auto',
      flexDirection: 'column',
      gap: spacing(3),
      padding: 0,
      margin: spacing(0, -4),

      [breakpoints.up('md')]: {
        margin: 0,
      },
    },
    footer: {
      whiteSpace: 'pre-line',
      ...typography.body?.XS,
      color: palette.neutral.grey[40],
      textAlign: 'justify',

      marginTop: spacing(4),
      [breakpoints.up('md')]: {
        marginTop: spacing(8),
      },

      '& p:last-child': {
        textAlign: 'center',
      },

      '& a': {
        color: palette.brand,
        textDecoration: 'none',
      },
    },
  })
);
