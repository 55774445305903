import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(({ palette, spacing, breakpoints }) => ({
  root: {
    minHeight: '100vh',
    backgroundColor: palette.surface,
    padding: spacing(8, 0),
    [breakpoints.up('sm')]: {
      padding: spacing(10, 0),
    },
  },
}));
