export const quotation = {
  id: 'deb75dbd-938a-4fc4-936c-89af3d657e70',
  userId: 'd0b72cda-3b0b-474d-9afa-98260525f914',
  number: 'J2CRNG7',
  emailAddress: 'test***@gmail.com',
  payment: null,
  operator: null,
  consents: null,
  phoneNumber: '12*****',
  startDate: null,
  status: 'CREATED',
  hospitalization: true,
  coPayment: true,
  selfEmployer: true,
  refund: false,
  numberOfPersons: null,
  insuredPersons: null,
  postalCode: null,
  currentlyInsured: false,
  previousInsurerName: '',
  sourceOfContact: 'idk',
};
