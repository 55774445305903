import CircularProgress from '@mui/material/CircularProgress';

import { useStyles } from './LoadingScreen.styles';

export default function LoadingScreen() {
  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress />
    </div>
  );
}
