import { Theme } from '@mui/material';
import { CSSObject } from 'tss-react';
import { makeStyles } from 'tss-react/mui';
import { ButtonColor, ButtonProps, ButtonSize } from './Button.interfaces';

export const useStyles = makeStyles<ButtonProps>({ name: 'Button' })(
  (theme, { size, color }) => {
    const sizeMapping = getSizeMapping(theme);
    const colorMapping = getColorMapping(theme);
    const sizeStyles = size ? sizeMapping[size] : {};
    const colorsStyles = color ? colorMapping[color] : {};

    return {
      root: {
        boxShadow: 'none',
        width: '100%',
        textAlign: 'center',
        '&:hover': {
          boxShadow: 'none',
        },
        ...sizeStyles.root,
        ...colorsStyles.root,
        textTransform: 'none',

        '& svg': {
          fill: colorsStyles.root?.color,
          fontSize: sizeStyles.root?.fontSize,
        },
      },
      disabled: {
        ...colorsStyles.disabled,
      },
    };
  }
);

export const getSizeMapping = ({
  spacing,
  typography,
}: Theme): Record<ButtonSize, Partial<Record<string, CSSObject>>> => ({
  Small: {
    root: {
      gap: 5,
      minHeight: 40,
      borderRadius: 50,
      padding: `${spacing(2)}px ${spacing(3)}px`,
      fontFamily: typography.fontFamily,
      fontSize: 14,
      fontWeight: 600,
      lineHeight: 1.29,
    },
  },

  Medium: {
    root: {
      gap: 5,
      minHeight: 48,
      borderRadius: 50,
      padding: `14px ${spacing(5)}px`,
      fontFamily: typography.fontFamily,
      fontSize: 16,
      fontWeight: 600,
      lineHeight: 1.25,
      '& .MuiSvgIcon-root': {
        fontSize: 16,
      },
    },
  },
  Large: {
    root: {
      gap: 10,
      minHeight: 56,
      borderRadius: 50,
      padding: `18px ${spacing(6)}px`,
      fontFamily: typography.fontFamily,
      fontSize: 18,
      fontWeight: 600,
      lineHeight: 1.11,
      '& .MuiSvgIcon-root': {
        fontSize: 18,
      },
    },
  },
});

export const getColorMapping = ({
  palette,
}: Theme): Record<ButtonColor, Partial<Record<string, CSSObject>>> => ({
  Brand: {
    root: {
      color: palette.white,
      backgroundColor: palette.brand,
      transition: '0.1s all',

      '&:hover': {
        opacity: 0.75,
        backgroundColor: palette.brand,
        boxShadow: 'none',
      },
    },
    disabled: {
      backgroundColor: palette.brand,
      opacity: 0.35,
    },
  },
  White: {
    root: {
      color: palette.black,
      backgroundColor: palette.white,
      transition: '0.1s all',

      '&:hover': {
        boxShadow: 'none',
        backgroundColor: palette.neutral.grey[10],
      },
    },

    disabled: {
      backgroundColor: palette.white,
      opacity: 0.35,
    },
  },
  Grey: {
    root: {
      color: palette.black,
      backgroundColor: palette.neutral.grey[10],
      transition: '0.1s all',

      '&:hover': {
        color: palette.neutral.grey[40],
        backgroundColor: palette.neutral.grey[10],
        boxShadow: 'none',
      },
    },
    disabled: {
      backgroundColor: palette.neutral.grey[10],
      opacity: 0.35,
    },
  },
  Dark: {
    root: {
      color: palette.white,
      backgroundColor: palette.black,
      transition: '0.1s all',

      '&:hover': {
        backgroundColor: palette.neutral.grey[50],
        boxShadow: 'none',
      },
    },

    disabled: {
      backgroundColor: palette.black,
      opacity: 0.25,
    },
  },
});
