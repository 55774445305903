import { IconName } from 'app/components/Icon/Icon.interfaces';
import { InsurerNameEnum } from '../enums/Insurers.enum';
import { LabelEnum } from '../enums/Label.enum';

export enum PreviousInsurerDictionaryKeys {
  none = 'none',
  sanitas = 'sanitas',
  asisa = 'asisa',
  adeslas = 'adeslas',
  aegon = 'aegon',
  nueva = 'nueva',
  fiatc = 'fiatc',
  other = 'other',
  dkv = 'dkv',
  imq = 'imq',
  allianz = 'allianz',
}

export const insurersConfig: Record<
  InsurerNameEnum,
  { image: string; icon?: IconName; name: string }
> = {
  [InsurerNameEnum.Adeslas]: {
    image: '',
    icon: 'Adeslas',
    name: 'Adeslas',
  },
  [InsurerNameEnum.AEGON]: {
    image: '',
    icon: 'Aegon',
    name: 'Aegon',
  },
  [InsurerNameEnum.AsefaSalud]: {
    image: '',
    name: 'AsefaSalud',
    icon: 'Aegon',
  },
  [InsurerNameEnum.Asisa]: {
    image: '',
    name: 'Asisa',
    icon: 'Asisa',
  },
  [InsurerNameEnum.Allianz]: {
    image: '',
    name: 'Allianz',
    icon: 'Allianz',
  },
  [InsurerNameEnum.Fiatc]: {
    image: '',
    name: 'Fiatc',
    icon: 'Fiatc',
  },
  [InsurerNameEnum.Nara]: {
    image: '',
    name: 'Nara',
    icon: 'Nara',
  },
  [InsurerNameEnum.Sanitas]: {
    image: '',
    name: 'Sanitas',
    icon: 'Sanitas',
  },
  [InsurerNameEnum.Axa]: { image: '', name: 'AXA', icon: 'Axa' },
  [InsurerNameEnum.Nueva]: {
    image: '',
    name: 'Nueva',
    icon: 'NuevaMutua',
  },
  [InsurerNameEnum.Other]: {
    image: '/images/other.png',
    name: LabelEnum.Other,
    icon: undefined,
  },
  [InsurerNameEnum.DKV]: {
    image: '',
    name: 'DKV',
    icon: 'DKV',
  },
  [InsurerNameEnum.IMQ]: {
    image: '',
    name: 'IMQ',
    icon: 'IMQ',
  },
};

export const keyToConfig: Record<
  PreviousInsurerDictionaryKeys,
  { image: string; icon?: IconName; name: string }
> = {
  [PreviousInsurerDictionaryKeys.none]: insurersConfig[InsurerNameEnum.Other],
  [PreviousInsurerDictionaryKeys.adeslas]:
    insurersConfig[InsurerNameEnum.Adeslas],
  [PreviousInsurerDictionaryKeys.asisa]: insurersConfig[InsurerNameEnum.Asisa],
  [PreviousInsurerDictionaryKeys.fiatc]: insurersConfig[InsurerNameEnum.Fiatc],
  [PreviousInsurerDictionaryKeys.nueva]: insurersConfig[InsurerNameEnum.Nueva],
  [PreviousInsurerDictionaryKeys.other]: insurersConfig[InsurerNameEnum.Other],
  [PreviousInsurerDictionaryKeys.sanitas]:
    insurersConfig[InsurerNameEnum.Sanitas],
  [PreviousInsurerDictionaryKeys.dkv]: insurersConfig[InsurerNameEnum.DKV],
  [PreviousInsurerDictionaryKeys.imq]: insurersConfig[InsurerNameEnum.IMQ],
  [PreviousInsurerDictionaryKeys.aegon]: insurersConfig[InsurerNameEnum.AEGON],
  [PreviousInsurerDictionaryKeys.allianz]:
    insurersConfig[InsurerNameEnum.Allianz],
};
