import { store } from 'app/store';
import { FieldsEnum } from 'app/shared/enums/Fields.enum';
import { updateQuotationDebouncedAction } from 'app/store/data/quotation/quotationActions';
import { JsonPatchValueType } from 'app/shared/types/JsonPatchValue.type';

export function patchValue(name: FieldsEnum, value: JsonPatchValueType) {
  store.dispatch(
    updateQuotationDebouncedAction({
      name,
      value,
    })
  );
}
