import { PaletteOptions } from '@mui/material/styles/createPalette';

export const primaryColors = {
  brand: '#0eb866',
  surface: '#F6F2EE',
  'brand-10': '#F3F8F6',
  'brand-20': '#D3F3E4',
  white: '#FFFFFF',
  black: '#0E0E0E',
};

export const netural = {
  grey: {
    10: '#f5f5f5',
    20: '#dbdbdb',
    30: '#b9b9b9',
    40: '#7b7b7b',
    50: '#3a3a3a',
  },
};

export const UISpecific = {
  attention: '#ffa553',
  error: '#D12B1E',
  success: '#0EB866',
  info: '#59ABB9',
  focus: '#3474e0',
  'attention-10': '#ffe8c6',
  'error-10': '#FBEAE9',
  'success-10': '#E7F8F0',
  'info-10': '#DEEEF1',
  'focus-10': '#C2D6F6',
};

export const palette: PaletteOptions = {
  primary: { main: primaryColors.brand },
  brand: primaryColors.brand,
  'brand-10': primaryColors['brand-10'],
  'brand-20': primaryColors['brand-20'],
  surface: primaryColors.surface,
  white: primaryColors.white,
  black: primaryColors.black,
  neutral: { grey: netural.grey },
  ui: {
    attention: UISpecific.attention,
    'attention-10': UISpecific['attention-10'],
    error: UISpecific.error,
    'error-10': UISpecific['error-10'],
    success: UISpecific.success,
    'success-10': UISpecific['success-10'],
    info: UISpecific.info,
    'info-10': UISpecific['info-10'],
    focus: UISpecific.focus,
    'focus-10': UISpecific['focus-10'],
  },
};
