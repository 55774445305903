import React from 'react';
import { SelectControlProps } from './SelectControl.interfaces';
import { Select } from 'app/components/Select';
import { SelectOption } from 'app/components/Select/Select.interfaces';
import { ControlContainer } from 'app/components/ControlContainer';

export default function SelectControl(props: SelectControlProps) {
  const { dictionary = [], input, label, meta } = props;

  const options = dictionary.map(({ value, key }) => {
    return {
      value: String(key),
      label: String(value),
    };
  });

  const handleChange = (
    _: React.SyntheticEvent<Element, Event>,
    value: SelectOption | null
  ) => {
    input.onChange(value?.value);
  };

  return (
    <ControlContainer label={label} meta={meta}>
      <Select
        options={options}
        onChange={handleChange}
        onBlur={input.onBlur}
        value={input.value}
      />
    </ControlContainer>
  );
}
