import React from 'react';
import { useStyles } from './Radio.styles';
import { RadioProps } from './Radio.interfaces';
import { Radio as MUIRadio } from '@mui/material';
import { Icon } from '../Icon';

export default function Radio({ onLabelClick, ...props }: RadioProps) {
  const { classes } = useStyles();

  return (
    <label className={classes.root}>
      <MUIRadio
        {...props}
        color="primary"
        classes={{
          ...props.classes,
        }}
        name={props.value}
        icon={<Icon name="RadioUnchecked" />}
        checkedIcon={<Icon name="RadioChecked" />}
        disableRipple
      />
      <span className={classes.text} onClick={onLabelClick}>
        {props.label}
      </span>
    </label>
  );
}
