import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import isEqual from 'lodash-es/isEqual';

import { QuotationResultsState, fetchQuotationResultsAction } from '.';

const initialState: QuotationResultsState = {
  insurers: {},
  offers: {},
  results: {},
  calculationTriggeredByUser: false,
  isLoading: false,
};

export const quotationResultsSlice = createSlice({
  name: 'quotationResults',
  initialState,
  reducers: {
    setIsQuotationSubmittedByUserAction: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.calculationTriggeredByUser = action.payload;
    },
    clearQuotationResults: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchQuotationResultsAction.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchQuotationResultsAction.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(fetchQuotationResultsAction.fulfilled, (state, action) => {
      const results = action.payload;

      const addInsurer = (insurer: typeof results[0]['insurer']) => {
        if (state.insurers[insurer.id] === undefined) {
          state.insurers[insurer.id] = insurer;
        }
      };

      const addOffer = (offer: typeof results[0]['offers'][0]) => {
        if (state.offers[offer.id] === undefined) {
          state.offers[offer.id] = offer;
        }
      };

      const setResult = (result: typeof results[0]) => {
        const resultNormalized = {
          ...result,
          offers: result.offers.map((offer) => offer.id),
        };

        if (!isEqual(state.results[resultNormalized.insurer.id], result)) {
          state.results[resultNormalized.insurer.id] = resultNormalized;
        }
      };

      results.forEach((result) => {
        addInsurer(result.insurer);
        result.offers.forEach(addOffer);
        setResult(result);
      });

      state.isLoading = false;
    });
  },
});

export const { clearQuotationResults, setIsQuotationSubmittedByUserAction } =
  quotationResultsSlice.actions;

export default quotationResultsSlice.reducer;
