import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(
  ({ typography, spacing, palette, breakpoints }) => ({
    root: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: spacing(4),
    },
    content: {
      textAlign: 'right',
    },
    label: {
      ...typography.body.XS,
      color: palette.neutral.grey[50],
    },
    phoneNumber: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: 6,
      marginTop: 6,
      textDecoration: 'none',

      ...typography.body.L,
      fontWeight: 600,
      color: palette.black,

      '& svg': {
        width: 18,
        height: 18,
      },

      '& path': {
        fill: palette.brand,
      },
    },
    userIconContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      width: 32,
      height: 32,

      [breakpoints.up('md')]: {
        width: 44,
        height: 44,
      },

      border: '2px solid',
      borderRadius: 100,
      borderColor: palette.brand,
      backgroundColor: palette.white,
    },
    userIcon: {
      width: 19,
      height: 19,

      [breakpoints.up('md')]: {
        width: 26,
        height: 26,
      },

      '& path': {
        fill: palette.brand,
      },
    },
  })
);
