import React from 'react';

import { useStyles } from './RowMessages.styles';
import { RowMessagesProps } from './RowMessages.interfaces';

function RowMessages(props: RowMessagesProps) {
  const { messages } = props;

  const { classes } = useStyles();

  if (!messages) {
    return null;
  }

  return (
    <>
      {messages.map((message) => (
        <div className={classes.root} key={message.key}>
          {message.content}
        </div>
      ))}
    </>
  );
}

export default React.memo(RowMessages);
