import { useCallback, useEffect } from 'react';
import TagManager from 'react-gtm-module';

import { ResultsOffer } from 'app/components/ResultsOffer/ResultsOffer.interfaces';
import {
  REACT_APP_GTM_AUTH,
  REACT_APP_GTM_ID,
  REACT_APP_GTM_PREVIEW,
} from 'app/shared/helpers/envConfig';
import { selectQuotation } from 'app/store/data/quotation';
import { useSelector } from 'react-redux';

export const FORM_NAME = 'salud';

export enum GTMEvent {
  StepView = 'step_view',
  FieldInput = 'field_input',
  // Results
  FormSubmit = 'form_submit',
  ResultsView = 'results_view',
  ClickToCall = 'click_to_call',
  ModifyData = 'modify_data',
  FilterCheck = 'filter_check',
  FilterUncheck = 'filter_uncheck',
  AddToCompare = 'add_to_compare',
  RemoveFromCompare = 'remove_from_compare',
  CompareView = 'compare_view',
  OrderCallClick = 'order_call_click',
  OrderCallSent = 'order_call_sent',
  OfferDetailsClick = 'offer_details_click',
  RecommendationSelect = 'recommendation_select',
}
export enum GTMSection {
  Header = 'header',
  Footer = 'footer',
  Filters = 'filters',
  Offer = 'offer',
  DetailsPopup = 'details_popup',
  ComparePopup = 'compare_popup',
}
export enum GTMElement {
  Link = 'link',
  Button = 'button',
  Checkbox = 'checkbox',
}

const tagManagerArgs = {
  gtmId: REACT_APP_GTM_ID,
  auth: REACT_APP_GTM_AUTH || undefined,
  preview: REACT_APP_GTM_PREVIEW || undefined,
};

export const useGTMInit = () => {
  const initGTMScript = () => {
    if (process.env.NODE_ENV === 'development') {
      return;
    }

    if (!REACT_APP_GTM_ID) {
      console.warn('GTM ID missing');
      return;
    }

    TagManager.initialize(tagManagerArgs);
  };

  useEffect(initGTMScript, []);
};

export const useGTM = () => {
  const quotation = useSelector(selectQuotation);

  const addDataLayer = useCallback(
    (event: GTMEvent, data?: Object) => {
      console.info({
        event,
        form_name: FORM_NAME,
        quotation_number: quotation.number,
        ...(data || {}),
      });

      TagManager.dataLayer({
        dataLayer: {
          event,
          form_name: FORM_NAME,
          quotation_number: quotation.number,
          ...(data || {}),
        },
      });
    },
    [quotation.number]
  );

  const addResultsEvent = useCallback(
    (
      event: GTMEvent,
      data: {
        section: GTMSection;
        element: { type: GTMElement; text: string; value?: boolean };
        offer?: ResultsOffer;
      }
    ) => {
      addDataLayer(event, {
        section: data.section,
        offer_number: data.offer?.number,
        offer_type:
          data.offer?.type === 'cheapest' ? 'recommended_lowest' : undefined,
        offer_value: data.offer?.price.finalPrice,
        insurer_name: data.offer?.insurerName,
        element_text: data.element.text,
        element_type: data.element.type,
      });
    },
    [addDataLayer]
  );

  return { addDataLayer, addResultsEvent };
};
