import React from 'react';
import { OfferDetailsContactViewProps } from './OfferDetailsContactView.interfaces';
import { OfferDetailsContact } from 'app/components/OfferDetailsContact';
import { Modal } from 'app/components/Modal';
import { useNavigate } from 'react-router-dom';

export default function OfferDetailsContactView(
  props: OfferDetailsContactViewProps
) {
  const navigate = useNavigate();

  const handleClose = () => navigate(-1);

  return (
    <Modal open maxWidth="xs" close={handleClose}>
      <OfferDetailsContact close={handleClose} />
    </Modal>
  );
}
