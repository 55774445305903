import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
  root: { position: 'absolute', right: 12, zIndex: 2, pointerEvents: 'none' },
  icon: {
    transition: 'all 300ms',
  },
  open: {
    transform: 'rotate(-180deg)',
  },
}));
