import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { UIState } from './state.interface';

const initialState: UIState = {
  isQuotationModified: false,
};

export const UISlice = createSlice({
  name: 'UI',
  initialState,
  reducers: {
    setIsQuotationModified: (state, action: PayloadAction<boolean>) => {
      state.isQuotationModified = action.payload;
    },
  },
});

export const { setIsQuotationModified } = UISlice.actions;
export default UISlice.reducer;
