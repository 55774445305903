import { DateTime } from 'luxon';

import { QuotationStatusEnum } from 'app/shared/enums/QuotationStatus.enum';
import { QuotationState } from 'app/store/data/quotation';
import { FieldsEnum } from '../enums/Fields.enum';

export function parseModelPath(modelPath: string) {
  return `/${modelPath.replace(/\./g, '/').replace(/\[([0-9]*)\]/gi, '/$1')}`;
}

export function isQuotationStatusAfterCalculation(status: QuotationStatusEnum) {
  const afterCalculationStatuses = [
    QuotationStatusEnum.SentToCalculation,
    QuotationStatusEnum.Calculated,
    QuotationStatusEnum.Redirected,
    QuotationStatusEnum.Checkout,
  ];

  return afterCalculationStatuses.includes(status);
}

export const isQuotationExpired = (quotationStartDate: string) => {
  const currentDate = DateTime.now();
  const startDate = DateTime.fromISO(quotationStartDate);

  return startDate.startOf('day') < currentDate.startOf('day');
};

export const mapQuotationToFormValues = (
  quotation: QuotationState
): FormValues => ({
  ...quotation,
  [FieldsEnum.Products]: {
    [FieldsEnum.Hospitalization]: !!quotation.hospitalization,
    [FieldsEnum.CoPayment]: !!quotation.coPayment,
    [FieldsEnum.SelfEmployer]: !!quotation.selfEmployer,
    [FieldsEnum.Refund]: !!quotation.refund,
  },
});

export interface FormValues extends QuotationState {
  [FieldsEnum.Products]: {
    [FieldsEnum.Hospitalization]: boolean;
    [FieldsEnum.CoPayment]: boolean;
    [FieldsEnum.SelfEmployer]: boolean;
    [FieldsEnum.Refund]: boolean;
  };
}

export const mapFormValuesToQuotation = (values: FormValues) => ({
  ...values,
  ...values[FieldsEnum.Products],
});
