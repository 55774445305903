import React from 'react';
import { useStyles } from './ResultsOfferFilterList.styles';
import { ResultsOfferFilterListProps } from './ResultsOfferFilterList.interfaces';
import { offerFilterKeyToProductLabelMap } from 'app/views/ResultsView/ResultsView.constants';
import { Icon } from '../Icon';

export default function ResultsOfferFilterList(
  props: ResultsOfferFilterListProps
) {
  const { filters } = props;
  const { classes } = useStyles();
  const items = Object.keys(filters).map((key) => ({
    value: filters[key],
    label: offerFilterKeyToProductLabelMap[key],
  }));

  return (
    <div className={classes.root}>
      {items?.map(({ value, label }) => (
        <div
          className={classes.item}
          key={label}
          data-cy="offer-filter"
          data-value={value}
        >
          {value ? <Icon name="CheckCircle" /> : <Icon name="CloseCircle" />}
          <div className={classes.label}>{label}</div>
        </div>
      ))}
    </div>
  );
}
