import { CallService } from 'app/services/call.service';
import { LabelEnum } from 'app/shared/enums/Label.enum';
import { PreferredTime } from 'app/shared/enums/PrefferedTime.enum';
import { getFullNumber, parseNumber } from 'app/shared/helpers/phone';
import {
  GTMElement,
  GTMEvent,
  GTMSection,
  useGTM,
} from 'app/shared/hooks/useGTM';
import { selectQuotationId } from 'app/store/data/quotation';
import { selectQuotationPhoneNumber } from 'app/store/data/quotation/quotationSelectors';
import { selectOfferById } from 'app/store/data/quotationResults/quotationResultsSelectors';
import validate from 'app/validators/fieldLevelValidators/phoneNumber';
import required from 'app/validators/fieldLevelValidators/required';
import { ChangeEvent, FocusEvent, FormEvent, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

type ViewState = 'DEFAULT' | 'SUCCESS' | 'AFTER_HOURS';

export const useOfferDetailsContact = () => {
  const [viewState, setViewState] = useState<ViewState>('DEFAULT');
  const phoneInput = usePhoneInput();
  const quotationId = useSelector(selectQuotationId);
  const { offerId } = useParams();
  const offer = useSelector(selectOfferById(offerId));

  const { addResultsEvent } = useGTM();

  const onPhoneNumberClick = () => {
    addResultsEvent(GTMEvent.ClickToCall, {
      element: {
        type: GTMElement.Link,
        text: LabelEnum.ContactNumber,
      },
      section: GTMSection.DetailsPopup,
      offer: offer || undefined,
    });
  };

  const handleCallRequestSubmit = async (event: FormEvent) => {
    event.preventDefault();
    const inputError = phoneInput.validateInput();

    if (inputError || !offer) {
      return;
    }

    try {
      const response = await CallService.requestCall({
        phoneNumber: getFullNumber(phoneInput.value),
        offerNumber: offer.number,
        preferredTime: PreferredTime.ASAP,
        quotationId,
      });

      addResultsEvent(GTMEvent.OrderCallSent, {
        element: {
          type: GTMElement.Button,
          text: LabelEnum.WeWillCallYou,
        },
        section: GTMSection.DetailsPopup,
        offer,
      });

      const nextViewState = response.status === 204 ? 'SUCCESS' : 'DEFAULT';
      setViewState(nextViewState);
    } catch (error) {
      console.error(error);
    }
  };

  return {
    viewState,
    onPhoneNumberClick,
    handleCallRequestSubmit,
    phoneInput,
  };
};

export const usePhoneInput = () => {
  const initialPhoneInput = useSelector(selectQuotationPhoneNumber);
  const isInitialValueValid =
    !!initialPhoneInput && !validate(initialPhoneInput);

  const [value, setValue] = useState(
    isInitialValueValid ? initialPhoneInput : ''
  );
  const [touched, setTouched] = useState(false);
  const [error, setError] = useState<string | undefined>('');

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = parseNumber(event.target.value);
    setValue(value);
  };

  const onFocus = (_: FocusEvent<HTMLInputElement>) => {
    setTouched(true);
  };

  const onBlur = (_: FocusEvent<HTMLInputElement>) => {
    validateInput();
  };

  const validateInput = () => {
    const error = validate(value) || required(value);
    setError(error);
    setTouched(true);

    return error;
  };

  return {
    value,
    error: touched && error,
    onChange,
    onBlur,
    onFocus,
    validateInput,
  };
};
