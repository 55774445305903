import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(({ palette, spacing, breakpoints }) => ({
  root: {
    position: 'relative',
    padding: spacing(4),
    [breakpoints.up('sm')]: {
      padding: spacing(6),
    },
    backgroundColor: palette.white,
    borderRadius: 12,
  },
}));
