import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ShowNotificationActionPayload } from './actionPayload.interface';
import { NotificationState } from './state.interface';

const initialState: NotificationState = {
  notification: undefined,
};

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    showNotificationAction: (
      _,
      action: PayloadAction<ShowNotificationActionPayload>
    ) => ({
      notification: action.payload,
    }),
    hideNotificationAction: () => {
      return initialState;
    },
  },
});

export const { hideNotificationAction, showNotificationAction } =
  notificationSlice.actions;

export default notificationSlice.reducer;
