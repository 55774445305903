import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles({ name: 'TextButton' })(
  ({ typography, palette }) => ({
    root: {
      ...typography.body.S,
      padding: 0,
      border: 'none',
      textDecorationLine: 'underline',
      backgroundColor: 'transparent',
      color: palette.neutral.grey[50],
      cursor: 'pointer',
    },
  })
);
