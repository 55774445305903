import { Theme } from '@mui/material';
import { TypographyOptions } from '@mui/material/styles/createTypography';
import { CSSObject } from 'tss-react/types';

export const getResponsiveTypography = (
  theme: Theme
): Record<string, CSSObject> => ({
  h1: {
    fontFamily: 'Chivo',
    fontSize: 68,
    fontWeight: 900,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '68px',
    letterSpacing: 'normal',
    [theme.breakpoints.down('sm')]: {
      fontSize: 44,
      lineHeight: '44px',
    },
  },
  h2: {
    fontFamily: 'Chivo',
    fontSize: 44,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '44px',
    letterSpacing: 'normal',
    [theme.breakpoints.down('sm')]: {
      fontSize: 32,
      lineHeight: '34px',
    },
  },
  h3: {
    fontFamily: 'Chivo',
    fontSize: 36,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '40px',
    letterSpacing: 'normal',
    [theme.breakpoints.down('sm')]: {
      fontSize: 26,
      lineHeight: '29px',
    },
  },
  h4: {
    fontFamily: 'Chivo',
    fontSize: 26,
    fontWeight: 400,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '32px',
    letterSpacing: 'normal',
    [theme.breakpoints.down('sm')]: {
      fontSize: 22,
      lineHeight: '25px',
    },
  },
  h5: {
    fontFamily: 'Montserrat',
    fontSize: 18,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '24px',
    letterSpacing: 'normal',
  },
  h6: {
    fontFamily: 'Montserrat',
    fontSize: 16,
    fontWeight: 700,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '20px',
    letterSpacing: 'normal',
  },
});

export const typography: TypographyOptions = {
  fontFamily: '"Montserrat", "Helvetica", sans-serif',
  body: {
    XS: {
      fontFamily: 'Montserrat',
      fontSize: 12,
      fontWeight: 500,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '16px',
      letterSpacing: 'normal',
    },
    S: {
      fontFamily: 'Montserrat',
      fontSize: 14,
      fontWeight: 500,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '18px',
      letterSpacing: 'normal',
    },
    M: {
      fontFamily: 'Montserrat',
      fontSize: 16,
      fontWeight: 500,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '24px',
      letterSpacing: 'normal',
    },
    L: {
      fontFamily: 'Montserrat',
      fontSize: 18,
      fontWeight: 500,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '24px',
      letterSpacing: 'normal',
    },
  },
  preTitle: {
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '15px',
    letterSpacing: 'normal',
    textTransform: 'uppercase',
  },
};
