import { useEffect, useState } from 'react';

import { useTypedDispatch } from 'app/shared/hooks/useTypedDispatch';
import { getAuthAction } from 'app/store/data/user/userActions';
import { useSelector } from 'react-redux';
import { selectIsLoggedIn } from 'app/store/data/user';

export function useAuth() {
  const [isLoading, setIsLoading] = useState(true);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const dispatch = useTypedDispatch();

  useEffect(() => {
    const getAuth = async () => {
      try {
        await dispatch(getAuthAction());
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    getAuth();
  }, [dispatch]);

  return { isLoading, isLoggedIn };
}
