import React from 'react';

import { useStyles } from './FormControlErrorText.styles';
import { FormControlErrorTextProps } from './FormControlErrorText.interfaces';
import { Icon } from 'app/components/Icon';

export default function FormControlErrorText({
  children,
  className,
}: FormControlErrorTextProps) {
  const { classes, cx } = useStyles();

  return (
    <div className={cx(classes.root, className)} data-cy="alert">
      <Icon name="Alert" className={classes.alertIcon} />
      {children}
    </div>
  );
}
