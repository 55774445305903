import Cookies from 'js-cookie';
import qs from 'query-string';
import { AcquisitionParams } from '../interfaces/AcquisitionParams.interface';
import { UTMs } from '../interfaces/UTMs.interface';

const COLLECTED_UTMS = [
  'utm_campaign',
  'utm_source',
  'utm_medium',
  'utm_name',
  'utm_term',
  'utm_content',
  'utm_referrer',
];

export const LOCAL_STORAGE_ACQUISITION_KEY = 'acquisition';
export const COOKIE_ACQUISITION_KEY = 'acquisition';

const isUTMs = (params: Partial<UTMs>): params is UTMs =>
  'utm_campaign' in params;

const getUrlUTMs = (): Partial<UTMs> => {
  const search = window.location.search;
  const stripedUrl = qs.pick(search, COLLECTED_UTMS);
  const params = qs.parse(stripedUrl);

  return params;
};

const getCookieUTMs = (): Partial<UTMs> | undefined => {
  const cookie = Cookies.get(COOKIE_ACQUISITION_KEY);
  const params = cookie ? JSON.parse(cookie) : {};

  return params['utm_campaign'] !== null ? params : undefined;
};

export const saveUTMsInCookie = (utms: UTMs): void => {
  const cookie = JSON.stringify(utms);
  Cookies.set(COOKIE_ACQUISITION_KEY, cookie, {
    domain: '.arpem.com',
    expires: 365,
  });
};

export const getUTMs = (): UTMs | undefined => {
  const urlUTMs = getUrlUTMs();
  const cookieUTMs = getCookieUTMs();

  if (isUTMs(urlUTMs)) {
    return urlUTMs;
  } else if (cookieUTMs && isUTMs(cookieUTMs)) {
    return cookieUTMs;
  } else {
    return undefined;
  }
};

export const mapUTMsToAcquisitionParams = (utms: UTMs): AcquisitionParams => {
  return {
    campaign: utms.utm_campaign,
    content: utms.utm_content,
    medium: utms.utm_medium,
    name: utms.utm_name,
    referrer: utms.utm_referrer,
    source: utms.utm_source,
    term: utms.utm_term,
  };
};
