import React from 'react';
import { useStyles } from './InsuranceLogo.styles';
import { InsuranceLogoProps } from './InsuranceLogo.interfaces';
import { insurersConfig } from 'app/shared/helpers/insurersConfig';
import { Icon } from '../Icon';
import { getScaledImages } from 'app/shared/helpers/image';

export default function InsuranceLogo({ name }: InsuranceLogoProps) {
  const { classes } = useStyles();

  const icon = name && insurersConfig[name]?.icon;
  const image = insurersConfig[name]?.image || '';
  const scaledImages = getScaledImages(image);

  return (
    <div className={classes.root}>
      {icon && <Icon name={icon} />}
      {!icon && (
        <img
          srcSet={`${scaledImages[1]} 210w, ${scaledImages[0]} 140w, ${image} 70w, `}
          src={image}
          alt={name}
        />
      )}
    </div>
  );
}
