import { Box } from '@mui/material';
import { InfiniteCarouselProps } from './InfiniteCarousel.interfaces';

export default function InfiniteCarousel({
  children,
  animationSpeed = 20,
  itemWidth = 200,
}: InfiniteCarouselProps) {
  const length = children.length;
  const items = [...children, ...children];

  return (
    <Box sx={{ overflowX: 'hidden' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'nowrap',
          alignItems: 'center',

          '@keyframes slide': {
            '0%': {
              transform: 'translateX(0)',
            },
            '100%': {
              transform: `translateX(-${length * itemWidth}px)`,
            },
          },
          animation: `slide ${animationSpeed}s linear infinite`,
        }}
      >
        {items}
      </Box>
    </Box>
  );
}
