import { DictionariesEnum } from 'app/shared/enums/Dictionaries.enum';
import { LabelEnum } from 'app/shared/enums/Label.enum';
import { DictService, Dictionary } from 'app/services/dict.service';

export const isZipCodeFormatValid = (value: string) => {
  const re = /^[0-9]{5}$/;

  return re.test(value);
};

let cache: { value: string; data: Dictionary } | undefined = undefined;

const zipCode = async (value: string) => {
  const isFormatValid = isZipCodeFormatValid(value);

  if (isFormatValid) {
    const { data } =
      value === cache?.value
        ? cache
        : await DictService.getDictionary(DictionariesEnum.CitiesWithProvince, {
            zipCode: value,
          });

    cache = { value, data };

    return data.length > 0 ? undefined : LabelEnum.ZipCodeInvalid;
  }

  return LabelEnum.ZipCodeInvalid;
};

export default zipCode;
