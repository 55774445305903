import isNil from 'lodash-es/isNil';

import { LabelEnum } from 'app/shared/enums/Label.enum';
import { topLevelDomains } from './topLevelDomains';

function isEmailValid(email: string, validateTopLevelDomain?: boolean) {
  const isEmailDefined = !isNil(email);

  if (!isEmailDefined) {
    return false;
  }

  // TODO: remove escapes
  const re =
    /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*)@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/im; // eslint-disable-line no-control-regex

  const isFormatValid = re.test(String(email).toLowerCase());

  if (validateTopLevelDomain) {
    const emailParts = email.split(/\.|@/);
    const lastEmailPart = emailParts[emailParts.length - 1].toLowerCase();
    const isTopLevelDomainValid = topLevelDomains.includes(lastEmailPart);

    return isFormatValid && isTopLevelDomainValid;
  } else {
    return isFormatValid;
  }
}

const email = (value: string) => {
  return !value || isEmailValid(value, true)
    ? undefined
    : LabelEnum.EmailInvalid;
};

export default email;
