import LoadingButton from '@mui/lab/LoadingButton';
import { Box } from '@mui/material';
import { CalculationFormConfig } from 'app/components/CalculationFormConfig';
import { Form } from 'app/components/Form';
import { LabelEnum } from 'app/shared/enums/Label.enum';
import { scrollToTarget } from 'app/shared/helpers/scrollToTarget';
import { useForm, useFormState } from 'react-final-form';
import { Button } from '../Button';
import { ButtonProps } from '../Button/Button.interfaces';
import { Icon } from '../Icon';
import { useCalculationForm } from './useCalculationForm';

export default function CalculationForm() {
  const {
    handleSubmit,
    initialValues,
    isLoading,
    handleSubmitCapture,
    activeScreenIndex,
    nextScreen,
  } = useCalculationForm();

  const isFinalScreen = activeScreenIndex === 1;

  return (
    <Form
      action={handleSubmit}
      initialValues={initialValues}
      isLoading={isLoading}
      submitLabel={LabelEnum.CalculationFormSubmitButton}
      onSubmitCapture={handleSubmitCapture}
      FormButton={({ isLoading }) => (
        <FormButton
          isLoading={isLoading}
          isFinalScreen={isFinalScreen}
          onClick={nextScreen}
        />
      )}
    >
      <CalculationFormConfig activeScreenIndex={activeScreenIndex} />
    </Form>
  );
}

interface FormButtonProps extends Partial<ButtonProps> {
  isLoading: boolean;
  isFinalScreen: boolean;
}

const FormButton = ({ isLoading, isFinalScreen, onClick }: FormButtonProps) => {
  const { hasValidationErrors } = useFormState();
  const { submit } = useForm();

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (hasValidationErrors) {
      submit();
      scrollToTarget('[data-field-error="true"]');
    } else {
      onClick?.(e);
    }
  };

  return isFinalScreen ? (
    <LoadingButton
      type="submit"
      size="large"
      variant="contained"
      disableElevation
      fullWidth
      loading={isLoading}
    >
      {LabelEnum.CalculationFormSubmitButton}
    </LoadingButton>
  ) : (
    <Button size="Large" color="Brand" onClick={handleClick}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 1,
          alignItems: 'center',
        }}
      >
        {LabelEnum.Next} <Icon name="ArrowRight" />
      </Box>
    </Button>
  );
};
