import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(({ spacing }) => ({
  formContent: {
    marginBottom: spacing(8),
  },
  button: {},
  ppcButton: {
    margin: spacing(14, 0, 4),
    svg: {
      marginLeft: '10px',
    },
  },
}));
