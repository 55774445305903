import { Autocomplete, ListItem, TextField } from '@mui/material';
import { SelectEndAdornment } from 'app/components/SelectEndAdornment';
import { forwardRef, useState } from 'react';
import { Icon } from '../Icon';
import { SelectOption, SelectProps } from './Select.interfaces';
import { useStyles } from './Select.styles';

const Select = forwardRef((props: SelectProps, ref) => {
  const {
    options = [],
    className,
    onChange,
    onBlur,
    value,
    textFieldProps,
    renderOption,
  } = props;
  const { classes, cx } = useStyles();
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const defaultOption = (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: SelectOption
  ) => (
    <ListItem {...props} data-value={option.value}>
      {option.label}
    </ListItem>
  );

  return (
    <Autocomplete
      className={cx(classes.root, className)}
      options={options}
      value={value}
      open={isOpen}
      onChange={onChange}
      onOpen={handleOpen}
      onClose={handleClose}
      onBlur={onBlur}
      onFocus={handleOpen}
      getOptionLabel={(option) => option.label}
      ref={ref}
      renderOption={renderOption || defaultOption}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            {...textFieldProps}
            InputLabelProps={{
              shrink: false,
              ...textFieldProps?.InputLabelProps,
            }}
            InputProps={{
              ...params.InputProps,
              startAdornment: !!value?.icon && <Icon name={value.icon} />,
              endAdornment: (
                <SelectEndAdornment isOpen={isOpen} onClick={handleOpen} />
              ),
              ...textFieldProps?.InputProps,
            }}
          />
        );
      }}
    />
  );
});

export default Select;
