import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(({ typography, spacing }) => ({
  root: {},
  option: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 50,
    overflow: 'hidden',
    padding: spacing(0, 2),

    ...typography.body?.S,
    gap: spacing(2),

    '& svg': {
      width: 'auto',
      height: 'auto',
      maxWidth: 60,
      maxHeight: 60,
      zIndex: 2,
    },
  },
  input: {
    '& svg': {
      width: 'auto',
      height: 'auto',
      maxWidth: 60,
      maxHeight: 35,
      zIndex: 2,
    },
  },
}));
