import React from 'react';
import { Navigate, Outlet } from 'react-router';
import { ProtectedRouteProps } from './ProtectedRoute.interfaces';
import { RouteEnum } from 'app/shared/enums/Route.enum';
import { useLocation } from 'react-router-dom';

const ProtectedRoute = ({
  isAllowed,
  redirectPath = RouteEnum.ROOT_VIEW,
  children,
}: ProtectedRouteProps) => {
  const location = useLocation();

  if (!isAllowed) {
    return <Navigate to={redirectPath} replace state={location.state} />;
  }

  return children ? <>{children}</> : <Outlet />;
};

export default ProtectedRoute;
