import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(({ palette, typography }) => ({
  root: {
    display: 'grid',
    placeContent: 'center',
    position: 'relative',

    overflow: 'hidden',
    cursor: 'pointer',

    userSelect: 'none',

    ...typography.body.M,
    color: palette.black,

    '& input[type="radio"]': {
      appearance: 'none',
      WebkitAppearance: 'none',
      opacity: 1,

      height: 56,
      width: 56,

      border: 'solid 2px',
      borderColor: palette.neutral?.grey[20],
      borderRadius: '50%',
      transition: 'all 150ms',

      '&:focus, &:focus-visible, &:hover': {
        borderColor: palette.neutral?.grey[30],
        outline: 'none',
      },

      '&:checked': {
        borderColor: palette.brand,
        backgroundColor: palette['brand-10'],
      },
    },

    '& .MuiRadio-root': {
      height: 56,
      width: 56,
      appearance: 'auto',

      '& svg': {
        display: 'none',
      },
    },
  },
  label: {
    display: 'grid',
    placeContent: 'center',

    position: 'absolute',
    zIndex: 2,
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },
}));
