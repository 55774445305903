import { Navigate, useParams } from 'react-router-dom';

import { RouteEnum } from 'app/shared/enums/Route.enum';
import { useAuth } from 'app/shared/hooks/useAuth';
import { LoadingScreen } from '../LoadingScreen';

export default function QuotationById() {
  const params = useParams();
  const { isLoading, isLoggedIn } = useAuth();

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <Navigate
      to={
        !isLoggedIn
          ? `${RouteEnum.ROOT_VIEW}/${RouteEnum.LOGIN}`
          : `${RouteEnum.ROOT_VIEW}/${RouteEnum.CALCULATOR_VIEW}`
      }
      replace={true}
      state={{ quotationId: params.quotationId }}
    />
  );
}
