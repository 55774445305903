import { QuotationStatusEnum } from 'app/shared/enums/QuotationStatus.enum';
import { RequestCallStatus } from 'app/shared/enums/RequestCallStatus.enum';
import { REACT_APP_API_URL } from 'app/shared/helpers/envConfig';
import { RequestCallResponse } from 'app/shared/interfaces/RequestCall.interface';
import { dictionaries } from 'app/shared/mocks/dictionaries.mock';
import { quotation } from 'app/shared/mocks/quotation.mock';
import { me } from 'app/shared/mocks/me.mock';
import { quotationResults } from 'app/shared/mocks/quotationResults.mock';
import { DateTime } from 'luxon';

import { createServer } from 'miragejs';

export function makeServer({ environment = 'test' } = {}) {
  let quotationSubmited = false;

  return createServer({
    environment,
    routes() {
      this.urlPrefix = REACT_APP_API_URL || 'localhost:3000';

      Object.keys(dictionaries).forEach((key) => {
        this.get(`dictionaries/${key}`, () => dictionaries[key]);
      });

      this.get(`health-insurances/quotations/last`, () =>
        quotationSubmited
          ? {
              ...quotation,
              status: QuotationStatusEnum.Calculated,
              startDate: quotation.startDate || DateTime.now().toISO(),
            }
          : quotation
      );

      this.post(`health-insurances/quotations/:id/copies`, () =>
        quotationSubmited
          ? {
              ...quotation,
              status: QuotationStatusEnum.InProgress,
              startDate: quotation.startDate || DateTime.now().toISO(),
            }
          : quotation
      );

      this.post(`health-insurances/quotations/:id/results`, () => {
        quotationSubmited = true;
        return quotationResults;
      });

      this.get(`health-insurances/quotations/:id/results`, () =>
        quotationSubmited ? quotationResults : {}
      );

      this.post<RequestCallResponse>(`health-insurances/call`, () =>
        Math.random() > 0.5
          ? { status: RequestCallStatus.Success }
          : { status: RequestCallStatus.AfterHours }
      );

      this.get(`users/me`, () => me);

      this.passthrough();
      this.passthrough('https://api.privacy-center.org/**');
      this.passthrough('https://www.evenbits.co/**');
    },
  });
}
