import React from 'react';
import { useStyles } from './OfferComparisonView.styles';
import { OfferComparisonViewProps } from './OfferComparisonView.interfaces';
import { Modal } from 'app/components/Modal';
import { OfferComparisonTable } from 'app/components/OfferComparisonTable';
import { useOfferComparisonView } from './useOfferComparisonView';
import { Typography } from '@mui/material';
import { LabelEnum } from 'app/shared/enums/Label.enum';

export default function OfferComparisonView(props: OfferComparisonViewProps) {
  const { classes } = useStyles();
  const { handleClose, offers, removeOffer, handleContact } =
    useOfferComparisonView();

  return (
    <Modal open close={handleClose} fullScreen>
      <div className={classes.root}>
        <Typography variant="h4" className={classes.title}>
          {LabelEnum.Compare}
        </Typography>
        <OfferComparisonTable
          offers={offers}
          onRemoveOfferButtonClick={removeOffer}
          onPrimaryOfferButtonClick={handleContact}
        />
      </div>
    </Modal>
  );
}
