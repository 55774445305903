import { useTypedDispatch } from 'app/shared/hooks/useTypedDispatch';
import { useTypedSelector } from 'app/shared/hooks/useTypedSelector';
import { createNewQuotationAction } from 'app/store/data/quotation';
import { logoutAction, selectIsLoggedIn } from 'app/store/data/user';
import { useNotification } from 'app/store/data/notification';
import { MessageTypeEnum } from 'app/shared/enums/MessageType.enum';
import { LabelEnum } from 'app/shared/enums/Label.enum';

export function useLogoutButton() {
  const isLoggedIn = useTypedSelector(selectIsLoggedIn);
  const dispatch = useTypedDispatch();
  const notify = useNotification();

  const logout = async () => {
    await dispatch(logoutAction()).unwrap();
    notify(MessageTypeEnum.Success, LabelEnum.LogoutSucceded);
    dispatch(createNewQuotationAction());
  };

  return { isLoggedIn, logout };
}
