import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(
  ({ spacing, typography, palette, breakpoints }) => ({
    root: {
      display: 'flex',
      flex: '1 0 320px',
      flexDirection: 'column',
      alignItems: 'center',
      padding: spacing(6),

      backgroundColor: palette['brand-10'],
      borderRadius: 10,

      '& .MuiFormControl-root': {
        width: '100%',
      },
    },
    content: {
      display: 'flex',
      flex: '1 0 auto',
      flexDirection: 'column',
      textAlign: 'center',

      paddingTop: spacing(6),
      paddingBottom: spacing(3),
    },
    contentRequested: {
      gap: spacing(2),
      paddingBottom: spacing(4),
      justifyContent: 'center',
    },
    image: {
      maxWidth: 190,
      maxHeight: 190,
      width: 'auto',
      height: 'auto',
    },
    title: {
      marginBottom: spacing(3),
    },
    header: {
      margin: 0,
      ...typography.h5,
    },
    callUs: {
      marginTop: spacing(5),
      marginBottom: spacing(3),
    },
    button: {
      marginTop: spacing(3),
    },
    contactNumber: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      gap: spacing(2),

      ...typography.h3,
      textDecoration: 'none',
      color: palette.brand,

      '& p': {
        lineHeight: '40px',
      },
    },
    sidenote: {
      ...typography.body.S,
      whiteSpace: 'pre',
      color: palette.neutral.grey[40],
      textAlign: 'center',
      marginTop: spacing(3),
    },
    requestedTitle: {
      ...typography.h5,
      color: palette.brand,
      margin: 0,
    },
    requestedBody: {
      ...typography.body.S,
      fontWeight: 600,
      margin: 0,
    },
    closeButton: {
      marginTop: spacing(6),
      [breakpoints.up('md')]: {
        display: 'none',
      },
    },
    error: {
      textAlign: 'left',
      flex: '0 0 auto',
      margin: spacing(1, 0, 0),
    },
  })
);
