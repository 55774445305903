import { Field } from 'react-final-form';

import FormRowContainer from 'app/components/FormRowContainer/FormRowContainer';
import { FormRowProps } from './FormRow.interfaces';
import { useFormRow } from './useFormRow';

export default function FormRow(props: FormRowProps) {
  const {
    visible = true,
    messages,
    renderFormRowContainer: ContainerComponent = FormRowContainer,
    ...fieldProps
  } = props;

  const {
    component,
    dictionary,
    handleControlBlur,
    handleControlValueChange,
    isLoading,
    validate,
  } = useFormRow(props);

  if (!visible) {
    return null;
  }

  return (
    <ContainerComponent messages={messages}>
      <Field
        {...fieldProps}
        validate={validate}
        component={component}
        dictionary={dictionary}
        isLoading={isLoading}
        onControlBlur={handleControlBlur}
        onValueChange={handleControlValueChange}
      />
    </ContainerComponent>
  );
}
