import React, { useState } from 'react';
import { RadioGroup } from '@mui/material';

import { Select } from 'app/components/Select';
import { useStyles } from './RadioSelectControl.styles';
import { RadioCircle } from 'app/components/RadioCircle';
import { RadioSelectControlProps } from './RadioSelectControl.interfaces';
import { SelectOption } from 'app/components/Select/Select.interfaces';
import { LabelEnum } from 'app/shared/enums/Label.enum';
import { ControlContainer } from 'app/components/ControlContainer';
import { getInitialValues, SLICE_INDEX } from './RadioSelectControl.utils';

export default function RadioSelectControl(props: RadioSelectControlProps) {
  const { classes } = useStyles();
  const {
    input,
    label,
    meta,
    dictionary = [],
    onControlBlur,
    onValueChange,
    tooltip,
  } = props;
  const initialValues = getInitialValues(String(input.value));
  const [selectValue, setSelectValue] = useState<SelectOption | null>(
    initialValues.select
  );
  const [radioValue, setRadioValue] = useState<string>(initialValues.radio);
  const options = dictionary?.map(({ value }) => ({
    value: String(value),
    label: String(value),
  }));
  const radioOptions = options.slice(0, SLICE_INDEX);
  const selectOptions = options.slice(SLICE_INDEX);

  const handleBlur = (_: React.FocusEvent<HTMLDivElement>) => {
    onControlBlur?.({
      value: selectValue?.value || radioValue,
      shouldPatch: true,
    });
  };

  const handleChange = (value: string) => {
    input.onChange(value);
    onValueChange?.({ value, shouldPatch: true });
  };

  const handleSelectChange = (
    _: React.SyntheticEvent<Element, Event>,
    value: SelectOption | null
  ) => {
    setSelectValue(value);
    setRadioValue('');

    handleChange(value?.value || '');
  };

  const handleRadioChange = (
    _: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    setSelectValue(null);
    setRadioValue(value);

    handleChange(value);
  };

  return (
    <ControlContainer
      label={label}
      meta={meta}
      name={input.name}
      tooltip={tooltip}
    >
      <div className={classes.root}>
        <RadioGroup
          onChange={handleRadioChange}
          className={classes.radioGroup}
          value={radioValue}
        >
          {radioOptions.map((option) => (
            <RadioCircle key={option.label} {...option} />
          ))}
        </RadioGroup>
        <Select
          options={selectOptions}
          className={classes.select}
          onChange={handleSelectChange}
          onBlur={handleBlur}
          value={selectValue}
          textFieldProps={{ label: LabelEnum.More }}
        />
      </div>
    </ControlContainer>
  );
}
