import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import set from 'lodash-es/set';

import { FieldsEnum } from 'app/shared/enums/Fields.enum';
import { QuotationStatusEnum } from 'app/shared/enums/QuotationStatus.enum';
import { getAnonymisedFields } from 'app/shared/helpers/anonymisation';
import { SetQuotationQuestionActionPayload } from './actionPayload.interface';
import {
  createNewQuotationAction,
  fetchQuotationCopyAction,
  initQuotationAction,
  submitQuotationAction,
} from './quotationActions';
import { QuotationState } from './state.interface';

const initialState: QuotationState = {
  anonymised: {
    [FieldsEnum.EmailAddress]: false,
    [FieldsEnum.PhoneNumber]: false,
  },
};

export const quotationSlice = createSlice({
  name: 'quotation',
  initialState,
  reducers: {
    setQuotationQuestionAction: (
      state,
      action: PayloadAction<SetQuotationQuestionActionPayload>
    ) => {
      state.anonymised[action.payload.name] = false;
      set(state, action.payload.name, action.payload.value);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createNewQuotationAction.fulfilled, (state, action) => {
        return {
          ...state,
          ...action.payload,
          anonymised: action.payload
            ? getAnonymisedFields(action.payload)
            : initialState.anonymised,
        };
      })
      .addCase(fetchQuotationCopyAction.fulfilled, (state, action) => {
        return {
          ...state,
          ...action.payload,
          anonymised: action.payload
            ? getAnonymisedFields(action.payload)
            : initialState.anonymised,
        };
      })
      .addCase(initQuotationAction.fulfilled, (state, action) => {
        return {
          ...state,
          ...action.payload,
          anonymised: action.payload
            ? getAnonymisedFields(action.payload)
            : initialState.anonymised,
        };
      })
      .addCase(submitQuotationAction.fulfilled, (state, action) => {
        state.status = QuotationStatusEnum.SentToCalculation;
      });
  },
});

export const { setQuotationQuestionAction } = quotationSlice.actions;

export default quotationSlice.reducer;
