import React from 'react';
import { useStyles } from './FormTooltip.styles';
import { FormTooltipProps } from './FormTooltip.interfaces';
import { ClickAwayListener, IconButton, Popper } from '@mui/material';
import { Icon } from '../Icon';
import { useFormTooltip } from './useFormTooltip';

export default function FormTooltip({ content }: FormTooltipProps) {
  const { classes } = useStyles();
  const { handleClick, handleClose, placement, modifiers, anchorEl } =
    useFormTooltip();

  return (
    <ClickAwayListener onClickAway={handleClose} mouseEvent="onClick">
      <div className={classes.root}>
        <TooltipButton isActive={!!anchorEl} onClick={handleClick} />
        <Popper
          open={!!anchorEl}
          anchorEl={anchorEl}
          placement={placement}
          className={classes.popover}
          modifiers={modifiers}
        >
          {content}
        </Popper>
      </div>
    </ClickAwayListener>
  );
}

function TooltipButton({
  isActive,
  onClick,
}: {
  isActive: boolean;
  onClick: React.MouseEventHandler<HTMLElement>;
}) {
  const { classes } = useStyles();

  return (
    <IconButton onClick={onClick} className={classes.button}>
      {isActive ? <Icon name={'TooltipOn'} /> : <Icon name={'TooltipOff'} />}
    </IconButton>
  );
}
