import { TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';

import { ControlContainer } from 'app/components/ControlContainer';
import { Icon } from 'app/components/Icon';
import { LabelEnum } from 'app/shared/enums/Label.enum';
import { EmailControlProps } from './EmailControl.interfaces';

export default function EmailControl(props: EmailControlProps) {
  const { input, label, meta, onControlBlur, onValueChange, tooltip } = props;
  const isAnon = input.value.includes('*');
  const error = Boolean(meta.touched && (meta.error || meta.submitError));

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const value = event.target.value.trim();

    input.onBlur();

    if (onControlBlur) {
      onControlBlur({ value, shouldPatch: true });
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.trim();

    input.onChange(value);

    if (onValueChange) {
      onValueChange({
        value,
        shouldPatch: false,
      });
    }
  };

  const handleFocus = (_: React.FocusEvent<HTMLInputElement>) => {
    if (!isAnon) {
      return;
    }

    const value = '';
    input.onChange(value);

    if (onValueChange) {
      onValueChange({
        value,
        shouldPatch: true,
      });
    }
  };

  return (
    <ControlContainer
      label={label}
      meta={meta}
      name={input.name}
      tooltip={tooltip}
    >
      <TextField
        type="email"
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        error={error}
        placeholder={LabelEnum.EmailControlPlaceholder}
        value={input.value}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Icon name="Mail" />
            </InputAdornment>
          ),
        }}
      />
    </ControlContainer>
  );
}
