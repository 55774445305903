import { AxiosError } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { UserService } from 'app/services/user.service';
import { CredentialsInterface } from 'app/shared/interfaces/Credentials.interface';
import { ErrorResponseDataInterface } from 'app/shared/interfaces/ErrorResponseData.interface';

export const loginAction = createAsyncThunk(
  'user/login',
  async (
    {
      credentials,
    }: {
      credentials: CredentialsInterface;
    },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await UserService.login(credentials);

      return data;
    } catch (error) {
      const err = error as AxiosError<ErrorResponseDataInterface>;

      return rejectWithValue(err.response?.data);
    }
  }
);

export const logoutAction = createAsyncThunk('user/logout', async () => {
  await UserService.logout();
});

export const getAuthAction = createAsyncThunk(
  'user/getAuth',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await UserService.getCurrentUserInfo();

      return data;
    } catch (error) {
      const err = error as AxiosError;

      return rejectWithValue(err.response?.data);
    }
  }
);
