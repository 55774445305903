import { createSlice } from '@reduxjs/toolkit';

import { UserState } from './state.interface';
import { getAuthAction, loginAction, logoutAction } from './userActions';

const initialState: UserState = {};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loginAction.fulfilled, (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload;
    });
    builder.addCase(logoutAction.fulfilled, (state, action) => {
      return initialState;
    });
    builder.addCase(getAuthAction.fulfilled, (state, action) => {
      state.isLoggedIn = !action.payload.anonymous;
      state.user = action.payload;
    });
  },
});

export default userSlice.reducer;
