import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(
  ({ spacing, breakpoints, palette, typography }) => ({
    root: {
      marginTop: spacing(5),
      marginBottom: spacing(4),

      [breakpoints.up('sm')]: {
        marginTop: spacing(8),
        marginBottom: spacing(6),
      },
    },
    label: {
      color: palette.black,
    },
    sublabel: {
      marginTop: spacing(2),

      ...typography.body.M,
      color: palette.black,
    },
  })
);
