import React from 'react';
import { useStyles } from './Table.styles';
import { TableProps } from './Table.interfaces';

export function Header({ children, className }: TableProps) {
  const { classes, cx } = useStyles();

  return <div className={cx(classes.head, className)}>{children}</div>;
}

export function Body({ children, className }: TableProps) {
  const { classes, cx } = useStyles();

  return <div className={cx(classes.body, className)}>{children}</div>;
}

export function Table({ children, className }: TableProps) {
  const { classes, cx } = useStyles();

  return <div className={cx(classes.table, className)}>{children}</div>;
}

export function Row({ children, className }: TableProps) {
  const { classes, cx } = useStyles();

  return <div className={cx(classes.row, className)}>{children}</div>;
}

export function Cell({ children, className }: TableProps) {
  const { classes, cx } = useStyles();

  return <div className={cx(classes.cell, className)}>{children}</div>;
}
