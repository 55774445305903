import React, { FC } from 'react';
import { useStyles } from './Button.styles';
import { ButtonProps } from './Button.interfaces';
import { Link } from 'react-router-dom';
import MUIButton from '@mui/material/Button';

const Button: FC<ButtonProps> = ({
  color,
  size,
  children,
  isDisabled,
  isLoading,
  to,
  component = 'button',
  ...props
}) => {
  const { classes } = useStyles({ color, size });

  return (
    <MUIButton
      classes={classes}
      {...props}
      disabled={isDisabled}
      component={component === 'Link' ? Link : component}
      to={to}
    >
      {children}
    </MUIButton>
  );
};

export default Button;
