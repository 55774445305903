import { MessageTypeEnum } from 'app/shared/enums/MessageType.enum';
import { useTypedDispatch } from 'app/shared/hooks/useTypedDispatch';
import { showNotificationAction } from '.';

export const useNotification = () => {
  const dispatch = useTypedDispatch();

  return (type: MessageTypeEnum, content: string, duration: number = 6000) =>
    dispatch(
      showNotificationAction({
        type,
        content,
        duration,
      })
    );
};
