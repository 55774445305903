import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useTypedDispatch } from 'app/shared/hooks/useTypedDispatch';
import { loginAction } from 'app/store/data/user/userActions';
import { useNotification } from 'app/store/data/notification';
import { LabelEnum } from 'app/shared/enums/Label.enum';
import { MessageTypeEnum } from 'app/shared/enums/MessageType.enum';
import { RouteEnum } from 'app/shared/enums/Route.enum';
import { FieldsEnum } from 'app/shared/enums/Fields.enum';
import { ErrorResponseDataInterface } from 'app/shared/interfaces/ErrorResponseData.interface';
import { LoginFormInterface } from 'app/shared/interfaces/LoginForm.interface';

export function useLoginForm() {
  const dispatch = useTypedDispatch();
  const notify = useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { state } = useLocation();

  const onSubmitError = (error: ErrorResponseDataInterface) => {
    const { errorKey } = error;

    setIsLoading(false);

    if (errorKey === 'invalid-email') {
      return { [FieldsEnum.Username]: LabelEnum.UsernameInvalid };
    }

    if (errorKey === 'invalid-password') {
      return { [FieldsEnum.Password]: LabelEnum.PasswordInvalid };
    }
  };

  const onSubmitSuccess = () => {
    notify(MessageTypeEnum.Success, LabelEnum.LoginSucceded);
    navigate(`/${RouteEnum.CALCULATOR_VIEW}`, { state });
  };

  const handleSubmit = async (values: LoginFormInterface) => {
    setIsLoading(true);

    try {
      await dispatch(
        loginAction({
          credentials: values,
        })
      ).unwrap();

      onSubmitSuccess();
    } catch (error) {
      const err = error as ErrorResponseDataInterface;

      return onSubmitError(err);
    }
  };

  return {
    handleSubmit,
    isLoading,
  };
}
