import { range } from 'lodash';

import { Dictionary } from 'app/services/dict.service';
import { DictionariesEnum } from 'app/shared/enums/Dictionaries.enum';
import { FieldsEnum } from 'app/shared/enums/Fields.enum';

export const dictionaries: Partial<Record<DictionariesEnum, Dictionary>> = {
  [DictionariesEnum.YesNo]: [
    {
      key: 'true',
      value: 'Sí',
    },
    {
      key: 'false',
      value: 'No',
    },
  ],
  [DictionariesEnum.NumberOfPersons]: range(1, 10, 1).map((value) => ({
    value: String(value),
    key: String(value),
  })),
  [DictionariesEnum.InsuredPersons]: range(0, 76, 1).map((value) => ({
    value: String(value),
    key: String(value),
  })),
  [DictionariesEnum.Products]: [
    {
      key: FieldsEnum.Hospitalization,
      value: 'Hospitalización',
      additionalInfo:
        'Marca esta casilla si quieres incluir la hospitalización, la cirugía y las intervenciones quirúrgicas en tu póliza. Si no lo marcas, solo tendrás acceso a médicos especialistas.',
    },
    {
      key: FieldsEnum.CoPayment,
      value: 'Copago',
      additionalInfo:
        'El copago es para aquellas personas que acuden poco al médico y quieren pagar menos cada mes, asumiendo una parte en cada acto médico que necesiten. Si no lo marcas, tendrás las ofertas de “tarifa plana” pagando siempre lo mismo cada mes.',
    },
    {
      key: FieldsEnum.SelfEmployer,
      value: 'Autónomos o pymes',
      additionalInfo:
        'Si eres autónomo o PYME aprovecha esta opción para obtener los mejores precios.',
    },
    {
      key: FieldsEnum.Refund,
      value: 'Reembolso de gastos',
      additionalInfo:
        'Si viajas mucho y no quieres estar limitado por un cuadro médico, aprovecha esta opción que, aunque pagas más mensualmente, te da la posibilidad de ir al médico que desees. Si no la marcas te daremos ofertas más económicas con cuadro médico de la aseguradora.',
    },
  ],
};
