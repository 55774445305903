import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(({ spacing, breakpoints }) => ({
  root: {},
  questions: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(3),

    [breakpoints.up('sm')]: {
      gap: spacing(4),
    },
  },
}));
