import { FormSectionProps } from './FormSection.interfaces';
import { useStyles } from './FormSection.styles';
import { FormSectionHeader } from 'app/components/FormSectionHeader';

export default function FormSection(props: FormSectionProps) {
  const { children, label, subLabel, visible = true } = props;

  const { classes } = useStyles();

  if (!visible) {
    return null;
  }

  return (
    <section className={classes.root}>
      <FormSectionHeader label={label} subLabel={subLabel} />
      <div className={classes.questions}>{children}</div>
    </section>
  );
}
