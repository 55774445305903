import React from 'react';
import { InsurantAgeControlProps } from './InsurantAgeControl.interfaces';
import { ControlContainer } from 'app/components/ControlContainer';
import { Select } from 'app/components/Select';
import { LabelEnum } from 'app/shared/enums/Label.enum';
import { SelectOption } from 'app/components/Select/Select.interfaces';

export default function InsurantAgeControl(props: InsurantAgeControlProps) {
  const { input, meta, options, onChange, onBlur, label } = props;
  const value =
    options.find(({ value }) => value === String(input.value)) || null;

  const handleChange = (
    _: React.SyntheticEvent<Element, Event>,
    selectedOption: SelectOption | null
  ) => {
    const value = selectedOption?.value;

    input.onChange(value);

    if (value) {
      onChange(value);
    }
  };

  const handleBlur = (event: React.FocusEvent<HTMLDivElement>) => {
    input.onBlur(event);

    if (input.value) {
      onBlur(input.value);
    }
  };

  return (
    <ControlContainer meta={meta} label={label} name={input.name}>
      <Select
        options={options}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        textFieldProps={{
          type: 'number',
          label: LabelEnum.Age,
          error: !!(meta.touched && meta.error),
        }}
      />
    </ControlContainer>
  );
}
